import { StorageKeyTypes } from '../interfaces/table'
import { ClientTypes } from '../interfaces/client'
import routes from '../constants/routes'
import { OrderTypes } from '../interfaces/orders'
import { ProductTypes } from '../interfaces/products'
import { ComplaintTypes } from '../interfaces/complaints'
import { UserRoleTypes } from '../interfaces/user'

interface TableRowNavigationProps {
  key: StorageKeyTypes
  rowData: object
}
// eslint-disable-next-line import/prefer-default-export
export function tableRowNavigation({ key, rowData }:TableRowNavigationProps): string {
  if (key === 'clientsTable') {
    const data = rowData as ClientTypes
    return `${routes.shared.clientInfo}/${data.client_number}`
  }

  if (key === 'ordersTable') {
    const data = rowData as OrderTypes
    return `${routes.shared.orderInfo}/${data.id}`
  }

  if (key === 'productsTable') {
    const data = rowData as ProductTypes
    return `${routes.shared.productInfo}/${data.id}`
  }

  if (key === 'complaintsTable') {
    const data = rowData as ComplaintTypes
    return `${routes.shared.complaintDetails}/${data.id}`
  }

  if (key === 'usersTable') {
    const data = rowData as { objectId: number; user_type: UserRoleTypes['userRole'] }
    return `${routes.shared.userInfo}/${data.user_type.toLowerCase()}-${data.objectId}`
  }

  return ''
}
