// eslint-disable-next-line import/prefer-default-export
export const complaintDescriptionOptions = [
  {
    id: 1,
    value: '',
    label: 'chooseDescription'
  },
  {
    id: 2,
    value: 'WRONG_PRODUCT',
    label: 'WRONG_PRODUCT'
  },
  {
    id: 3,
    value: 'DEFECTIVE_PRODUCT',
    label: 'DEFECTIVE_PRODUCT'
  },
  {
    id: 4,
    value: 'WRONG_PRICE',
    label: 'WRONG_PRICE'
  },
  {
    id: 5,
    value: 'OTHER',
    label: 'OTHER'
  }
]

export const complaintStatusOptions = [
  {
    id: 1,
    value: '',
    label: 'chooseStatus'
  },
  {
    id: 2,
    value: 'ACTIVE',
    label: 'ACTIVE'
  },
  {
    id: 3,
    value: 'COMPLETED',
    label: 'COMPLETED'
  }
]

export const complaintActionOptions = [
  {
    id: 1,
    value: '',
    label: 'chooseAction'
  },
  {
    id: 2,
    value: 'PRODUCT_REPLACEMENT',
    label: 'PRODUCT_REPLACEMENT'
  },
  {
    id: 3,
    value: 'ISSUE_OF_CREDIT',
    label: 'ISSUE_OF_CREDIT'
  },
  {
    id: 4,
    value: 'COMMUNICATION_FROM_THE_SELLER',
    label: 'COMMUNICATION_FROM_THE_SELLER'
  },
  {
    id: 5,
    value: 'COMMUNICATION_FROM_THE_SALES_MANAGER',
    label: 'COMMUNICATION_FROM_THE_SALES_MANAGER'
  },
  {
    id: 6,
    value: 'COMMUNICATION_FROM_THE_ACCOUNTING_DEPARTMENT',
    label: 'COMMUNICATION_FROM_THE_ACCOUNTING_DEPARTMENT'
  },
  {
    id: 7,
    value: 'COMMUNICATION_FROM_COMMERCIAL_MANAGER',
    label: 'COMMUNICATION_FROM_COMMERCIAL_MANAGER'
  },
  {
    id: 8,
    value: 'COMMUNICATION_FROM_CEO',
    label: 'COMMUNICATION_FROM_CEO'
  }
]

export const complaintPriorityOptions = [
  {
    id: 1,
    value: '',
    label: 'choosePriority'
  },
  {
    id: 2,
    value: 'LOW',
    label: 'LOW'
  },
  {
    id: 3,
    value: 'NORMAL',
    label: 'NORMAL'
  },
  {
    id: 4,
    value: 'HIGH',
    label: 'HIGH'
  }
]
