import { ChildrenTypes } from '../../../../../../../interfaces/children'

function CampaignHeaderSteps({ children }: ChildrenTypes) {
  return (
    <header className="flex gap-4 flex-col sm:flex-row sm:flex-wrap sm:items-center justify-between border-b border-solid border-[#E6E6E6] pb-2 sm:pb-4 w-full">
      {children}
    </header>
  )
}

export default CampaignHeaderSteps
