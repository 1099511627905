import { registerLocale } from 'react-datepicker'
import { el } from 'date-fns/locale'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import useWidth from '../../hooks/useWidth'
import useGetData from '../../hooks/useGetData'
import apiService from '../../services/api/apiService'
import { TotalOrdersStatisticsTypes } from '../../interfaces/statistics'
import usePredefinedDateRange from '../../hooks/usePredefinedDateRange'
import ChartHeaderFilters from './ChartHeaderFilters'

registerLocale('el', el)

interface Props {
  text: string,
  barSize?: number
}

function NumberOfOrdersChart({ text, barSize = 12 }:Props) {
  const { t } = useTranslation()
  const {
    startDate, endDate, shouldFetchValue, setDateRange, setPredefinedDateRange, predefinedDateRange
  } = usePredefinedDateRange()
  const { width } = useWidth()

  const { data } = useGetData<TotalOrdersStatisticsTypes>({
    queryKey: `numberOfOrdersStats, ${predefinedDateRange}`,
    queryFn: () => apiService.getTotalOrdersStatistics({
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    }),
    disabled: predefinedDateRange !== shouldFetchValue || !endDate
  })

  return (
    <div className="bg-[#FBFBFB] rounded-[10px] p-[10px] md:py-5 md:px-8 h-[600px] sm:h-[500px]">
      <ChartHeaderFilters
        text={text}
        predefinedDateRange={predefinedDateRange}
        endDate={endDate}
        startDate={startDate}
        setPredefinedDateRange={setPredefinedDateRange}
        setDateRange={setDateRange}
      />
      <ResponsiveContainer width="100%" height={width && width <= 500 ? '77%' : '85%'}>
        <BarChart
          data={data?.total ?? []}
          barSize={barSize}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={(date) => (data?.total.length > 0 ? moment(date).format('DD/MM/YY') : '')}
            angle={-40}
            tickMargin={11}
            style={{
              fontSize: '10px'
            }}
          />
          <YAxis width={40} />
          <Tooltip labelFormatter={
            (date) => moment(date).format('DD/MM/YY')
          }
          />
          <Bar
            dataKey="count"
            fill="#004059"
            name={t('orders')}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default NumberOfOrdersChart
