import { useTranslation } from 'react-i18next'
import { HiOutlinePlus } from 'react-icons/hi'

interface Props {
  text: string
  onClickHandler: () => void
}

function CreateNewListBtn({ text, onClickHandler }: Props) {
  const { t } = useTranslation();
  return (
    <button onClick={onClickHandler} type="button" className="text-grey-pale whitespace-nowrap flex items-center gap-[9px]">
      <span className="flex justify-center items-center rounded-lg bg-[#F1F1F1] w-[28px] h-[28px] hover:text-btn-sub transition-colors duration-200">
        <HiOutlinePlus />
      </span>
      {t(text)}
    </button>
  )
}

export default CreateNewListBtn
