import { useTranslation } from 'react-i18next'

interface Props {
  text: string
}

function InputErrorMessage({ text }: Props) {
  const [t] = useTranslation()

  return (
    <p className="text-red-700 text-xs top-[82px] absolute left-0">
      {t(text)}
    </p>
  )
}

export default InputErrorMessage
