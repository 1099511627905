import { useTranslation } from 'react-i18next'
import { AiOutlineSave } from 'react-icons/ai'
import Spinner from './Spinner'

interface Props {
  type : 'button' | 'submit'
  onClickHandler?: () => void
  isLoading?: boolean
}

function DraftButton({
  type, onClickHandler, isLoading = false
}:Props) {
  const { t } = useTranslation()
  return (
    <button
      /* eslint-disable-next-line react/button-has-type */
      type={type}
      className="w-full flex items-center justify-center gap-3 text-lg text-white leading-6 rounded-[5px] px-[30px] border border-primary-text-dark text-primary-text-dark py-[15px] font-semibold hover:opacity-90 transition-opacity duration-200text-white bg-white"
      onClick={onClickHandler}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <AiOutlineSave size={20} className="text-primary-text-dark" />
          {t('save')}
        </>
      )}
    </button>
  )
}

export default DraftButton
