import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import LoginForm from './components/LoginForm/LoginForm'
import logo from '../../assets/images/logo.png'

function Login() {
  const { t } = useTranslation()

  useEffect(() => {
    document.title = `Konnektable Technologies CRM - ${t('login')}`
  }, [t])

  return (
    <div className="p-5 flex flex-col justify-center w-full min-h-full">
      <img src={logo} width="332px" height="133px" alt="logo" className="mx-auto" />
      <div className="mx-auto bg-white mt-12 max-w-[500px] rounded-3xl shadow-faint w-full">
        <h1 className="text-center text-4xl pt-20 text-[#223367]">{`${t('Welcome')}!`}</h1>
        <p className="text-center text-lg my-4 text-grey-pale">
          {t('loginToContinue')}
        </p>
        <LoginForm />
      </div>
    </div>
  )
}

export default Login
