import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction } from 'react'
import { PredefinedRangeTypes } from '../../interfaces/statistics'

interface Props {
  predefinedDateRange: PredefinedRangeTypes
  setPredefinedDateRange: Dispatch<SetStateAction<PredefinedRangeTypes>>
}

function ChartPredefinedDateRanges({ predefinedDateRange, setPredefinedDateRange }:Props) {
  const { t } = useTranslation();
  return (
    <select value={predefinedDateRange} onChange={(event) => setPredefinedDateRange(event.target.value as PredefinedRangeTypes)} className="w-full sm:w-max bg-white flex items-center gap-2 border-[1px] border-solid border-[#BEBEBE] rounded-lg text-sm text-grey-pale px-1 h-[46px] outline-none">
      <option value="thisCalendarMonth">{t('thisCalendarMonth')}</option>
      <option value="lastCalendarMonth">{t('lastCalendarMonth')}</option>
      <option value="thisCalendarYear">{t('thisCalendarYear')}</option>
      <option value="lastCalendarYear">{t('lastCalendarYear')}</option>
      <option value="selectDateRange">{t('selectDateRange')}</option>
    </select>
  )
}

export default ChartPredefinedDateRanges
