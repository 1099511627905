import axios, { AxiosError, AxiosResponse } from 'axios'
import { CallsServiceInterface } from './callsService.interface'
import {
  AgentCallsPaginateTypes, PostponeCallTypes, TodayCallsTypes, UpdateCallTypes
} from '../../interfaces/calls'
import exception from '../../utils/exception'
import { ServerError } from '../../interfaces/api'
import axiosInstance from '../axiosInstance'

class CallsService implements CallsServiceInterface {
  public async getTodayCalls(): Promise<TodayCallsTypes[] | null> {
    try {
      const response = await axiosInstance.get('/auth/agent/todays-list/')
      return response.data.data.total as TodayCallsTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getAgentCalls({ pageParam = 1, searchText = '' }): Promise<AgentCallsPaginateTypes | null> {
    try {
      const response = await axiosInstance.post(`/emoria-calls/emoria-call/search/?page=${pageParam}`, {
        search_text: searchText.trim()
      })
      return response.data.data as AgentCallsPaginateTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateCall(values: UpdateCallTypes): Promise<AxiosResponse | null> {
    try {
      const apiEndpoint = values?.callId ? `/emoria-calls/emoria-call/${values.callId}/make-call/` : '/emoria-calls/emoria-call/make-client-call/'
      const data = {
        client: values?.clientNumber ? +values.clientNumber : undefined,
        status: values.status,
        duration: values.duration,
        call_start_time: values.callStartTime,
        call_end_time: values.callEndTime,
        notes: values.notes,
        planned_date: values.plannedDate
      }

      if (!values.clientNumber) {
        delete data.client
      }

      if (values.status !== 'Postponed') {
        delete data.planned_date
      }

      return await axiosInstance.patch(apiEndpoint, data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async postponeCall({ plannedDate, notes, callId }
  :PostponeCallTypes): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.patch(`/emoria-calls/emoria-call/${callId}/postpone/`, {
        planned_date: plannedDate,
        notes
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}

export default CallsService;
