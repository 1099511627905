import pdfIcon from '../../../../../assets/images/pdf.png'
import textIcon from '../../../../../assets/images/txt.png'

interface Props {
  media: { id: number, file: string }
}

function ComplaintMediaPreview({ media }:Props) {
  let mediaLink = ''

  if (media.file.endsWith('.pdf')) {
    mediaLink = pdfIcon
  } else if (media.file.endsWith('.txt')) {
    mediaLink = textIcon
  } else {
    mediaLink = media.file
  }

  return (
    <a href={media.file} className="block w-[80px] h-[80px] rounded-xl" target="_blank" rel="noreferrer">
      <img src={mediaLink} alt="" className="w-full h-full rounded-xl cover" />
    </a>
  )
}

export default ComplaintMediaPreview
