import React from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import RenderIf from './RenderIf'

interface Props {
  onClickHandler: () => void
  btnText: 'back' | 'Cancel'
}

function SecondaryActionBtn({ onClickHandler, btnText }:Props) {
  const { t } = useTranslation()
  return (
    <button
      type="button"
      onClick={onClickHandler}
      className="text-lg leading-6 rounded-[5px] px-[30px] py-4 font-semibold hover:opacity-90 transition-opacity duration-200 bg-[#e8e8e8] flex items-center justify-center gap-[2px]"
    >
      <RenderIf isTrue={btnText === 'back'}>
        <BsChevronLeft size={20} className="-translate-x-2" />
      </RenderIf>
      {t(btnText)}
    </button>
  )
}

export default SecondaryActionBtn
