import { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import FormLayout from '../../../../../components/Form/FormLayout'
import CampaignHeaderSteps from '../components/CampaignForms/CampaignHeaderSteps/CampaignHeaderSteps'
import DetailsInfoBtns from '../../../../../components/UI/DetailsInfoBtns'
import routes from '../../../../../constants/routes'
import DesignEmailStep from '../components/CampaignForms/CampaignFormSteps/DesignEmailStep'
import { useNewsletters } from '../../../../../context/NewsletterCtx/NewslettersProvider'
import UserMethods from '../../../../../utils/userMethods'
import EmailPreview from '../components/EmailPreview/EmailPreview'
import CampaignHeaderStep from '../components/CampaignForms/CampaignHeaderSteps/CampaignHeaderStep'
import useWidth from '../../../../../hooks/useWidth'
import RenderIf from '../../../../../components/UI/RenderIf'

function DesignEmail() {
  const { t } = useTranslation()
  const { width } = useWidth()
  const authUser = UserMethods.getUser()
  const navigate = useNavigate()
  const location = useLocation()
  const newslettersCtx = useNewsletters()
  const nextStepPath = authUser?.user.user_type === 'DIRECTOR' ? routes.director.campaignPreview : routes.agent.campaignPreview
  const newslettersPath = authUser?.user.user_type === 'DIRECTOR'
    ? routes.director.newsletters
    : routes.agent.newsletters
  const formik = useFormik({
    initialValues: {
      content: newslettersCtx.content ?? '',
      emailGreeting: newslettersCtx.emailGreeting ?? 'Με εκτίμηση, \nΗ ομάδα της Emoria',
      files: newslettersCtx.getter.newsletterFiles ?? []
    },
    onSubmit: (values) => {
      newslettersCtx.setter.setContent(values.content)
      newslettersCtx.setter.setEmailGreeting(values.emailGreeting)
      newslettersCtx.setter.setNewsletterFiles(values.files)
      navigate(nextStepPath, {
        state: {
          isDesignEmailCompleted: true
        }
      })
    }
  })

  useEffect(() => {
    document.title = `Emoria CRM - ${t('emailDesign')}`
  }, [t])

  if (!newslettersCtx.getter.campaignName && !newslettersCtx.getter.emailSubject) {
    return <Navigate to={newslettersPath} />
  }

  if (!(location.state as { isCampaignDetailsCompleted: boolean })?.isCampaignDetailsCompleted) {
    return <Navigate to={newslettersPath} />
  }

  return (
    <div className="flex gap-[40px] flex-col 2xl:grid 2xl:grid-cols-2">
      <div className="flex flex-col h-max">
        <FormLayout marginBottom="mb-0">
          <CampaignHeaderSteps>
            <CampaignHeaderStep status="completed" text="campaignDetails" stepNumber={1} />
            <CampaignHeaderStep status="inProgress" text="emailDesign" stepNumber={2} />
            <CampaignHeaderStep status="inActive" text="preview" stepNumber={3} />
          </CampaignHeaderSteps>
          <DesignEmailStep
            formik={formik}
            newslettersCtx={newslettersCtx}
          />
        </FormLayout>
        <RenderIf isTrue={!!width && width >= 1536}>
          <DetailsInfoBtns type="submit" btnText="nextStep" onClickHandler={() => formik.submitForm()} />
        </RenderIf>

      </div>
      <div>
        <FormLayout marginBottom="mb-0">
          <EmailPreview
            content={formik.values.content}
            emailGreeting={formik.values.emailGreeting}
          />
        </FormLayout>
        <RenderIf isTrue={!!width && width < 1536}>
          <DetailsInfoBtns type="submit" btnText="nextStep" onClickHandler={() => formik.submitForm()} />
        </RenderIf>
      </div>
    </div>

  )
}

export default DesignEmail
