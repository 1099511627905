import {
  PieChart, Pie, ResponsiveContainer, Cell
} from 'recharts'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ChartHeaderFilters from '../../../components/Charts/ChartHeaderFilters';
import usePredefinedDateRange, { PredefinedDateRangeTypes } from '../../../hooks/usePredefinedDateRange'
import useGetData from '../../../hooks/useGetData';
import apiService from '../../../services/api/apiService';
import { TotalCallStatisticsTypes } from '../../../interfaces/statistics';
import RenderIf from '../../../components/UI/RenderIf'
import WidgetTitle from '../../../components/UI/WidgetTitle'
import useWidth from '../../../hooks/useWidth'

interface Props {
  agent?: string
  gridCss?: string
  predefinedDateRange?: PredefinedDateRangeTypes
}

const COLORS = ['#7FCB9B', '#F8D134'];

function CallStatistics({ agent, gridCss, predefinedDateRange }: Props) {
  const { t } = useTranslation()
  const { width } = useWidth()
  const localPredefinedDateRange = usePredefinedDateRange()

  const disabled = predefinedDateRange?.predefinedDateRange
    !== predefinedDateRange?.shouldFetchValue
    || !predefinedDateRange?.endDate

  const localDisabled = localPredefinedDateRange.predefinedDateRange
    !== localPredefinedDateRange.shouldFetchValue
    || !localPredefinedDateRange.endDate

  const { data: callStatistics } = useGetData<TotalCallStatisticsTypes>({
    queryKey: `callStatistics ${predefinedDateRange?.predefinedDateRange ?? localPredefinedDateRange.predefinedDateRange} ${agent}`,
    queryFn: () => apiService.getTotalCallStatistics({
      from: moment(predefinedDateRange?.startDate ?? localPredefinedDateRange.startDate).format('YYYY-MM-DD'),
      to: moment(predefinedDateRange?.endDate ?? localPredefinedDateRange.endDate).format('YYYY-MM-DD'),
      agent
    }),
    disabled: predefinedDateRange?.predefinedDateRange ? disabled : localDisabled
  })

  const data = [
    { name: `${t('callsLeadingToOrders')}`, value: callStatistics?.calls_leading_to_orders },
    { name: `${t('callsPostponed')}`, value: callStatistics?.calls_postponed }
  ];

  return (
    <div className={`${gridCss} px-5 py-5 border border-grey-statistics rounded-[10px]`}>
      <RenderIf isTrue={!!predefinedDateRange}>
        <WidgetTitle text="callStatistics" fontWeight="font-semibold" />
      </RenderIf>
      <RenderIf isTrue={!predefinedDateRange}>
        <ChartHeaderFilters
          text="callStatistics"
          predefinedDateRange={localPredefinedDateRange.predefinedDateRange}
          endDate={localPredefinedDateRange.endDate}
          startDate={localPredefinedDateRange.startDate}
          setPredefinedDateRange={localPredefinedDateRange.setPredefinedDateRange}
          setDateRange={localPredefinedDateRange.setDateRange}
        />
      </RenderIf>
      <div className="flex flex-col md:flex-row mt-5 justify-between gap-x-20">
        <ResponsiveContainer width={width && width > 1280 ? '70%' : '99%'} height="100%" minHeight={200}>
          <PieChart width={400} height={400}>
            <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              innerRadius={70}
              outerRadius={100}
              data={data}
              cx="50%"
              cy="75%"
              fill="#7FCB9B"
              label
            >
              {data?.map((entry, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className="flex flex-col justify-center gap-2">
          <div className="flex items-center gap-1">
            <div className="mt-[6px] mr-1 self-start rounded-full min-h-[10px] min-w-[10px] bg-[#7FCB9B]" />
            <p>
              <span className="text-lg font-semibold">{callStatistics?.calls_leading_to_orders}</span>
              {' '}
              {t('fromF')}
              {' '}
              <span className="text-lg font-semibold">{callStatistics?.total_calls}</span>
              {' '}
              {t('callsLeadToOrders')}
            </p>
          </div>
          <div className="flex items-center gap-1">
            <div className="mr-1 mt-[6px] self-start rounded-full  min-h-[10px] min-w-[10px] bg-[#F8D134]" />
            <p>
              <span className="text-lg font-semibold">{callStatistics?.calls_postponed}</span>
              {' '}
              {t('fromF')}
              {' '}
              <span className="text-lg font-semibold">{callStatistics?.total_calls}</span>
              {' '}
              {t('callsWerePostponed')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallStatistics
