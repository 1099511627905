import { useTranslation } from 'react-i18next'
import { FieldInputProps } from 'formik'
import { FiEdit } from 'react-icons/fi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { toast } from 'react-toastify'
import RenderIf from '../../../../../../components/UI/RenderIf'
import InputErrorMessage from '../../../../../../components/UI/InputErrorMsg'
import CreateNewListBtn from './CreateNewListBtn'
import { MailingListTypes } from '../../../../../../interfaces/newsletters'
import { useModalContext } from '../../../../../../context/ModalsCtx/ModalsProvider'
import { useMailingList } from '../../../../../../context/MailingListCtx/MailingListProvider'

interface Props {
  setValue: any
  getFieldProps: FieldInputProps<any>
  errors: boolean
  errorName: string | undefined
  mailingList: MailingListTypes[]
}

function MailingListFormInput({
  getFieldProps, errors, errorName, mailingList = []
}:Props) {
  const { t } = useTranslation()
  const { showMailingListModalHandler } = useMailingList()
  const { openConfirmationModalHandler } = useModalContext()
  const iconClasses = 'text-[#BEBEBE] transition-colors duration-200 cursor-pointer'

  const onEditListHandler = (id: string) => {
    if (getFieldProps.value === '') {
      toast.warn<string>(t('selectListToEdit'))
      return
    }
    const selectedMailingList = mailingList.find((list) => list.id === +id)
    if (selectedMailingList) {
      showMailingListModalHandler(selectedMailingList)
    }
  }

  const onDeleteListHandler = (id: string) => {
    if (getFieldProps.value === '') {
      toast.warn<string>(t('selectListToDelete'))
      return
    }
    openConfirmationModalHandler('deleteMailingListMessage', {
      id: +id,
      userRole: null
    })
  }

  return (
    <div className="text-grey-pale text-base md:text-lg mb-5 relative">
      <div>
        <label htmlFor="mailingList" className="mb-2 block">{t('chooseEmailList')}</label>
        <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-5">
          <div className={`bg-white w-full border px-2 rounded-[5px] h-12 flex items-center ${errors ? 'border-red-700' : 'border-gray-400'}`}>
            <select id="mailingList" {...getFieldProps} className="h-full w-full outline-none bg-white">
              <option value="">{t('chooseList')}</option>
              {mailingList.map((list) => (
                <option
                  key={list.id}
                  value={list.id}
                >
                  {list.name}
                </option>
              ))}
            </select>
            <FiEdit
              onClick={() => onEditListHandler(getFieldProps.value)}
              size={50}
              className={`${iconClasses} px-3 border-x border-x-gray-400 h-full hover:text-[#4B8FDC]`}
            />
            <RiDeleteBin6Line
              onClick={() => onDeleteListHandler(getFieldProps.value)}
              size={40}
              className={`${iconClasses} pl-3 pr-1 hover:text-red-500`}
            />
          </div>
          <CreateNewListBtn text="createNewList" onClickHandler={() => showMailingListModalHandler()} />
        </div>
      </div>
      <RenderIf isTrue={!!errors}>
        <div className="absolute top-1 w-full">
          <InputErrorMessage text={errorName!} />
        </div>
      </RenderIf>
    </div>
  )
}

export default MailingListFormInput
