import { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import FormLayout from '../../../../../components/Form/FormLayout'
import CampaignHeaderSteps from '../components/CampaignForms/CampaignHeaderSteps/CampaignHeaderSteps'
import CampaignHeaderStep from '../components/CampaignForms/CampaignHeaderSteps/CampaignHeaderStep'
import DetailsInfoBtns from '../../../../../components/UI/DetailsInfoBtns'
import PageSection from '../../../../../components/UI/PageSection'
import EmailPreview from '../components/EmailPreview/EmailPreview'
import { useNewsletters } from '../../../../../context/NewsletterCtx/NewslettersProvider'
import CampaignHeaderPreviewValues from '../components/CampaignForms/CampaignHeaderSteps/CampaignHeaderPreviewValues'
import useMutateData from '../../../../../hooks/useMutateData'
import apiService from '../../../../../services/api/apiService'
import routes from '../../../../../constants/routes'
import EmailAttachmentsPreview from '../components/EmailAttachmentsPreview'
import { useMailingList } from '../../../../../context/MailingListCtx/MailingListProvider'
import UserMethods from '../../../../../utils/userMethods'

function CampaignPreview() {
  const { t } = useTranslation()
  const authUser = UserMethods.getUser()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const newslettersCtx = useNewsletters()
  const mailingListCtx = useMailingList()
  const newslettersPath = authUser?.user.user_type === 'DIRECTOR'
    ? routes.director.newsletters
    : routes.agent.newsletters

  const { mutate: mutateNewsletter, isLoading: isNewsletterLoading } = useMutateData({
    key: 'newsletters',
    mutationFn: newslettersCtx.isEditNewsletter
      ? apiService.updateNewsletter
      : apiService.createNewsletter,
    successMessage: newslettersCtx.isEditNewsletter ? 'newsletterHasBeenUpdated' : 'newsletterHasBeenCreated'
  })

  useEffect(() => {
    document.title = `Emoria CRM - ${t('campaignPreview')}`
  }, [t])

  // Load template
  useEffect(() => {
    newslettersCtx.setter.setEmailTemplate(document.getElementById('emailTemplate')?.innerHTML ?? '')
  }, [newslettersCtx.setter])

  if (!newslettersCtx.getter.campaignName && !newslettersCtx.getter.emailSubject) {
    return <Navigate to={newslettersPath} />
  }

  if (!(location.state as { isDesignEmailCompleted: boolean })?.isDesignEmailCompleted) {
    return <Navigate to={newslettersPath} />
  }

  const submitFilesOnUpdate = async () => {
    if (newslettersCtx.getter.newsletterFiles
      && newslettersCtx.getter.newsletterFiles?.length > 0
      && newslettersCtx.isEditNewsletter) {
      await Promise.all(
        Array.from(newslettersCtx.getter.newsletterFiles, (file) => apiService
          .createFile(file, newslettersCtx.getter.selectedNewsletterId!))
      )
      await queryClient.invalidateQueries('newsletters')
    }
  }

  return (
    <PageSection>
      <div>
        <FormLayout marginBottom="mb-5">
          <div className="flex flex-col w-full">
            <CampaignHeaderSteps>
              <CampaignHeaderStep status="completed" text="campaignDetails" stepNumber={1} />
              <CampaignHeaderStep status="completed" text="emailDesign" stepNumber={2} />
              <CampaignHeaderStep status="inProgress" text="preview" stepNumber={3} />
            </CampaignHeaderSteps>
            <CampaignHeaderPreviewValues
              campaignName={newslettersCtx.getter.campaignName}
              emailSubject={newslettersCtx.getter.emailSubject}
            />
          </div>
        </FormLayout>
        <EmailPreview
          content={newslettersCtx.content ?? ''}
          emailGreeting={newslettersCtx.emailGreeting ?? ''}
        />

        <EmailAttachmentsPreview newslettersCtx={newslettersCtx} />

        <DetailsInfoBtns
          btnText="send"
          onClickHandlerDraft={() => mutateNewsletter({
            newsletter: newslettersCtx.getter,
            mailingList: mailingListCtx.getter,
            isEditNewsletter: newslettersCtx.isEditNewsletter
          }, {
            onSuccess: async () => {
              await submitFilesOnUpdate()
              navigate(newslettersPath)
              newslettersCtx.onResetNewsletterHandler()
              mailingListCtx.onResetMailingListHandler()
            }
          })}
          onClickHandler={() => mutateNewsletter({
            newsletter: newslettersCtx.getter,
            mailingList: mailingListCtx.getter,
            isEditNewsletter: newslettersCtx.isEditNewsletter
          }, {
            onSuccess: async (response) => {
              await submitFilesOnUpdate()

              apiService.sendNewsletter(response?.data.data.id).then(() => {
                navigate(newslettersPath)
                newslettersCtx.onResetNewsletterHandler()
                mailingListCtx.onResetMailingListHandler()
                queryClient.invalidateQueries('newsletters')
              })
            }
          })}
          isPrimaryBtnLoading={isNewsletterLoading}
          isDraftBtnLoading={isNewsletterLoading}
        />
      </div>
    </PageSection>
  )
}

export default CampaignPreview
