import ReactDOM from 'react-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import FormButtons from '../../../../../../components/Form/FormButtons'
import FormInput from '../../../../../../components/Form/FormInput'
import { useMailingList } from '../../../../../../context/MailingListCtx/MailingListProvider'
import UploadMailingList from './UploadMailingList'
import MailingListTable from './MailingListTable'
import { useDataProvider } from '../../../../../../context/DataProviderCtx/DataProvider'
import validationSchema from '../../../../../../utils/validationSchema'
import useMutateData from '../../../../../../hooks/useMutateData'
import apiService from '../../../../../../services/api/apiService'
import MailingListTableSkeleton from '../../../../../../components/Skeletons/MailingListTableSkeleton'
import RenderIf from '../../../../../../components/UI/RenderIf'
import Spinner from '../../../../../../components/UI/Spinner'

interface Props {
  mailingListId: string
}

const MailingListModal = ({ mailingListId }:Props) => {
  const { t } = useTranslation()
  const [listFromFile, setListFromFile] = useState<File | undefined>(undefined)
  const dataProvider = useDataProvider()
  const mailingListCtx = useMailingList()

  const { mutate: mutateMailingList, isLoading } = useMutateData({
    key: 'mailingList',
    mutationFn:
      mailingListCtx.getter.isEditMailingList
        ? apiService.updateMailingList
        : apiService.createMailingList,
    successMessage: mailingListCtx.getter.isEditMailingList ? 'mailingListHasBeenUpdated' : 'mailingListHasBeenCreated'
  })

  const { mutate: uploadMailingList, isLoading: isUploading } = useMutateData({
    key: 'mailingList',
    mutationFn: apiService.uploadMailingListFile,
    successMessage: 'mailingListHasBeenUploaded'
  })

  const { closeMailingListModalHandler } = mailingListCtx
  // Send mailing list file
  useEffect(() => {
    if (listFromFile) {
      uploadMailingList(listFromFile, {
        onSuccess: () => {
          setListFromFile(undefined)
          closeMailingListModalHandler()
          setListFromFile(undefined)
        },
        onError: () => {
          setListFromFile(undefined)
        }
      })
    }
  }, [
    listFromFile,
    closeMailingListModalHandler,
    uploadMailingList])

  const formik = useFormik({
    initialValues: {
      mailingListName: mailingListCtx.getter.mailingListName ?? ''
    },
    validationSchema: validationSchema.mailingListCreationForm,
    onSubmit: (values) => {
      if (mailingListCtx.getter.mailingListSelectedClients.length === 0) {
        toast.warn<string>(t('noClientsSelected'))
        return
      }
      mutateMailingList({
        id: mailingListId,
        name: values.mailingListName,
        clients: mailingListCtx.getter.mailingListSelectedClients
      }, {
        onSuccess: () => {
          mailingListCtx.closeMailingListModalHandler()
          mailingListCtx.onClearMailingListSelectedClientsHandler()
          formik.resetForm()
          setListFromFile(undefined)
        }
      })
    }
  })

  const { setFieldValue } = formik
  useEffect(() => {
    if (mailingListCtx.getter.isEditMailingList) {
      setFieldValue('mailingListName', mailingListCtx.getter.mailingListName)
    }
  }, [
    mailingListCtx.getter.mailingListName,
    mailingListCtx.getter.isEditMailingList,
    setFieldValue
  ])

  const backdropClasses = mailingListCtx.showMailingListModal ? 'opacity-100 visible' : 'opacity-0 invisible'
  const modalClasses = mailingListCtx.showMailingListModal ? 'opacity-100 scale-100' : 'opacity-0 scale-0'
  const overlays = document.getElementById('overlays') as HTMLElement

  return ReactDOM.createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */}
      <div className={`fixed inset-0 z-40 bg-[rgba(0,0,0,0.20)] transition-opacity duration-300 ${backdropClasses}`} onClick={mailingListCtx.closeMailingListModalHandler} />
      <div className={`bg-white absolute xl:fixed top-8 md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 z-50  max-w-[90%] xl:max-w-[1235px] w-full origin-center transition-all duration-300 ${modalClasses} rounded-2xl`}>
        <div className="flex items-center justify-between bg-btn-sub text-white rounded-t-2xl px-[35px] py-[25px]">
          <h2 className="font-semibold text-4xl">{t(mailingListCtx.getter.isEditMailingList ? 'editList' : 'createNewList')}</h2>
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 px-[35px] py-[25px] text-lg text-grey-pale">
          <div className="flex flex-col sm:items-center gap-3 sm:flex-row sm:mb-2">
            <FormInput
              inputId="emailListName"
              name="emailListName"
              width="lg:w-1/2"
              marginX="mx-0"
              marginY="my-0"
              getFieldProps={formik.getFieldProps('mailingListName')}
              errors={!!(formik.touched.mailingListName && formik.errors.mailingListName)}
              errorName={formik.errors.mailingListName}
            />
            <RenderIf isTrue={isUploading}>
              <div className="sm:translate-y-[15px] flex items-center gap-3 w-max my-2">
                <Spinner />
                <p className="text-sm whitespace-nowrap">{t('uploadingFile')}</p>
              </div>
            </RenderIf>
            <RenderIf isTrue={!isUploading}>
              <div className="flex sm:items-center gap-3 sm:translate-y-[15px]">
                <p className="text-primary-dark-text text-sm hidden sm:block">{t('or')}</p>
                <UploadMailingList setListFromFile={setListFromFile} />
              </div>
            </RenderIf>
          </div>

          <RenderIf
            isTrue={dataProvider.isClientsLoading
              || dataProvider.clientPaginate.isFetchingNextPage}
          >
            <MailingListTableSkeleton />
          </RenderIf>

          <RenderIf
            isTrue={!dataProvider.isClientsLoading
            && !dataProvider.clientPaginate.isFetchingNextPage}
          >
            <MailingListTable
              clients={dataProvider.clients}
              selectedClients={mailingListCtx.getter.mailingListSelectedClients}
              onSelectHandler={mailingListCtx.onSelectClientHandler}
              onRemoveHandler={mailingListCtx.onRemoveClientHandler}
              onShowSelectedClientsHandler={mailingListCtx.showSelectedClientsModalHandler}
            />
          </RenderIf>
          <div className="w-full h-[1px] bg-[#BEBEBE] mt-4" />
          <FormButtons
            isLoading={isLoading}
            btnText="save"
            onCancelHandler={mailingListCtx.closeMailingListModalHandler}
          />
        </form>
      </div>
    </>,
    overlays
  )
}

export default MailingListModal
