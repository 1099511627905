import {
  useContext, useState, useMemo, useCallback
} from 'react'
import { ChildrenTypes } from '../../interfaces/children'
import { TaskTypes } from '../../interfaces/tasks'
import { ModalContextTypes, ResourceIdTypes } from './modalsCtx.interface'
import modalsCtx from './modalsCtx'

export const useModalContext = (): ModalContextTypes => useContext(modalsCtx)

function ModalsProvider({ children }:ChildrenTypes) {
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const [showTaskModal, setShowTaskModal] = useState<boolean>(false)
  const [selectedTask, setSelectedTask] = useState<TaskTypes | undefined>(undefined)
  const [modalText, setModalText] = useState<string | null>(null)
  const [resourceId, setResourceId] = useState<ResourceIdTypes | null>({
    id: null,
    userRole: null
  })
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const closeConfirmationModalHandler = useCallback(() => {
    setShowConfirmationModal(false)
    setModalText(null)
    setResourceId(null)
  }, [])

  const openConfirmationModalHandler = useCallback(
    (text:string, { id, userRole }: ResourceIdTypes) => {
      setModalText(text)
      setResourceId({ id, userRole })
      setShowConfirmationModal(true)
    },
    []
  )

  const openTaskModalHandler = useCallback((task?: TaskTypes) => {
    setShowTaskModal(true)
    setSelectedTask(task)
  }, [])

  const closeTaskModalHandler = useCallback(() => {
    setShowTaskModal(false)
    setSelectedTask(undefined)
  }, [])

  const ctx = useMemo(() => (
    {
      showConfirmationModal,
      showTaskModal,
      modalText,
      resourceId,
      isOpen,
      selectedTask,
      setIsOpen,
      closeTaskModalHandler,
      closeConfirmationModalHandler,
      openConfirmationModalHandler,
      openTaskModalHandler
    }
  ), [
    showConfirmationModal,
    showTaskModal,
    modalText,
    resourceId,
    isOpen,
    selectedTask,
    setIsOpen,
    closeTaskModalHandler,
    closeConfirmationModalHandler,
    openConfirmationModalHandler,
    openTaskModalHandler
  ]);

  return (
    <modalsCtx.Provider value={ctx}>
      {children}
    </modalsCtx.Provider>
  )
}

export default ModalsProvider
