import { FieldInputProps } from 'formik'
import { useTranslation } from 'react-i18next'
import RenderIf from '../UI/RenderIf'
import InputErrorMessage from '../UI/InputErrorMsg'

interface PropTypes {
  inputId: string
  name: string
  getFieldProps: FieldInputProps<any>
  errors: boolean
  errorName: string | undefined
  width?: string
  marginX?: 'mx-4' | 'mx-0',
  marginY?: 'my-4' | 'my-0',
  [key: string]: any
}

function FormTextArea({
  inputId,
  name,
  getFieldProps,
  errors,
  errorName,
  width = 'lg:w-1/3 | w-full',
  marginX = 'mx-4',
  marginY = 'my-4',
  ...restProps
}: PropTypes) {
  const { t } = useTranslation()

  return (
    <div className={`relative text-base md:text-lg w-full sm:w-full flex flex-col ${marginY} ${width}`}>
      <label
        htmlFor={inputId}
        className={`${marginX} mb-2 text-grey-pale whitespace-nowrap`}
      >
        {t(name)}
      </label>
      <textarea
        id={inputId}
        {...getFieldProps}
        {...restProps}
        className={`outline-none ${marginX} border py-1 px-2 rounded-[5px] ${
          errors ? 'border-red-700' : 'border-gray-400'
        }`}
      />
      <RenderIf isTrue={!!errors}>
        <div className={`${marginX} mt-1 absolute bottom-[78px] w-full`}>
          <InputErrorMessage text={errorName!} />
        </div>
      </RenderIf>
    </div>
  )
}

export default FormTextArea
