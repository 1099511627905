import { Outlet } from 'react-router-dom'
import { UserRoleTypes } from '../interfaces/user'
import Header from '../components/Header/Header'
import { useModalContext } from '../context/ModalsCtx/ModalsProvider'
import useScrollToTop from '../hooks/useScrollToTop'

function Layout({ userRole }: UserRoleTypes) {
  const { isOpen, setIsOpen } = useModalContext()
  useScrollToTop()
  return (
    <>
      <main className={`flex relative flex-col z-10 min-h-screen pb-5 px-5 lg:px-20 ${isOpen ? 'overflow-y-hidden h-screen' : 'overflow-y-auto lg:min-h-screen'}`}>
        <Header userRole={userRole} isOpen={isOpen} setIsOpen={setIsOpen} />
        <Outlet />
      </main>
      {/* Black bg alternative to linear gradient to keep consistent height across pages */}
      <div className="absolute bg-[#131D3D] top-0 w-full left-0 h-[330px]" />
    </>
  )
}

export default Layout
