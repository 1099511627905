import { ColumnInstance } from 'react-table'
import { useTranslation } from 'react-i18next'
import { ReactNode } from 'react'
import TableFiltersBtn from './TableFiltersBtn'
import useToggleMenu from '../../hooks/useToggleMenu'
import Dropdown from '../Dropdown/Dropdown'
import { StorageKeyTypes } from '../../interfaces/table'

interface Props {
  allColumns: ColumnInstance<object>[],
  storageKey: StorageKeyTypes
  children?: ReactNode
}

function TableFiltersDropdown({ allColumns, storageKey, children }:Props) {
  const { t } = useTranslation()
  const { ref, isOpen, setIsOpen } = useToggleMenu()
  const handleDropdownMenu = () => setIsOpen((prevState) => !prevState)

  const storeSelectedColumnsHandler = (columnId: string) => {
    const addedColumns = JSON.parse(localStorage.getItem(storageKey)!)
    if (addedColumns) {
      if (addedColumns.includes(columnId)) {
        localStorage
          .setItem(
            storageKey,
            JSON.stringify(addedColumns.filter((column: string) => column !== columnId))
          )
      } else {
        localStorage.setItem(storageKey, JSON.stringify([...addedColumns, columnId]))
      }
    } else {
      localStorage.setItem(storageKey, JSON.stringify([columnId]))
    }
  }

  return (
    <div ref={ref} className="relative h-full flex items-center">
      <TableFiltersBtn onClickHandler={handleDropdownMenu} />
      <Dropdown isOpen={isOpen}>
        {children}
        {allColumns.map((col) => (
          <div key={col.id} className="flex items-center justify-between gap-8 px-4 py-1 last:border-0 border-b border-solid border-b-[#BEBEBE]">
            <p className="text-grey-pale whitespace-nowrap">{col.Header?.toString() === ' ' ? t('actions') : col.Header?.toString()}</p>
            <input
              type="checkbox"
              {...col.getToggleHiddenProps()}
              className="outline-none"
              onClick={() => storeSelectedColumnsHandler(col.id)}
            />
          </div>
        ))}
      </Dropdown>
    </div>
  )
}

export default TableFiltersDropdown
