import { IoMdPerson } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { ClientTypes } from '../../../../../../interfaces/client'

interface Props {
  selectedClients: ClientTypes[]
  onClickHandler: () => void
}

function ViewSelectedClientsBtn({ selectedClients = [], onClickHandler }:Props) {
  const { t } = useTranslation()

  return (
    <button
      type="button"
      onClick={onClickHandler}
      className="flex items-center gap-3 cursor-pointer"
    >
      <div className="flex items-center justify-center rounded-full h-[35px] w-[35px] border border-btn-sub">
        <IoMdPerson size={20} className="text-btn-sub" />
      </div>
      <p
        className="text-sm text-[#2571C6] whitespace-nowrap"
      >
        {`${selectedClients.length} ${t('selectedClients')}`}
      </p>
    </button>
  )
}

export default ViewSelectedClientsBtn
