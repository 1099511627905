import axios, { AxiosError, AxiosResponse } from 'axios'
import { TasksServiceInterface } from './tasksService.interface'
import { TaskCreationTypes, TaskTypes } from '../../interfaces/tasks'
import axiosInstance from '../axiosInstance'
import exception from '../../utils/exception'
import { ServerError } from '../../interfaces/api'

class TasksService implements TasksServiceInterface {
  public async getTasks(): Promise<TaskTypes[] | null> {
    try {
      const response = await axiosInstance('/tasks/task/')
      return response.data.data as TaskTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async createTask(values: TaskCreationTypes): Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.post('/tasks/task/', values)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async editTask(values: TaskCreationTypes): Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.patch(`/tasks/task/${values.id}/`, {
        title: values.title,
        description: values.description,
        type: values.type
      })
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async deleteTask({ id }: { id: number }): Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.delete(`/tasks/task/${id}/`)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}

export default TasksService;
