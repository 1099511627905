import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import TaskButton from '../../../../../components/UI/TaskButton'
import ControlPanelTask from './ControlPanelTask'
import UserMethods from '../../../../../utils/userMethods'
import userPath from '../../../../../utils/userPath'
import TaskModal from './TaskModal'
import { useModalContext } from '../../../../../context/ModalsCtx/ModalsProvider'
import RenderIf from '../../../../../components/UI/RenderIf'
import { TaskTypes } from '../../../../../interfaces/tasks'
import NoDataMessage from '../../../../../components/UI/NoDataMessage'
import WidgetTitle from '../../../../../components/UI/WidgetTitle'

interface Props {
  tasks: TaskTypes[]
}

function ControlPanelTasks({ tasks = [] }:Props) {
  const authUser = UserMethods.getUser()
  const path = userPath({ userRole: authUser?.user.user_type })
  const navigate = useNavigate()
  const { openTaskModalHandler } = useModalContext()

  const transformedTasks = useMemo(() => tasks
    ?.sort((a, b) => b.creation_date.localeCompare(a.creation_date)), [tasks])
    ?.slice(0, authUser?.user.user_type !== 'AGENT' ? 2 : undefined)

  const displayTasks = transformedTasks
    ?.map((task) => <ControlPanelTask task={task} key={task.id} />)

  const agentClassHeight = authUser?.user.user_type === 'AGENT' ? 'max-h-[calc(100vh-435px)] overflow-y-hidden bg-white' : ' bg-[#FBFBFB] px-5 sm:px-8 py-[20px]'
  const agentClassScrollable = authUser?.user.user_type === 'AGENT' ? 'overflow-y-auto h-[calc(100%-60px)]' : ''

  return (
    <>
      <TaskModal />
      <div className={`rounded-[10px]  ${agentClassHeight}`}>
        <div className="flex items-center gap-2 flex-wrap justify-between">
          <WidgetTitle text="tasks" />
          <div className="flex items-center gap-4">
            <RenderIf isTrue={authUser?.user.user_type === 'AGENT'}>
              <TaskButton type="all" text="allTasks" onClickHandler={() => navigate(path)} />
            </RenderIf>
            <TaskButton type="add" text="newTask" onClickHandler={() => openTaskModalHandler()} />
          </div>
        </div>
        <div className={`mt-5 flex flex-col gap-3 ${agentClassScrollable}`}>
          {displayTasks}
          <RenderIf isTrue={transformedTasks?.length === 0}>
            <NoDataMessage text="noTasksFound" />
          </RenderIf>
          <RenderIf isTrue={authUser?.user.user_type !== 'AGENT'}>
            <TaskButton type="all" text="allTasks" onClickHandler={() => navigate(path)} />
          </RenderIf>
        </div>
      </div>
    </>

  )
}

export default ControlPanelTasks
