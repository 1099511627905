import {
  BsCardList,
  BsFillTelephoneInboundFill, BsHeadset, BsPeopleFill
} from 'react-icons/bs'
import { ImTicket } from 'react-icons/im'
import CardOverview from '../../../components/UI/CardOverview'
import { AnalyticsTypes } from '../../../interfaces/statistics'
import useGetData from '../../../hooks/useGetData'
import apiService from '../../../services/api/apiService'
import RenderIf from '../../../components/UI/RenderIf'
import DirectorCardsSkeleton from '../../../components/Skeletons/DirectorCardsSkeleton'

function DirectorCardsContainer() {
  const { data: analytics, isLoading: isAnalyticsLoading } = useGetData<AnalyticsTypes>({
    queryKey: 'analytics',
    queryFn: apiService.getAnalytics
  })

  return (
    <>
      <RenderIf isTrue={!isAnalyticsLoading}>
        <div className="grid grid-cols-2 2xl:grid-cols-5 my-6 gap-[22px] w-full ">
          <CardOverview text="completedCourses" number={analytics?.calls_completed} secondaryNumber={`/${analytics?.total_calls ?? 0}`}>
            <BsFillTelephoneInboundFill className="text-success" size={26} />
          </CardOverview>
          <CardOverview text="activeComplaints" number={analytics?.active_complaints}>
            <ImTicket color="#E4BD21" size={30} />
          </CardOverview>
          <div className="col-span-full 2xl:col-auto">
            <CardOverview text="ordersToday" number={analytics?.total_orders_today} secondaryNumber={`/${analytics?.total_orders ?? 0}`}>
              <BsCardList color="#959292" size={30} />
            </CardOverview>
          </div>
          <CardOverview text="saleAgents" number={analytics?.total_agents}>
            <BsHeadset color="#4b8fdc" size={30} />
          </CardOverview>

          <CardOverview text="clients" number={analytics?.total_clients}>
            <BsPeopleFill color="#78c896" size={30} />
          </CardOverview>

        </div>
      </RenderIf>
      <RenderIf isTrue={isAnalyticsLoading}>
        <DirectorCardsSkeleton />
      </RenderIf>
    </>
  )
}

export default DirectorCardsContainer
