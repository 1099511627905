import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import Table from '../../../../../components/Table/Table'
import { ComplaintTypes } from '../../../../../interfaces/complaints'
import routes from '../../../../../constants/routes'
import TableActionBtns from '../../../../../components/Table/TableActionBtns'
import { useModalContext } from '../../../../../context/ModalsCtx/ModalsProvider'

interface Props {
  complaints: ComplaintTypes[]
}

interface OriginalTypes {
  cell: { row: { original: { id: number, status: ComplaintTypes['status'], tableStatus: string } } }
}

function ComplaintsTable({ complaints = [] }: Props) {
  const { t } = useTranslation()
  const { openConfirmationModalHandler } = useModalContext()
  const navigate = useNavigate()

  const transformedComplaints = useMemo(() => complaints.map((complaint) => ({
    ...complaint,
    tableDescription: t(complaint.description),
    tableStatus: t(complaint.status),
    tablePriority: t(complaint.priority),
    tableAction: t(complaint.action)
  })).sort((a, b) => b.date_created.localeCompare(a.date_created)), [complaints, t])

  const columns = useMemo(() => [
    {
      Header: t('complaintNumber'),
      accessor: 'id'
    },
    {
      Header: t('order'),
      accessor: 'order_number',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p>{value || '-'}</p>
    },
    {
      Header: t('description'),
      accessor: 'tableDescription',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p title={value} className="truncate max-w-[180px]">{value}</p>
    },
    {
      Header: t('complaintPriority'),
      accessor: 'tablePriority'
    },
    {
      Header: t('date'),
      accessor: 'date_created',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p>{moment(value).format('DD/MM/YYYY')}</p>
    },
    {
      Header: t('client'),
      accessor: 'client_name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p title={value} className="truncate max-w-[180px]">{value}</p>
    },
    {
      Header: t('state'),
      accessor: 'tableStatus',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        cell: {
          row: { original }
        }
      }:OriginalTypes) => <span className={` ${original.status === 'ACTIVE' ? 'bg-[#FBF3E5] text-[#949292]' : 'bg-[#d0fee1] text-[#61A279]'} rounded-xl px-7 py-2 whitespace-nowrap`}>{original.tableStatus}</span>
    },
    {
      Header: t('action'),
      accessor: 'tableAction',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p title={value} className="truncate max-w-[200px]">{value}</p>
    },
    {
      Header: t('notes'),
      accessor: 'notes',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p title={value} className="truncate max-w-[180px]">{value}</p>
    },
    {
      Header: t('creator'),
      accessor: 'creator',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p title={value} className="truncate max-w-[180px]">{value}</p>
    },
    {
      Header: t(' '),
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        cell: {
          row: { original }
        }
      }:OriginalTypes) => (
        <TableActionBtns
          onClickHandlerEdit={() => navigate(`${routes.shared.updateComplaint}/${original.id}`)}
          onClickHandlerDelete={() => openConfirmationModalHandler('deleteComplaintMessage', {
            id: original.id,
            userRole: null
          })}
        />

      )
    }
  ], [t, navigate, openConfirmationModalHandler]);

  return (
    <Table
      columns={columns}
      data={transformedComplaints}
      storageKey="complaintsTable"
      rowsPerPage={10}
    />
  )
}

export default ComplaintsTable
