import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { IoMdAddCircleOutline, IoMdRemoveCircleOutline } from 'react-icons/io'
import Table from '../../../../../../components/Table/Table'
import { ClientTypes } from '../../../../../../interfaces/client'
import { useDataProvider } from '../../../../../../context/DataProviderCtx/DataProvider'
import RenderIf from '../../../../../../components/UI/RenderIf'

interface Props {
  clients: ClientTypes[]
  selectedClients: ClientTypes[]
  onSelectHandler: (client: ClientTypes) => void
  onRemoveHandler: (client: ClientTypes) => void
  onShowSelectedClientsHandler: () => void
}

interface OriginalTypes {
  cell: { row: { original: ClientTypes } }
}

function MailingListTable({
  clients = [], selectedClients, onSelectHandler, onRemoveHandler, onShowSelectedClientsHandler
}:Props) {
  const { clientPaginate, searchTextClient, onSearchClientHandler } = useDataProvider()
  const { t } = useTranslation()

  const columns = useMemo(() => [
    {
      Header: t('number'),
      accessor: 'client_number',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p>{value || '-'}</p>
    },
    {
      Header: t('fullName'),
      accessor: 'full_name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p className="whitespace-nowrap">{value}</p>
    },
    {
      Header: t('emailList'),
      accessor: 'mailing_lists[0].name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p className="whitespace-nowrap">{value || '-'}</p>
    },
    {
      Header: ' ',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        cell: {
          row: { original }
        }
      }:OriginalTypes) => (
        <div className="flex justify-end">
          <RenderIf
            isTrue={!selectedClients
              .find((client) => client.client_number === original.client_number)}
          >
            <IoMdAddCircleOutline size={25} onClick={() => onSelectHandler(original)} className="cursor-pointer text-[#BEBEBE] transition-colors duration-200 cursor-pointer hover:text-[#4B8FDC]" />
          </RenderIf>
          <RenderIf
            isTrue={!!selectedClients
              .find((client) => client.client_number === original.client_number)}
          >
            <IoMdRemoveCircleOutline size={25} onClick={() => onRemoveHandler(original)} className="cursor-pointer text-[#BEBEBE] transition-colors duration-200 cursor-pointer hover:text-error" />
          </RenderIf>
        </div>

      )
    }

  ], [t, onSelectHandler, onRemoveHandler, selectedClients]);

  return (
    <Table
      columns={columns}
      data={clients}
      storageKey="mailingListTable"
      rowsPerPage={5}
      apiPagination={clientPaginate}
      selectedClients={selectedClients}
      onShowSelectedClientsHandler={onShowSelectedClientsHandler}
      searchText={searchTextClient}
      onSearchHandler={onSearchClientHandler}
    />
  )
}

export default MailingListTable
