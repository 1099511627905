import axios, { AxiosError, AxiosResponse } from 'axios'
import { CronActionsServiceInterface } from './cronActionsService.Interface'
import exception from '../../utils/exception'
import { ServerError } from '../../interfaces/api'
import axiosInstance from '../axiosInstance'
import { CronTaskStateTypes, SyncCronActionTypes } from '../../interfaces/cron-actions'

class CronActionsService implements CronActionsServiceInterface {
  public async sendBrokenGeocodeEmails(): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.get('/clients/client/send-email-for-geocode/')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async sendLastDayOrdersEmails(): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.get('/orders/order/send-email-for-yesterdays-orders/')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async sendOutOfStockProducts(): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.get('/products/product/send-email-for-out-of-stock-products/')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async syncClientsAndProducts(): Promise<SyncCronActionTypes | null> {
    try {
      const response = await axiosInstance.get('/clients/client/sync-customers-and-products/')
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async syncOrders(): Promise<SyncCronActionTypes | null> {
    try {
      const response = await axiosInstance.get('/clients/client/sync-orders/')
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getTaskState(taskId: string | null): Promise<CronTaskStateTypes | null> {
    if (!taskId) return null
    try {
      const response = await axiosInstance.get(`/auth/celery/get-task-state/${taskId}/`)
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}

export default CronActionsService
