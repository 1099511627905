import {
  BsCheckAll,
  BsFillTelephoneInboundFill,
  BsFillTelephoneMinusFill,
  BsFillTelephoneXFill,
  BsCalendar
} from 'react-icons/bs'
import { AgentAnalyticsTypes } from '../../../interfaces/statistics'
import RenderIf from '../../../components/UI/RenderIf'
import CardOverview from '../../../components/UI/CardOverview'
import AgentCardsSkeleton from '../../../components/Skeletons/AgentCardsSkeleton'

interface Props {
  agentAnalytics: AgentAnalyticsTypes
  isLoading: boolean
}

function AgentCardsContainer({ agentAnalytics, isLoading }:Props) {
  return (
    <>
      <RenderIf isTrue={!isLoading}>
        <div className="grid grid-cols-2 xl:grid-cols-5 my-6 gap-[22px] w-full ">
          <CardOverview text="newOrders" number={agentAnalytics?.new_order}>
            <BsCheckAll className="text-success scale-[3] ml-2" />
          </CardOverview>
          <CardOverview text="completedCalls" number={agentAnalytics?.completed}>
            <BsFillTelephoneInboundFill className="text-success" size={26} />
          </CardOverview>
          <div className="col-span-full xl:col-auto">
            <CardOverview text="postponedCalls" number={agentAnalytics?.postponed}>
              <BsCalendar color="#F8D134" size={26} />
            </CardOverview>
          </div>
          <CardOverview text="noAnswerCalls" number={agentAnalytics?.no_answer}>
            <BsFillTelephoneXFill color="#F8D134" size={26} />
          </CardOverview>
          <CardOverview text="busyCalls" number={agentAnalytics?.busy}>
            <BsFillTelephoneMinusFill color="#F8D134" size={26} />
          </CardOverview>

        </div>
      </RenderIf>
      <RenderIf isTrue={isLoading}>
        <AgentCardsSkeleton />
      </RenderIf>
    </>
  )
}

export default AgentCardsContainer
