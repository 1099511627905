import { useTranslation } from 'react-i18next'

interface Props {
  text: string
  fontWeight?: 'font-medium' | 'font-semibold'
}

function WidgetTitle({ text, fontWeight = 'font-medium' }:Props) {
  const { t } = useTranslation()
  return (
    <h2 className={`text-lg text-grey-bold font-semibold ${fontWeight}`}>{t(text)}</h2>
  )
}

export default WidgetTitle
