import { FieldInputProps } from 'formik'
import { useTranslation } from 'react-i18next'
import RenderIf from '../UI/RenderIf'
import InputErrorMessage from '../UI/InputErrorMsg'

interface PropTypes {
  inputId: string
  name: string
  getFieldProps: FieldInputProps<any>
  errors: boolean
  errorName: string | undefined
  type?: string
  width?: 'lg:w-1/3' | 'lg:w-1/2' | 'lg:w-full' | 'md:w-full' | 'w-full'
  marginX?: 'mx-4' | 'mx-0',
  marginY?: 'my-4' | 'my-0',
  [key: string]: any
}

function FormInput({
  inputId,
  name,
  getFieldProps,
  errors,
  errorName,
  type = 'text',
  width = 'lg:w-1/3',
  marginX = 'mx-4',
  marginY = 'my-4',
  ...restProps
}: PropTypes) {
  const { t } = useTranslation()

  return (
    <div className={`relative text-base md:text-lg w-full sm:w-full flex flex-col last:mb-0 ${width} ${marginY}`}>
      <label
        htmlFor={inputId}
        className={`${marginX} mb-2 leading-6 text-grey-pale whitespace-nowrap`}
      >
        {t(name)}
      </label>
      <input
        type={type}
        id={inputId}
        {...getFieldProps}
        {...restProps}
        className={`outline-none ${marginX} border py-1 px-2 rounded-[5px] h-12 ${errors ? 'border-red-700' : 'border-gray-400'
          // eslint-disable-next-line @typescript-eslint/indent
          }`}
      />
      <RenderIf isTrue={!!errors}>
        <div className={`${marginX} absolute w-full`}>
          <InputErrorMessage text={errorName!} />
        </div>
      </RenderIf>
    </div>
  )
}

export default FormInput
