import { ChildrenTypes } from '../../interfaces/children'

interface Props extends ChildrenTypes {
  marginBottom?: 'mb-5' | 'mb-0'
}

function FormLayout({ marginBottom = 'mb-5', children }:Props) {
  return (
    <div className={`p-4 sm:p-8 bg-white rounded-3xl shadow-faint w-full flex mx-auto flex-wrap mb-5 h-[calc(100%-20px)] ${marginBottom}`}>
      {children}
    </div>
  )
}

export default FormLayout
