import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import Title from '../../../../components/UI/Title'
import ProductsTable from './components/ProductsTable'
import RenderIf from '../../../../components/UI/RenderIf'
import TableSkeleton from '../../../../components/Skeletons/TableSkeleton'
import { useDataProvider } from '../../../../context/DataProviderCtx/DataProvider'
import routes from '../../../../constants/routes'

export default function Products() {
  const navigate = useNavigate()
  const { products, isProductsLoading } = useDataProvider()
  const { pathname } = useLocation()
  const isExactPath = pathname === routes.director.products
    || pathname === routes.agent.products
  const [t] = useTranslation()

  useEffect(() => {
    document.title = `KT CRM - ${t('Products')}`
  }, [t])

  return (
    <>
      <RenderIf isTrue={isExactPath}>
        <section>
          <Title results={products} title="Products" add="Add Product" onClickHandler={() => navigate(routes.shared.createProduct)} />
          <RenderIf isTrue={isProductsLoading}>
            <TableSkeleton />
          </RenderIf>
          <RenderIf isTrue={!isProductsLoading}>
            <ProductsTable products={products} />
          </RenderIf>
        </section>
      </RenderIf>

      <RenderIf isTrue={!isExactPath}>
        <Outlet context={products} />
      </RenderIf>
    </>

  )
}
