import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import FormLayout from '../../../../../components/Form/FormLayout'
import FormInput from '../../../../../components/Form/FormInput'
import FormButtons from '../../../../../components/Form/FormButtons'
import FormSelect from '../../../../../components/Form/FormSelect'
import { ComplaintCreationTypes, ComplaintTypes } from '../../../../../interfaces/complaints'
import validationSchema from '../../../../../utils/validationSchema'
import FormTextArea from '../../../../../components/Form/FormTextArea'
import {
  complaintActionOptions,
  complaintDescriptionOptions,
  complaintPriorityOptions,
  complaintStatusOptions
} from '../../../../../helpers/complaint-options'
import { ClientTypes } from '../../../../../interfaces/client'
import SearchClientFormInput from '../../../../../components/Form/SearchClientFormInput'
import FormUploadMedia from '../../../../../components/Form/FormUploadMedia'
import RenderIf from '../../../../../components/UI/RenderIf'
import userMethods from '../../../../../utils/userMethods'

interface Props {
  onSubmit: (values: ComplaintCreationTypes) => void
  isLoading: boolean
  complaint?: ComplaintTypes
}

function ComplaintCreationForm({
  onSubmit, isLoading, complaint
}: Props) {
  const authUser = userMethods.getUser()
  const { t } = useTranslation()
  const [selectedClient, setSelectedClient] = useState<ClientTypes | undefined>()
  const initialValues: ComplaintCreationTypes = {
    creator: complaint ? complaint.creator : '',
    clientInfo: complaint ? complaint.client_name : '',
    id: complaint?.id ?? '',
    notes: complaint?.notes ?? '',
    action: complaint?.action ?? '',
    client: complaint?.client ?? '',
    priority: complaint?.priority ?? '',
    description: complaint?.description ?? '',
    status: complaint?.status ?? '',
    orderNumber: complaint?.order_number ?? '',
    files: []
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema.complaintCreationForm,
    onSubmit
  })

  const onRemoveMediaHandler = (name: string) => {
    // Remove file from formik state
    const filteredMedia = [...formik.values.files].filter((file) => file.name !== name)
    // Create new file list
    const list = new DataTransfer()
    filteredMedia.forEach((file) => list.items.add(file))
    // Set new file list
    formik.setFieldValue('files', list.files)
  }

  const { values, setFieldValue } = formik

  useEffect(() => {
    if (selectedClient && values.clientInfo.trim().length > 2 && !complaint) {
      setFieldValue('client', selectedClient.client_number)
      setFieldValue('clientInfo', `${selectedClient.full_name} - ${selectedClient.vat_number}`)
      setFieldValue('clientAddress', selectedClient.address)
      return
    }

    if (selectedClient && values.clientInfo!.trim().length === 0 && !complaint) {
      setSelectedClient(undefined)
      setFieldValue('client', '')
      setFieldValue('clientInfo', '')
      setFieldValue('clientAddress', '')
    }
  }, [setFieldValue, selectedClient, values.clientInfo, complaint])

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormLayout>
        <div className="flex w-full flex-col xl:flex-row">
          <div className="flex flex-col w-full">
            <div className="w-full grid grid-cols-1 lg:grid-cols-2">
              <RenderIf isTrue={!!complaint && authUser?.user.user_type === 'DIRECTOR'}>
                <FormInput
                  inputId="creator"
                  name="creator"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('creator')}
                  errors={!!(formik.touched.creator && formik.errors.creator)}
                  errorName={formik.errors.creator}
                  disabled
                />
              </RenderIf>
              <SearchClientFormInput
                name="clientName"
                getFieldProps={formik.getFieldProps('clientInfo')}
                errors={!!(formik.touched.clientInfo && formik.errors.clientInfo)}
                errorName={formik.errors.clientInfo}
                formik={formik}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                disabled={!!complaint}
              />
              <RenderIf isTrue={!!complaint && authUser?.user.user_type === 'DIRECTOR'}>
                <FormSelect
                  labelText="description"
                  width="lg:w-full"
                  getFieldProps={formik.getFieldProps('description')}
                  errors={!!(formik.touched.description && formik.errors.description)}
                  errorName={formik.errors.description}
                >
                  {complaintDescriptionOptions.map((option) => (
                    <option
                      key={option.id}
                      value={option.value}
                    >
                      {t(option.label)}
                    </option>
                  ))}
                </FormSelect>
              </RenderIf>
              <FormInput
                inputId="complaintOrderNumber"
                name="complaintOrderNumber"
                width="md:w-full"
                getFieldProps={formik.getFieldProps('orderNumber')}
                errors={!!(formik.touched.orderNumber && formik.errors.orderNumber)}
                errorName={formik.errors.orderNumber}
              />
            </div>
            <div className="w-full flex flex-wrap xl:flex-col">
              <RenderIf isTrue={!complaint || authUser?.user.user_type !== 'DIRECTOR'}>
                <FormSelect
                  labelText="description"
                  width="lg:w-full"
                  getFieldProps={formik.getFieldProps('description')}
                  errors={!!(formik.touched.description && formik.errors.description)}
                  errorName={formik.errors.description}
                >
                  {complaintDescriptionOptions.map((option) => (
                    <option
                      key={option.id}
                      value={option.value}
                    >
                      {t(option.label)}
                    </option>
                  ))}
                </FormSelect>
              </RenderIf>
              <FormTextArea
                inputId="notes"
                name="notes"
                width="w-full"
                rows={5}
                getFieldProps={formik.getFieldProps('notes')}
                errors={!!(formik.touched.notes && formik.errors.notes)}
                errorName={formik.errors.notes}
              />
            </div>
            <FormUploadMedia
              setMedia={formik.setFieldValue}
              media={formik.values.files}
              onRemoveMediaHandler={onRemoveMediaHandler}
              marginLeft="ml-4"
              text="attachments"
              acceptedFiles="image/png, image/jpg, .pdf, .txt"
            />
          </div>
          <div className="flex w-full flex-wrap xl:flex-col  xl:border-l xl:border-l-[#BEBEBE] xl:border-solid xl:w-1/3 xl:ml-8 xl:pl-8">
            <FormSelect
              labelText="ticketStatus"
              getFieldProps={formik.getFieldProps('status')}
              errors={!!(formik.touched.status && formik.errors.status)}
              errorName={formik.errors.status}
              width="lg:w-full"
            >
              {complaintStatusOptions.map((option) => (
                <option
                  key={option.id}
                  value={option.value}
                >
                  {t(option.label)}
                </option>
              ))}
            </FormSelect>
            <FormSelect
              labelText="priority"
              getFieldProps={formik.getFieldProps('priority')}
              errors={!!(formik.touched.priority && formik.errors.priority)}
              errorName={formik.errors.priority}
              width="lg:w-full"
            >
              {complaintPriorityOptions.map((option) => (
                <option
                  key={option.id}
                  value={option.value}
                >
                  {t(option.label)}
                </option>
              ))}
            </FormSelect>
            <FormSelect
              labelText="action"
              getFieldProps={formik.getFieldProps('action')}
              errors={!!(formik.touched.action && formik.errors.action)}
              errorName={formik.errors.action}
              width="lg:w-full"
            >
              {complaintActionOptions.map((option) => (
                <option
                  key={option.id}
                  value={option.value}
                >
                  {t(option.label)}
                </option>
              ))}
            </FormSelect>
          </div>

        </div>

      </FormLayout>
      <FormButtons isLoading={isLoading} btnText={complaint ? t('editComplaint') : t('Add Complaint')} />
    </form>
  )
}

export default ComplaintCreationForm
