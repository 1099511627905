import { useTranslation } from 'react-i18next'
import { useMemo, useEffect } from 'react'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'
import { useModalContext } from '../../../../../context/ModalsCtx/ModalsProvider'
import TableActionBtns from '../../../../../components/Table/TableActionBtns'
import Table from '../../../../../components/Table/Table'
import { FileTypes, NewsletterTypes } from '../../../../../interfaces/newsletters'
import useMutateData from '../../../../../hooks/useMutateData'
import apiService from '../../../../../services/api/apiService'
import routes from '../../../../../constants/routes'
import { useMailingList } from '../../../../../context/MailingListCtx/MailingListProvider'
import { useNewsletters } from '../../../../../context/NewsletterCtx/NewslettersProvider'
import { ProductTypes } from '../../../../../interfaces/products'

interface Props {
  newsletters: NewsletterTypes[]
}

function NewslettersTable({ newsletters = [] }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { openConfirmationModalHandler } = useModalContext()
  const { onResetNewsletterHandler } = useNewsletters()
  const { onEditNewsletterHandler, onResetMailingListHandler } = useMailingList()

  const { mutate: sendNewsletter } = useMutateData({
    key: 'newsletters',
    mutationFn: apiService.sendNewsletter,
    successMessage: 'newsletterHasBeenSend'
  })

  interface OriginalTypes {
    cell: { row: { original: NewsletterTypes } }
  }

  // Reset Newsletter state on mount
  useEffect(() => {
    onResetNewsletterHandler()
    onResetMailingListHandler()
  }, [onResetNewsletterHandler, onResetMailingListHandler])

  const columns = useMemo(() => [
    {
      Header: t('campaignName'),
      accessor: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p>{value || '-'}</p>
    },
    {
      Header: t('emailSubject'),
      accessor: 'email_subject',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p title={value} className="truncate max-w-[180px]">{value}</p>
    },
    {
      Header: t('emailList'),
      accessor: 'mailing_lists[0].name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p>{value || '-'}</p>
    },
    {
      Header: t('creationDate'),
      accessor: 'created_date',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p>{moment(value).format('DD/MM/YYYY')}</p>
    },
    {
      Header: t('state'),
      accessor: 'status',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p>{t(value)}</p>
    },
    {
      Header: t('sendDates'),
      accessor: 'sent_dates',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: NewsletterTypes['sent_dates'][] }) => {
        const sentDates = value.map((date) => `${moment(date.sent_date).format('DD/MM/YYYY, HH:mm')} `)
        return (
          <p className="line-clamp-2" title={`${sentDates}`}>
            {sentDates.length > 0 ? sentDates : '-'}
          </p>
        )
      }
    },
    {
      Header: t('content'),
      accessor: 'email_body',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => {
        const content = new DOMParser().parseFromString(value, 'text/html').getElementById('content')?.textContent ?? '-'
        return (
          <p className="line-clamp-2 text-ellipsis max-w-[700px] w-full" title={`${content}`}>
            {content}
          </p>
        )
      }
    },
    {
      Header: t('products'),
      accessor: 'products_on_offer',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:ProductTypes[] }) => {
        const products = value.map((prd) => `${prd.product_name} `)
        return (
          <p className="line-clamp-2 text-ellipsis max-w-[700px] w-full" title={`${products}`}>
            {products.length > 0 ? products : '-'}
          </p>
        )
      }
    },
    {
      Header: t('files'),
      accessor: 'files',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:FileTypes[] }) => {
        const files = value.map((file) => `${file.file?.split('/').at(-1)} `)
        return (
          <p className="line-clamp-2 max-w-[200px]" title={`${files}`}>
            {files.length > 0 ? files : '-'}
          </p>
        )
      }
    },
    {
      Header: t(' '),
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        cell: {
          row: { original }
        }
      }:OriginalTypes) => (
        <TableActionBtns
          onClickHandlerSend={() => sendNewsletter(original.id)}
          onClickHandlerEdit={() => {
            onEditNewsletterHandler(original)
            navigate(routes.shared.createNewsletter)
          }}
          onClickHandlerDelete={() => openConfirmationModalHandler('deleteNewsletterMessage', {
            id: original.id,
            userRole: null
          })}

        />
      )
    }
  ], [t, openConfirmationModalHandler, navigate, onEditNewsletterHandler, sendNewsletter]);

  return (
    <Table
      columns={columns}
      data={newsletters}
      storageKey="newslettersTable"
      rowsPerPage={5}
    />
  )
}

export default NewslettersTable
