import { useTranslation } from 'react-i18next'
import RenderIf from './RenderIf'

interface Props {
  type: 'add' | 'all'
  text: string
  onClickHandler: () => void
}

function TasKButton({ type, text, onClickHandler }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <RenderIf isTrue={type === 'add'}>
        <button type="submit" className="rounded-[5px] bg-[#EFF3F8] text-sm font-semibold text-primary-text-dark px-[10px] py-[8px]" onClick={onClickHandler}>
          {t(text)}
        </button>
      </RenderIf>
      <RenderIf isTrue={type === 'all'}>
        <button type="submit" className="text-sm font-semibold text-primary-text-dark underline ml-auto" onClick={onClickHandler}>
          {t(text)}
        </button>
      </RenderIf>
    </>
  )
}

export default TasKButton
