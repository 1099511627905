import { useTranslation } from 'react-i18next'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import UserCreationForm from '../components/UserCreationForm'
import useMutateData from '../../../../../hooks/useMutateData'
import apiService from '../../../../../services/api/apiService'
import { UserCreationTypes } from '../../../../../interfaces/user'
import PageTitle from '../../../../../components/UI/PageTitle'
import { UserOutletContextTypes } from '../../../../../interfaces/outlet-context'
import PageSection from '../../../../../components/UI/PageSection'

function UpdateAgent() {
  const [t] = useTranslation()
  const navigate = useNavigate()

  const params = useParams()
  const urlParams = params.id?.split('-')
  const { users, managers }: UserOutletContextTypes = useOutletContext()

  const user = users?.find((usr) => usr.id.toString() === urlParams?.[1]
    && usr.user.user_type === urlParams?.[0].toUpperCase())

  const { isLoading, mutate: updateUser } = useMutateData(
    {
      key: ['users', 'managers', 'agents'],
      mutationFn: apiService.updateUser,
      successMessage: 'userHasBeenUpdated'
    }
  )

  const submitFormHandler = (values: UserCreationTypes) => {
    updateUser({ id: user!.id, values }, {
      onSuccess: () => navigate(-1)
    })
  }

  useEffect(() => {
    document.title = `Emoria CRM - ${t('Update Employee')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text={t('Update Employee')} />
      <UserCreationForm
        onSubmit={submitFormHandler}
        user={user}
        isLoading={isLoading}
        managers={managers}
      />
    </PageSection>
  )
}

export default UpdateAgent
