import { useTranslation } from 'react-i18next'

interface Props {
  text: string
}

function SubTitle({ text }:Props) {
  const { t } = useTranslation();
  return (
    <p className="text-xl font-medium text-primary-text-dark">
      {t(text)}
    </p>
  )
}

export default SubTitle
