import DatePicker from 'react-datepicker'
import { Dispatch, SetStateAction } from 'react'
import WidgetTitle from '../UI/WidgetTitle'
import ChartPredefinedDateRanges from './ChartPredefinedDateRanges'
import RenderIf from '../UI/RenderIf'
import CustomDatePicker from '../CustomDatePicker/DatePicker'
import { PredefinedRangeTypes } from '../../interfaces/statistics'

interface Props {
  text: string,
  predefinedDateRange: PredefinedRangeTypes
  setPredefinedDateRange: Dispatch<SetStateAction<PredefinedRangeTypes>>
  setDateRange: Dispatch<SetStateAction<Date[]>>
  startDate: Date
  endDate: Date
}

function ChartHeaderFilters({
  text,
  startDate,
  endDate,
  predefinedDateRange,
  setDateRange,
  setPredefinedDateRange
}:Props) {
  return (
    <div className="mb-5 flex items-center justify-between gap-2 flex-wrap ">
      <WidgetTitle text={text} fontWeight="font-semibold" />
      <div className="flex flex-col sm:flex-row sm:gap-1 sm:items-end w-max">
        <ChartPredefinedDateRanges
          predefinedDateRange={predefinedDateRange}
          setPredefinedDateRange={setPredefinedDateRange}
        />
        <RenderIf isTrue={predefinedDateRange === 'selectDateRange'}>
          <DatePicker
            disabled={predefinedDateRange !== 'selectDateRange'}
            locale="el"
            dateFormat="dd/MM/yyyy"
            calendarClassName="bg-white"
            maxDate={new Date()}
            selectsRange
            startDate={startDate}
            placeholderText="Select Date Range"
            endDate={endDate}
            onChange={(update) => {
              if (predefinedDateRange === 'selectDateRange') {
                setDateRange(update as Date[])
              }
            }}
            customInput={<CustomDatePicker />}
          />
        </RenderIf>
      </div>
    </div>
  )
}

export default ChartHeaderFilters
