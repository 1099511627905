import { useTranslation } from 'react-i18next'

interface Props {
  text: string
}

function FormTitle({ text }:Props) {
  const { t } = useTranslation()
  return (
    <h3 className="text-primary-text-dark font-semibold text-lg my-4">{t(text)}</h3>
  )
}

export default FormTitle
