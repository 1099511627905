import {
  PieChart, Pie, Cell, ResponsiveContainer, Legend
} from 'recharts';
import moment from 'moment/moment'
import useGetData from '../../hooks/useGetData'
import { TotalWorthProductTypeOrdersStatisticsTypes } from '../../interfaces/statistics'
import apiService from '../../services/api/apiService'
import ChartHeaderFilters from './ChartHeaderFilters'
import usePredefinedDateRange, { PredefinedDateRangeTypes } from '../../hooks/usePredefinedDateRange'
import RenderIf from '../UI/RenderIf'
import WidgetTitle from '../UI/WidgetTitle'

interface Props {
  agent?: string
  gridCss?: string
  predefinedDateRange?: PredefinedDateRangeTypes
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

function TotalWorthProductTypeChart({ agent, gridCss, predefinedDateRange }: Props) {
  const localPredefinedDateRange = usePredefinedDateRange()

  const disabled = predefinedDateRange?.predefinedDateRange
    !== predefinedDateRange?.shouldFetchValue
    || !predefinedDateRange?.endDate

  const localDisabled = localPredefinedDateRange.predefinedDateRange
    !== localPredefinedDateRange.shouldFetchValue
    || !localPredefinedDateRange.endDate

  const { data } = useGetData<TotalWorthProductTypeOrdersStatisticsTypes[]>({
    queryKey: `worthOfOrdersByProductType, ${predefinedDateRange?.predefinedDateRange ?? localPredefinedDateRange.predefinedDateRange} ${agent}`,
    queryFn: () => apiService.getTotalWorthProductTypeOrdersStatistics({
      from: moment(predefinedDateRange?.startDate ?? localPredefinedDateRange.startDate).format('YYYY-MM-DD'),
      to: moment(predefinedDateRange?.endDate ?? localPredefinedDateRange.endDate).format('YYYY-MM-DD'),
      agent
    }),
    disabled: predefinedDateRange?.predefinedDateRange ? disabled : localDisabled
  })

  const dataArray = data?.map((object) => ({
    name: object?.orderproduct__product__product_category,
    value: object?.sum
  }))

  const print = 'print:w-[800px] print:flex-row print:justify-center'
  return (
    <div className={`${gridCss} bg-[#FBFBFB] rounded-[10px] p-[10px] md:py-5 md:px-8 h-full `}>
      <RenderIf isTrue={!!predefinedDateRange}>
        <WidgetTitle text="valueOfOrdersPerKind" fontWeight="font-semibold" />
      </RenderIf>
      <RenderIf isTrue={!predefinedDateRange}>
        <ChartHeaderFilters
          text="valueOfOrdersPerKind"
          predefinedDateRange={localPredefinedDateRange.predefinedDateRange}
          endDate={localPredefinedDateRange.endDate}
          startDate={localPredefinedDateRange.startDate}
          setPredefinedDateRange={localPredefinedDateRange.setPredefinedDateRange}
          setDateRange={localPredefinedDateRange.setDateRange}
        />
      </RenderIf>

      <div className={`w-full h-full flex flex-col md:flex-row items-center md:justify-center ${print}`}>
        <ResponsiveContainer width="99%" height="90%" minHeight={400}>
          <PieChart>
            <Pie
              data={dataArray ?? []}
              innerRadius={70}
              outerRadius={100}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {dataArray?.map((entry, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Legend align="right" verticalAlign="bottom" />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default TotalWorthProductTypeChart
