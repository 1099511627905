import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import ChartHeaderFilters from './ChartHeaderFilters'
import usePredefinedDateRange, { PredefinedDateRangeTypes } from '../../hooks/usePredefinedDateRange'
import { ReactComponent as Coinbag } from '../../assets/images/coin-bag-line.svg'
import { ReactComponent as CardList } from '../../assets/images/card-list.svg'
import useGetData from '../../hooks/useGetData'
import { TotalOrderValuesStatisticsTypes } from '../../interfaces/statistics'
import apiService from '../../services/api/apiService'
import RenderIf from '../UI/RenderIf'
import WidgetTitle from '../UI/WidgetTitle'

interface Props {
  agent?: string
  gridCss?: string
  predefinedDateRange?: PredefinedDateRangeTypes
}

function TotalOrderValues({ agent, gridCss, predefinedDateRange }: Props) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const localPredefinedDateRange = usePredefinedDateRange()
  const isAgentInfoPage = pathname.includes('agents')
  const disabled = predefinedDateRange?.predefinedDateRange
    !== predefinedDateRange?.shouldFetchValue
    || !predefinedDateRange?.endDate

  const localDisabled = localPredefinedDateRange.predefinedDateRange
    !== localPredefinedDateRange.shouldFetchValue
    || !localPredefinedDateRange.endDate

  const { data, isLoading } = useGetData<TotalOrderValuesStatisticsTypes>({
    queryKey: `totalOrderValues ${predefinedDateRange?.predefinedDateRange ?? localPredefinedDateRange.predefinedDateRange} ${agent}`,
    queryFn: () => apiService.getTotalOrderValuesStatistics({
      from: moment(predefinedDateRange?.startDate ?? localPredefinedDateRange.startDate).format('YYYY-MM-DD'),
      to: moment(predefinedDateRange?.endDate ?? localPredefinedDateRange.endDate).format('YYYY-MM-DD'),
      agent
    }),
    disabled: predefinedDateRange?.predefinedDateRange ? disabled : localDisabled
  })
  return (
    <div className={`${gridCss} p-6 sm:p-5 border border-grey-statistics rounded-[10px] flex-col h-max `}>
      <RenderIf isTrue={!!predefinedDateRange}>
        <WidgetTitle text="valueAndNumberOfOrders" fontWeight="font-semibold" />
      </RenderIf>
      <RenderIf isTrue={!predefinedDateRange}>
        <ChartHeaderFilters
          text="valueAndNumberOfOrders"
          predefinedDateRange={localPredefinedDateRange.predefinedDateRange}
          endDate={localPredefinedDateRange.endDate}
          startDate={localPredefinedDateRange.startDate}
          setPredefinedDateRange={localPredefinedDateRange.setPredefinedDateRange}
          setDateRange={localPredefinedDateRange.setDateRange}
        />
      </RenderIf>
      <RenderIf isTrue={!isLoading}>
        <div className={`flex flex-col gap-5 justify-center mt-5 ${isAgentInfoPage ? 'xl:h-[calc(100%-90px)]' : 'h-full'}`}>
          <div className="flex flex-wrap items-baseline gap-2 sm:gap-4 ">
            <Coinbag />
            <span className="font-bold text-4xl">{`${data?.worth_of_orders ?? 0}€`}</span>
            <span className="text-base text-grey-pale">{t('worthOfOrders')}</span>
          </div>

          <div className="flex flex-wrap items-baseline gap-2 sm:gap-4">
            <CardList />
            <span className="font-bold text-4xl">{`${data?.number_of_orders ?? 0}`}</span>
            <span className="text-base text-grey-pale">{t('numberOfOrders')}</span>
          </div>
        </div>
      </RenderIf>
    </div>
  )
}

export default TotalOrderValues
