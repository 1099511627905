import { FieldInputProps } from 'formik'
import { DebounceInput } from 'react-debounce-input'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction } from 'react'
import RenderIf from '../UI/RenderIf'
import InputErrorMessage from '../UI/InputErrorMsg'
import useGetData from '../../hooks/useGetData'
import apiService from '../../services/api/apiService'
import { ClientPaginateTypes, ClientTypes } from '../../interfaces/client'
import Spinner from '../UI/Spinner'

interface PropTypes {
  name: string
  getFieldProps: FieldInputProps<any>
  errors: boolean
  errorName: string | undefined
  formik: any
  selectedClient: ClientTypes | undefined
  setSelectedClient: Dispatch<SetStateAction<ClientTypes | undefined>>
  disabled?: boolean
}

function SearchClientFormInput({
  name,
  getFieldProps,
  errors,
  errorName,
  formik,
  selectedClient,
  setSelectedClient,
  disabled = false
}: PropTypes) {
  const { t } = useTranslation()
  const { data: clients, isLoading } = useGetData<ClientPaginateTypes>({
    queryKey: formik.values.clientInfo,
    queryFn: () => apiService.getClients({ pageParam: 1, searchText: formik.values.clientInfo }),
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    disabled: formik.values.clientInfo.length < 3 || !!selectedClient || disabled
  })

  const isSearchListNotEmptyClasses = clients?.results.length > 0 && formik.values.clientInfo.length > 2 ? 'scale-100 opacity-100' : 'scale-0 opacity-0'

  return (
    <div className="relative text-base md:text-lg w-full md:w-full sm:w-full flex flex-col mt-4 mb-5 relative">
      <label
        htmlFor={name}
        className="mx-4 mb-2 leading-6 text-grey-pale whitespace-nowrap"
      >
        {t(name)}
      </label>
      <div className={`flex items-center justify-between mx-4 border rounded-[5px] py-1 px-2 h-12 ${
        errors ? 'border-red-700' : 'border-gray-400'
      }`}
      >
        <DebounceInput
          autoComplete="off"
          type="text"
          placeholder={t('clientNameOrVat')}
          debounceTimeout={1500}
          id={name}
          {...getFieldProps}
          className="outline-none h-full w-full"
          disabled={disabled}
        />
        <RenderIf isTrue={isLoading}>
          <div className="ml-auto">
            <Spinner />
          </div>
        </RenderIf>
      </div>
      <RenderIf isTrue={!!errors}>
        <div className="mx-4 mt-1 absolute w-full">
          <InputErrorMessage text={errorName!} />
        </div>
      </RenderIf>
      <div
        className={`${isSearchListNotEmptyClasses} absolute left-0 right-0 max-h-[300px] mx-4 overflow-y-auto bg-white rounded-md top-[90px] z-[500] origin-top transition-all duration-300 shadow-faint`}
      >
        {clients?.results.map((client) => (
          <button
            type="button"
            key={client.client_number}
            onClick={() => setSelectedClient(client)}
            className="flex gap-2 w-[calc(100%-24px)] items-center text-left cursor-pointer mx-3 border-b border-solid border-b-[#f2f2f2] py-2 last:border-b-0 hover:bg-[rgba(0,0,0,0.02)] transition-colors duration-200"
          >
            <p>{client.full_name}</p>
            <p>-</p>
            <p>{client.vat_number}</p>
          </button>
        ))}
      </div>
    </div>
  )
}

export default SearchClientFormInput
