import { useTranslation } from 'react-i18next'
import { ChildrenTypes } from '../../interfaces/children'

interface Props extends ChildrenTypes {
  text: string
  number: number
  secondaryNumber?: string
}

function CardOverview({
  children, text, number, secondaryNumber
}:Props) {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col justify-between rounded-[16px] shadow-md px-4 py-7 sm:p-6 text-primary-text-dark bg-white h-[163px]">
      <h3 title={t(text)} className="text-[15px] sm:text-lg font-semibold sm:truncate max-w-[220px]">{t(text)}</h3>
      <div className="flex items-center gap-4 sm:gap-7">
        {children}
        <p className="text-[35px] font-bold">
          {number}
          <span className="ml-1 text-[#BCBCBC] text-lg font-semibold">{secondaryNumber}</span>
        </p>
      </div>
    </div>
  )
}

export default CardOverview
