import {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { ChildrenTypes } from '../../interfaces/children'
import { ProductTypes } from '../../interfaces/products'
import { useDataProvider } from '../DataProviderCtx/DataProvider'
import { FileTypes, NewsletterGetterDataTypes } from '../../interfaces/newsletters'
import { NewslettersCtxTypes } from './newslettersCtx.interface'
import newslettersCtx from './newslettersCtx'

export const useNewsletters = ():NewslettersCtxTypes => useContext(newslettersCtx)

function NewslettersProvider({ children }: ChildrenTypes) {
  const { products } = useDataProvider()

  const [isEditNewsletter, setIsEditNewsletter] = useState<NewslettersCtxTypes['isEditNewsletter']>(false)
  const [selectedNewsletterId, setSelectedNewsletterId] = useState<NewsletterGetterDataTypes['selectedNewsletterId']>(null)
  const [campaignName, setCampaignName] = useState<NewsletterGetterDataTypes['campaignName']>(null)
  const [emailSubject, setEmailSubject] = useState<NewsletterGetterDataTypes['emailSubject']>(null)

  const [newsletterFiles, setNewsletterFiles] = useState<NewsletterGetterDataTypes['newsletterFiles']>(null)
  const [existingFiles, setExistingFiles] = useState<NewslettersCtxTypes['existingFiles']>([])

  const [emailTemplate, setEmailTemplate] = useState<NewsletterGetterDataTypes['emailTemplate']>(null)
  const [selectedNewsletterProducts, setSelectedNewsletterProducts] = useState<NewsletterGetterDataTypes['selectedNewsletterProducts']>([])

  const [emailGreeting, setEmailGreeting] = useState<NewslettersCtxTypes['emailGreeting']>(null)
  const [content, setContent] = useState<NewslettersCtxTypes['content']>(null)
  const [newsletterProducts, setNewsletterProducts] = useState<NewslettersCtxTypes['newsletterProducts']>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [emailImage, setEmailImage] = useState<undefined | File>(undefined)
  const [emailImageUrl, setEmailImageUrl] = useState<null | FileTypes>(null)

  const setter = useMemo(() => ({
    setCampaignName,
    setEmailSubject,
    setContent,
    setEmailTemplate,
    setEmailGreeting,
    setNewsletterProducts,
    setSelectedNewsletterProducts,
    setNewsletterFiles,
    setEmailImage,
    setEmailImageUrl,
    setIsEditNewsletter,
    setExistingFiles,
    setSelectedNewsletterId
  }), []);

  const getter: NewsletterGetterDataTypes = useMemo(() => ({
    campaignName,
    emailSubject,
    emailTemplate,
    selectedNewsletterProducts,
    newsletterFiles,
    selectedNewsletterId
  }), [
    campaignName,
    emailSubject,
    emailTemplate,
    selectedNewsletterProducts,
    newsletterFiles,
    selectedNewsletterId
  ])

  // const onSearchValueChangeHandler = useCallback((
  //   event: FormEvent<HTMLInputElement>
  // ) => {
  //   const { value } = event.currentTarget
  //   setSearchValue(value)
  //   if (value.length > 2) {
  //     const searchedResults = products?.filter((product) => product.product_name
  //       .toLowerCase().includes(value.toLowerCase())
  //       || product.product_sku.toLowerCase()
  //         .startsWith(value.toLowerCase()))
  //     setNewsletterProducts(searchedResults)
  //   } else {
  //     setNewsletterProducts(products)
  //   }
  // }, [products])

  const onSelectedNewsletterProductsHandler = useCallback((selectedProduct: ProductTypes) => {
    setSelectedNewsletterProducts((prevProducts) => {
      const isAlreadySelected = prevProducts
        .some((product) => product.id === selectedProduct.id)
      if (isAlreadySelected) {
        return prevProducts
      }
      return [...prevProducts, selectedProduct]
    })
  }, [])

  const onRemoveProductHandler = useCallback((selectedProduct: ProductTypes) => {
    setSelectedNewsletterProducts((prevProducts) => prevProducts
      .filter((prevProduct) => prevProduct.id !== selectedProduct.id))
  }, [])

  const onClearSelectedNewsletterProductsHandler = useCallback(() => {
    setSelectedNewsletterProducts([])
  }, [])

  // Reset newsletter data
  const onResetNewsletterHandler = useCallback(() => {
    setCampaignName(null)
    setEmailSubject(null)
    setContent(null)
    setEmailTemplate(null)
    setEmailGreeting(null)
    setSelectedNewsletterProducts([])
    setNewsletterFiles(null)
    setEmailImage(undefined)
    setEmailImageUrl(null)
    setIsEditNewsletter(false)
    setExistingFiles([])
    setSelectedNewsletterId(null)
  }, [])

  useEffect(() => {
    if (products) {
      setNewsletterProducts(products)
    }
  }, [products])

  const ctx = useMemo(() => ({
    content,
    emailGreeting,
    emailImage,
    emailImageUrl,
    newsletterProducts,
    isEditNewsletter,
    existingFiles,
    setter,
    searchValue,
    getter,
    // onSearchValueChangeHandler,
    onSelectedNewsletterProductsHandler,
    onRemoveProductHandler,
    onClearSelectedNewsletterProductsHandler,
    onResetNewsletterHandler
  }), [
    content,
    emailGreeting,
    emailImage,
    emailImageUrl,
    newsletterProducts,
    existingFiles,
    isEditNewsletter,
    setter,
    getter,
    searchValue,
    // onSearchValueChangeHandler,
    onSelectedNewsletterProductsHandler,
    onRemoveProductHandler,
    onClearSelectedNewsletterProductsHandler,
    onResetNewsletterHandler
  ])

  return (
    <newslettersCtx.Provider value={ctx}>
      {children}
    </newslettersCtx.Provider>
  )
}

export default NewslettersProvider
