import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import parse from 'html-react-parser'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import RenderIf from '../../../../../../components/UI/RenderIf'
import { useNewsletters } from '../../../../../../context/NewsletterCtx/NewslettersProvider'
import apiService from '../../../../../../services/api/apiService'
import EmailPreviewProducts from './EmailPreviewProducts'
import EmailPreviewFooter from './EmailPreviewFooter'
import EmailPreviewImage from './EmailPreviewImage'

interface Props {
  content: string
  emailGreeting: string
}

function EmailPreview({
  content,
  emailGreeting
}: Props) {
  const { t } = useTranslation()
  const [isEmailImageLoading, setIsEmailImageLoading] = useState<boolean>(false)
  const { pathname } = useLocation()
  const newslettersCtx = useNewsletters()

  const isEmailDesign = pathname.includes('design-email')
  const { setEmailImage, setEmailImageUrl } = newslettersCtx.setter

  useEffect(() => {
    if (newslettersCtx.emailImage) {
      if (newslettersCtx.emailImage.size > 5000000) {
        toast.warn<string>(t('emailImageSize5MB'))
        setEmailImage(undefined)
        return
      }

      setIsEmailImageLoading(true)
      apiService.createFile(newslettersCtx.emailImage)
        .then((res) => {
          setEmailImageUrl(res)
        })
        .catch((error) => {
          toast.error<string>(error?.message)
        })
        .finally(() => {
          setEmailImage(undefined)
          setIsEmailImageLoading(false)
        })
    }
  }, [
    t,
    setEmailImage,
    newslettersCtx.emailImage,
    setEmailImageUrl
  ])

  return (
    <div className="w-full h-[calc(100%-30px)] ">
      <p className="text-primary-text-dark text-lg font-semibold mb-3">{t('emailPreview')}</p>
      <div className="rounded-[10px] overflow-hidden border-b border-b-[#EFEFEF] h-full">
        <div
          id="emailTemplate"
          style={{
            boxSizing: 'border-box',
            border: '1px solid #EFEFEF',
            width: '100%',
            height: '100%',
            overflowY: 'hidden',
            paddingBottom: '30px',
            margin: '0'
          }}
        >
          <div style={{
            width: '100%',
            boxSizing: 'border-box',
            backgroundColor: '#ffffff',
            height: '150px',
            paddingTop: '20px'
          }}
          >
            <img
              alt="Emoria Logo"
              src="https://crm.emoria.eu:8443/static/images/emoria-color.png"
              style={{
                boxSizing: 'border-box',
                display: 'block',
                width: '270px',
                height: '97px',
                margin: '0 auto'
              }}
            />
          </div>
          <div
            style={{
              boxSizing: 'border-box',
              wordBreak: 'break-word',
              borderRadius: '16px',
              margin: '20px auto 0 auto',
              maxWidth: '600px',
              width: '100%',
              minHeight: '150px',
              backgroundColor: '#fff',
              border: '1px solid #EFEFEF'
            }}
          >
            <EmailPreviewImage
              isLoading={isEmailImageLoading}
              isEmailDesign={isEmailDesign}
              newslettersCtx={newslettersCtx}
            />
            <div
              style={{
                padding: '20px'
              }}
            >
              <div id="content">
                {parse(content)}
              </div>
              <RenderIf isTrue={newslettersCtx.getter.selectedNewsletterProducts?.length !== 0}>
                <EmailPreviewProducts
                  products={newslettersCtx.getter.selectedNewsletterProducts}
                  onRemoveProduct={newslettersCtx.onRemoveProductHandler}
                  isEmailDesign={isEmailDesign}
                />
              </RenderIf>
              <p
                id="emailGreeting"
                style={{
                  boxSizing: 'border-box',
                  marginTop: '20px',
                  whiteSpace: 'pre-line'
                }}
              >
                {emailGreeting}
              </p>
            </div>
          </div>
          <EmailPreviewFooter />
        </div>
      </div>
    </div>
  )
}

export default EmailPreview
