import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import FormLayout from '../../../../../components/Form/FormLayout'
import FormInput from '../../../../../components/Form/FormInput'
import FormButtons from '../../../../../components/Form/FormButtons'
import validationSchema from '../../../../../utils/validationSchema'
import UserMethods from '../../../../../utils/userMethods'
import useMutateData from '../../../../../hooks/useMutateData'
import apiService from '../../../../../services/api/apiService'
import { UserTypes } from '../../../../../interfaces/user'

function UpdateUserDetailsForm() {
  const { t } = useTranslation()
  const authUser = UserMethods.getUser()
  const { mutate: updateMe, isLoading } = useMutateData({
    key: ['users', 'managers', 'agents'],
    mutationFn: apiService.updateMe,
    successMessage: 'yourDetailsHaveBeenUpdated'
  })

  const formik = useFormik({
    initialValues: {
      id: authUser?.id ?? '',
      firstName: authUser?.user.first_name ?? '',
      lastName: authUser?.user.last_name ?? '',
      email: authUser?.user.email ?? '',
      phone: authUser?.user.telephone ?? '',
      username: authUser?.user.username ?? '',
      role: authUser?.user.user_type ?? '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: () => validationSchema.updateUserDetailsForm(formik.values.password),
    onSubmit: (values) => {
      if (values.username.trim() === authUser?.user.username
        && values.email.trim() === authUser?.user.email
        && values.phone.trim() === authUser?.user.telephone
        && values.firstName.trim() === authUser?.user.first_name
        && values.lastName.trim() === authUser?.user.last_name
        && values.password.trim().length === 0
      ) {
        return
      }

      updateMe(values, {
        onSuccess: (data) => {
          const user = data as unknown as UserTypes
          UserMethods.saveUser(user)
          formik.values.password = ''
          formik.values.confirmPassword = ''
        }
      })
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormLayout>
        <FormInput
          inputId="firstName"
          name="firstName"
          getFieldProps={formik.getFieldProps('firstName')}
          errors={
            !!(formik.touched.lastName && formik.errors.firstName)
          }
          errorName={formik.errors.firstName}
        />
        <FormInput
          inputId="lastName"
          name="lastName"
          getFieldProps={formik.getFieldProps('lastName')}
          errors={
            !!(formik.touched.lastName && formik.errors.lastName)
          }
          errorName={formik.errors.lastName}
        />
        <FormInput
          inputId="username"
          name="Username"
          getFieldProps={formik.getFieldProps('username')}
          errors={
            !!(formik.touched.username && formik.errors.username)
          }
          errorName={formik.errors.username}
        />
        <FormInput
          inputId="email"
          name="Email"
          getFieldProps={formik.getFieldProps('email')}
          errors={!!(formik.touched.email && formik.errors.email)}
          errorName={formik.errors.email}
        />
        <FormInput
          inputId="phone"
          name="Phone"
          getFieldProps={formik.getFieldProps('phone')}
          errors={!!(formik.touched.phone && formik.errors.phone)}
          errorName={formik.errors.phone}
        />
        <hr className="mx-4 mt-8 mb-10 text-grey-pale w-full" />
        <div>
          <p className="md:text-lg mx-4 mb-2 leading-6 text-grey-pale">
            {t('passwordChangeWarning')}
            .
          </p>
        </div>
        <div className="flex flex-wrap mb-8 w-full">
          <FormInput
            type="password"
            inputId="password"
            name="newPassword"
            autoComplete="new-password"
            getFieldProps={formik.getFieldProps('password')}
            errors={
              !!(formik.touched.password && formik.errors.password)
            }
            errorName={formik.errors.password}
          />
          <FormInput
            type="password"
            inputId="confirmPassword"
            name="confirmNewPassword"
            autoComplete="new-password"
            getFieldProps={formik.getFieldProps('confirmPassword')}
            errors={
              !!(formik.touched.confirmPassword && formik.errors.confirmPassword)
            }
            errorName={formik.errors.confirmPassword}
          />
        </div>
      </FormLayout>
      <FormButtons isLoading={isLoading} btnText="saveChanges" />
    </form>
  )
}

export default UpdateUserDetailsForm
