import { Route, Routes } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import { ReactQueryDevtools } from 'react-query/devtools'
import routes from './constants/routes'
import Layout from './containers/Layout'
import 'react-toastify/dist/ReactToastify.css'
import Login from './pages/Login/Login'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import RenderIf from './components/UI/RenderIf'
import Products from './pages/Shared/pages/Products/Products'
import ProtectedRoutes from './components/Routes/ProtectedRoutes'
import PublicRoutes from './components/Routes/PublicRoutes'
import CreateAgent from './pages/Shared/pages/Agents/pages/CreateAgent'
import Director from './pages/Director/Director'
import Agents from './pages/Shared/pages/Agents/Agents'
import UpdateAgent from './pages/Shared/pages/Agents/pages/UpdateAgent'
import Complaints from './pages/Shared/pages/Complaints/Complaints'
import Statistics from './pages/Director/pages/Statistics'
import CreateComplaint from './pages/Shared/pages/Complaints/pages/CreateComplaint'
import UserInfo from './pages/Shared/pages/Agents/pages/UserInfo'
import SalesAgent from './pages/SalesAgent/SalesAgent'
import ComplaintInfo from './pages/Shared/pages/Complaints/pages/ComplaintInfo'
import UpdateComplaint from './pages/Shared/pages/Complaints/pages/UpdateComplaint'
import Tasks from './pages/Shared/pages/Tasks/Tasks'
import ProductInfo from './pages/Shared/pages/Products/pages/ProductInfo'
import CreateProduct from './pages/Shared/pages/Products/pages/CreateProduct'
import Settings from './pages/Shared/pages/Settings/Settings'

import 'react-datepicker/dist/react-datepicker.css'
import Newsletters from './pages/Shared/pages/Newsletters/Newsletters'
import CreateNewsletter from './pages/Shared/pages/Newsletters/pages/CreateNewsletter'
import CampaignDetails from './pages/Shared/pages/Newsletters/pages/CampaignDetails'
import DesignEmail from './pages/Shared/pages/Newsletters/pages/DesignEmail'
import CampaignPreview from './pages/Shared/pages/Newsletters/pages/CampaignPreview'
import ActivateAccount from './pages/ActivateAccount/ActivateAccount'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import UpdateProduct from './pages/Shared/pages/Products/pages/UpdateProduct'

function App() {
  return (
    <>
      <Routes>
        <Route path={routes.activateAccount} element={<ActivateAccount />} />
        <Route path={routes.resetPassword} element={<ResetPassword />} />
        <Route element={<PublicRoutes />}>
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.forgotPassword} element={<ForgotPassword />} />
        </Route>

        {/* DIRECTOR ROUTES */}
        <Route element={<ProtectedRoutes userRole="DIRECTOR" />}>
          <Route element={<Layout userRole="DIRECTOR" />}>
            <Route path={routes.director.home} element={<Director />} />
            <Route path={routes.director.statistics} element={<Statistics />} />
            <Route path={routes.director.agents} element={<Agents />}>
              <Route
                path={routes.shared.createAgent}
                element={<CreateAgent />}
              />
              <Route
                path={`${routes.shared.updateAgent}/:id`}
                element={<UpdateAgent />}
              />
              <Route
                path={`${routes.shared.userInfo}/:id`}
                element={<UserInfo />}
              />
            </Route>
            <Route path={routes.director.products} element={<Products />}>
              <Route
                path={`${routes.shared.productInfo}/:id`}
                element={<ProductInfo />}
              />
              <Route
                path={`${routes.shared.createProduct}`}
                element={<CreateProduct />}
              />
              <Route
                path={`${routes.shared.updateProduct}/:id`}
                element={<UpdateProduct />}
              />
            </Route>
            <Route path={routes.director.complaints} element={<Complaints />}>
              <Route
                path={routes.shared.createComplaint}
                element={<CreateComplaint />}
              />
              <Route
                path={`${routes.shared.updateComplaint}/:id`}
                element={<UpdateComplaint />}
              />
              <Route
                path={`${routes.shared.complaintDetails}/:id`}
                element={<ComplaintInfo />}
              />
            </Route>
            <Route path={routes.director.tasks} element={<Tasks />} />
            <Route path={routes.director.settings} element={<Settings />} />
            <Route path={routes.director.newsletters} element={<Newsletters />}>
              <Route
                path={routes.shared.createNewsletter}
                element={<CreateNewsletter />}
              >
                <Route index element={<CampaignDetails />} />
                <Route
                  path={routes.director.designEmail}
                  element={<DesignEmail />}
                />
                <Route
                  path={routes.director.campaignPreview}
                  element={<CampaignPreview />}
                />
              </Route>
            </Route>
          </Route>
        </Route>

        {/* SALES AGENT ROUTES */}
        <Route element={<ProtectedRoutes userRole="AGENT" />}>
          <Route element={<Layout userRole="AGENT" />}>
            <Route path={routes.agent.home} element={<SalesAgent />} />
            <Route path={routes.agent.products} element={<Products />}>
              <Route
                path={`${routes.shared.createProduct}`}
                element={<CreateProduct />}
              />
              <Route
                path={`${routes.shared.productInfo}/:id`}
                element={<ProductInfo />}
              />
              <Route
                path={`${routes.shared.updateProduct}/:id`}
                element={<UpdateProduct />}
              />
            </Route>
            <Route path={routes.agent.complaints} element={<Complaints />}>
              <Route
                path={routes.shared.createComplaint}
                element={<CreateComplaint />}
              />
              <Route
                path={`${routes.shared.updateComplaint}/:id`}
                element={<UpdateComplaint />}
              />
              <Route
                path={`${routes.shared.complaintDetails}/:id`}
                element={<ComplaintInfo />}
              />
            </Route>
            <Route path={routes.agent.newsletters} element={<Newsletters />}>
              <Route
                path={routes.shared.createNewsletter}
                element={<CreateNewsletter />}
              >
                <Route index element={<CampaignDetails />} />
                <Route
                  path={routes.agent.designEmail}
                  element={<DesignEmail />}
                />
                <Route
                  path={routes.agent.campaignPreview}
                  element={<CampaignPreview />}
                />
              </Route>
            </Route>
            <Route path={routes.agent.tasks} element={<Tasks />} />
            <Route path={routes.agent.settings} element={<Settings />} />
          </Route>
        </Route>
      </Routes>

      {/* React toastify configuration - Ignore */}
      <ToastContainer
        position="top-center"
        autoClose={2500}
        pauseOnFocusLoss={false}
        transition={Slide}
      />
      <RenderIf isTrue={process.env.NODE_ENV === 'development'}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </RenderIf>
    </>
  )
}

export default App
