import { UserRoleTypes } from '../interfaces/user'
import routes from '../constants/routes'

export default function navigateTo(userRole: UserRoleTypes['userRole']) {
  switch (userRole) {
    case 'DIRECTOR':
      return routes.director.home
    case 'AGENT':
      return routes.agent.home
    default:
      return routes.login
  }
}
