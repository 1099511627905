import UserMethods from '../../utils/userMethods'

function UserIcon() {
  const user = UserMethods.getUser()
  const userInitials = user && user!.user.first_name.charAt(0).toUpperCase()
      + user!.user.last_name.charAt(0).toUpperCase()

  return (
    <div className="flex justify-center items-center rounded-full bg-[#004059] w-10 h-10 text-white cursor-pointer">
      <p>{userInitials}</p>
    </div>
  )
}

export default UserIcon
