import { forwardRef } from 'react'
import { VscCalendar } from 'react-icons/vsc'

const CustomDatePicker = forwardRef(({ value, onClick }: any, ref: any) => (
  <button
    type="button"
    className="bg-white flex items-center gap-2 border-[1px] border-solid border-[#BEBEBE] rounded-lg text-sm text-textBlack px-3 py-3"
    onClick={onClick}
    ref={ref}
  >
    <VscCalendar className="text-base text-grey-pale" />
    {value}
  </button>
))

export default CustomDatePicker
