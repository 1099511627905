import { useTranslation } from 'react-i18next'

interface Props {
  text: string
}
function MyComponent({ text }:Props) {
  const { t } = useTranslation()
  return (
    <p className="text-center text-lg my-20 text-grey-pale font-semibold">{t(text)}</p>

  )
}

export default MyComponent
