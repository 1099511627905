import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import FormLayout from '../../../../../components/Form/FormLayout'
import FormInput from '../../../../../components/Form/FormInput'
import FormButtons from '../../../../../components/Form/FormButtons'
import validationSchema from '../../../../../utils/validationSchema'
import FormUploadMedia from '../../../../../components/Form/FormUploadMedia'

interface Props {
  onSubmit: (values: any) => void
  isLoading: boolean
  product?: any
}

function ProductCreationForm({
  onSubmit, isLoading, product
}: Props) {
  const { t } = useTranslation()
  const initialValues: any = {
    id: product ? product.id : '',
    product_name: product ? product.product_name : '',
    description: product ? product.description : '',
    product_photo: product?.product_photo ? [{
      name: product.product_photo.substring(product.product_photo.lastIndexOf('/') + 1, product.product_photo.length),
      source: product.product_photo
    }] : [],
    sold_items: product ? product.sold_items : 0,
    open_offers: product ? product.open_offers : 0
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema.productCreationForm,
    onSubmit
  })

  const onRemoveMediaHandler = () => {
    formik.setFieldValue('product_photo', [])
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormLayout>
        <div className="flex w-full flex-col xl:flex-row">
          <div className="flex flex-col w-full">
            <div className="w-full grid grid-cols-1 lg:grid-cols-2">
              <FormInput
                inputId="product_name"
                name="productName"
                labelText="Name"
                width="md:w-full"
                getFieldProps={formik.getFieldProps('product_name')}
                errors={!!(formik.touched.product_name && formik.errors.product_name)}
                errorName={formik.errors.product_name as string}
              />
              <FormInput
                inputId="description"
                name="description"
                labelText="Description"
                width="lg:w-full"
                getFieldProps={formik.getFieldProps('description')}
                errors={!!(formik.touched.description && formik.errors.description)}
                errorName={formik.errors.description as string}
              />
              <FormUploadMedia
                setMedia={formik.setFieldValue}
                multiple={false}
                fieldName="product_photo"
                media={formik.values.product_photo}
                onRemoveMediaHandler={onRemoveMediaHandler}
                marginLeft="ml-4"
                text="Product Logo"
                acceptedFiles="image/png, image/jpg"
              />
              <FormInput
                inputId="sold_items"
                name="soldItems"
                labelText="Sold Items"
                width="lg:w-full"
                getFieldProps={formik.getFieldProps('sold_items')}
                errors={!!(formik.touched.sold_items && formik.errors.sold_items)}
                errorName={formik.errors.sold_items as string}
              />
              <FormInput
                inputId="open_offers"
                name="openOffers"
                labelText="Open Offers"
                width="lg:w-full"
                getFieldProps={formik.getFieldProps('open_offers')}
                errors={!!(formik.touched.open_offers && formik.errors.open_offers)}
                errorName={formik.errors.open_offers as string}
              />
            </div>
          </div>
        </div>

      </FormLayout>
      <FormButtons isLoading={isLoading} btnText={product ? t('Edit Product') : t('Add Product')} />
    </form>
  )
}

export default ProductCreationForm
