import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import useMutateData from '../../../../../hooks/useMutateData'
import apiService from '../../../../../services/api/apiService'
import PageTitle from '../../../../../components/UI/PageTitle'
import PageSection from '../../../../../components/UI/PageSection'
import useGetData from '../../../../../hooks/useGetData'
import RenderIf from '../../../../../components/UI/RenderIf'
import ProductCreationForm from '../components/ProductCreationForm'

function UpdateProduct() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const { data: product, isLoading: isProductLoading } = useGetData<any>({
    queryKey: `product-${params.id}`,
    queryFn: () => apiService.getProduct(params.id),
    disabled: !params.id
  })

  const { isLoading, mutate: updateProduct } = useMutateData(
    {
      key: ['products', `product-${params.id}`],
      mutationFn: apiService.updateProduct,
      successMessage: 'productHasBeenUpdated'
    }
  )

  const submitFormHandler = (values: any) => {
    updateProduct(values, {
      onSuccess: () => navigate(-1)
    })
  }

  useEffect(() => {
    document.title = `KT CRM - ${t('Update Product')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text={t('Update Product')} />
      <RenderIf isTrue={!isProductLoading}>
        <ProductCreationForm
          onSubmit={submitFormHandler}
          product={product}
          isLoading={isLoading}
        />
      </RenderIf>
    </PageSection>
  )
}

export default UpdateProduct
