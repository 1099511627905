import { ReactNode } from 'react'
import RenderIf from './RenderIf'

interface Props {
  label: string
  text: string
  textOptional?: string
  children?: ReactNode
  ellipsis?: 'line-clamp-1 text-ellipsis' | 'line-clamp-none'
}
function Detail({
  label, text, textOptional, children, ellipsis = 'line-clamp-none'
}:Props) {
  return (
    <div className="flex flex-col gap-3">
      <p className="text-grey-pale text-sm md:text-lg line-clamp-2 text-ellipsis">{label}</p>
      <p title={text} className={`text-black text-sm md:text-lg flex items-center gap-1 ${ellipsis}`}>
        {children}
        {text}
      </p>
      <RenderIf isTrue={!!textOptional}>
        <p className="text-black text-sm md:text-lg flex items-center gap-1">
          {textOptional}
        </p>
      </RenderIf>
    </div>
  )
}

export default Detail
