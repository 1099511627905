import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import FormLayout from '../../../../../components/Form/FormLayout'
import { ComplaintTypes } from '../../../../../interfaces/complaints'
import Detail from '../../../../../components/UI/Detail'
import DetailsInfoBtns from '../../../../../components/UI/DetailsInfoBtns'
import RenderIf from '../../../../../components/UI/RenderIf'
import ComplaintMediaPreview from './ComplaintMediaPreview'
import routes from '../../../../../constants/routes'
import UserMethods from '../../../../../utils/userMethods'

interface Props {
  complaint?: ComplaintTypes
}

function ComplaintDetails({ complaint }: Props) {
  const navigate = useNavigate()
  const authUser = UserMethods.getUser()
  const { t } = useTranslation()

  const complaintPath = `/${authUser?.user.user_type.toLowerCase()}/complaints/${routes.shared.updateComplaint}/${complaint?.id}`
  const print = 'print:grid-cols-4'
  return (
    <div>
      <FormLayout>
        <div className={`grid grid-cols-2 md:grid-cols-4 gap-10 w-full ${print}`}>
          <RenderIf isTrue={authUser?.user.user_type === 'DIRECTOR'}>
            <Detail label={t('creator')} text={complaint?.creator ?? '-'} />
          </RenderIf>
          <Detail label={t('fullName')} text={complaint?.client_name ?? '-'} />
          <Detail label={t('orderNumber')} text={complaint?.order_number ?? '-'} />
          <Detail label={t('telephone')} text={t(complaint?.client_phone ?? '-')} />
          <Detail label="Email" text={t(complaint?.client_email ?? '-')} />
          <Detail label={t('dateCreated')} text={moment(complaint?.date_created).format('DD/MM/YYYY') ?? ''} />
          <Detail label={t('action')} text={t(complaint?.action ?? '')} />
          <Detail label={t('priority')} text={t(complaint?.priority ?? '')} />
        </div>
      </FormLayout>
      <div>
        <h3 className="text-grey-bold text-xl font-semibold mb-3">{t('complaintDescription')}</h3>
        <div className="bg-[#FBFBFB] rounded-[10px] p-9">
          <h4 className="text-lg font-semibold">{t(complaint?.description ?? '')}</h4>
          <p className="my-8 whitespace-pre-wrap max-h-[180px] overflow-y-auto">{complaint?.notes ?? ''}</p>
          <RenderIf isTrue={complaint?.complaint_files.length !== 0}>
            <div className="flex gap-5 flex-wrap">
              {complaint?.complaint_files
                .map((media) => <ComplaintMediaPreview key={media.id} media={media} />)}
            </div>
          </RenderIf>
        </div>
      </div>
      <DetailsInfoBtns btnText="editComplaint" onClickHandler={() => navigate(complaintPath)} />
    </div>
  )
}

export default ComplaintDetails
