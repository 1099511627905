import axios, { AxiosError, AxiosResponse } from 'axios'
import moment from 'moment'
import { OffersServiceInterface } from './offersService.interface'
import { OfferCreationTypes, OfferTypes } from '../../interfaces/offer'
import exception from '../../utils/exception'
import { ServerError } from '../../interfaces/api'
import axiosInstance from '../axiosInstance'

class OffersService implements OffersServiceInterface {
  public async getOffers(): Promise<OfferTypes[] | null> {
    try {
      const response = await axiosInstance.get('/offers/offer/')
      return response.data.data as OfferTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }

    return null
  }

  public async createOffer(values: OfferCreationTypes): Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.post('/offers/offer/', {
        offer_title: values.offerTitle,
        start_date: moment(values.startDate).format('YYYY-MM-DD'),
        end_date: moment(values.endDate).format('YYYY-MM-DD'),
        status: values.status,
        usage: +values.usage,
        discount_percentage: +values.discountPercentage
      })
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }

    return null
  }

  public async updateOffer({ values, id }:{ values:OfferCreationTypes, id: number }):
  Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.patch(`/offers/offer/${id}/`, {
        offer_title: values.offerTitle,
        start_date: moment(values.startDate).format('YYYY-MM-DD'),
        end_date: moment(values.endDate).format('YYYY-MM-DD'),
        status: values.status,
        usage: +values.usage,
        discount_percentage: +values.discountPercentage
      })
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }

    return null
  }

  public async deleteOffer({ id }: { id: number }): Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.delete(`/offers/offer/${id}/`)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }

    return null
  }
}

export default OffersService;
