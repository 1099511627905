import {
  useMemo, useContext, useState, useCallback
} from 'react'
import { ChildrenTypes } from '../../interfaces/children'
import useGetData from '../../hooks/useGetData'
import { MailingListGetterDataTypes, MailingListTypes, NewsletterTypes } from '../../interfaces/newsletters'
import apiService from '../../services/api/apiService'
import { useDataProvider } from '../DataProviderCtx/DataProvider'
import { ClientTypes } from '../../interfaces/client'
import mailingListCtx from './mailingListCtx'
import { MailingListCtxTypes } from './mailingListCtx.interface'
import { useNewsletters } from '../NewsletterCtx/NewslettersProvider'

export const useMailingList = ():MailingListCtxTypes => useContext(mailingListCtx)

function MailingListProvider({ children }:ChildrenTypes) {
  const { setSearchTextClient } = useDataProvider()
  const newslettersCtx = useNewsletters()

  const [mailingListName, setMailingListName] = useState<MailingListGetterDataTypes['mailingListName']>(null)
  const [selectedMailingListInput, setSelectedMailingListInput] = useState<MailingListGetterDataTypes['selectedMailingListInput']>(null)
  const [mailingListSelectedClients, setMailingListSelectedClients] = useState<MailingListGetterDataTypes['mailingListSelectedClients']>([])
  const [specificMailingListClients, setSpecificMailingListClients] = useState<MailingListGetterDataTypes['specificMailingListClients']>([])
  const [isEditMailingList, setIsEditMailingList] = useState<MailingListGetterDataTypes['isEditMailingList']>(false)

  const [showMailingListModal, setShowMailingListModal] = useState<MailingListCtxTypes['showMailingListModal']>(false)
  const [showSpecificClientsListModal, setShowSpecificClientsListModal] = useState<MailingListCtxTypes['showSpecificClientsListModal']>(false)
  const [showSelectedClientsModal, setShowSelectedClientsModal] = useState<MailingListCtxTypes['showSelectedClientsModal']>(false)
  const [showSelectedSpecificClientsModal, setShowSelectedSpecificClientsModal] = useState<MailingListCtxTypes['showSelectedSpecificClientsModal']>(false)

  const setter = useMemo(() => ({
    setMailingListName,
    setSelectedMailingListInput
  }), [])

  const getter: MailingListGetterDataTypes = useMemo(() => ({
    mailingListName,
    mailingListSelectedClients,
    specificMailingListClients,
    selectedMailingListInput,
    isEditMailingList
  }), [
    mailingListName,
    mailingListSelectedClients,
    specificMailingListClients,
    selectedMailingListInput,
    isEditMailingList
  ])

  const { data: mailingList } = useGetData<MailingListTypes[]>(
    {
      queryKey: 'mailingList',
      queryFn: apiService.getMailingList
    }
  )

  // on edit newsletter from table - prefill newsletter state with data
  const onEditNewsletterHandler = useCallback((selectedNewsletter: NewsletterTypes) => {
    const domParser = new DOMParser()

    // Set newsletter state for edit functionality
    newslettersCtx.setter.setIsEditNewsletter(true)
    newslettersCtx.setter.setSelectedNewsletterId(selectedNewsletter.id)
    newslettersCtx.setter.setCampaignName(selectedNewsletter.name)
    newslettersCtx.setter.setEmailSubject(selectedNewsletter.email_subject)

    // Get only the content div from email body
    newslettersCtx.setter.setContent(domParser.parseFromString(selectedNewsletter.email_body, 'text/html')
      .getElementById('content')?.innerHTML || '')

    // Get only the email greeting div from email body
    newslettersCtx.setter.setEmailGreeting(domParser.parseFromString(selectedNewsletter.email_body, 'text/html')
      .getElementById('emailGreeting')?.innerHTML || 'Με εκτίμηση, \nΗ ομάδα της Emoria')

    // Set the mailing list if it exists
    if (selectedNewsletter.mailing_lists.length > 0) {
      setSelectedMailingListInput(selectedNewsletter.mailing_lists[0].id.toString())
    }

    // Set specific clients if they exist
    if (selectedNewsletter.specific_clients.length > 0) {
      setSpecificMailingListClients(selectedNewsletter.specific_clients)
    }

    // Set products on offer if they exist
    if (selectedNewsletter.products_on_offer.length > 0) {
      newslettersCtx.setter.setSelectedNewsletterProducts(selectedNewsletter.products_on_offer)
    }

    // Set existing files if they exist
    if (selectedNewsletter.files.length > 0) {
      newslettersCtx.setter.setExistingFiles(selectedNewsletter.files)
    }

    // Set cover image if it exists
    const imageUrl = domParser.parseFromString(selectedNewsletter.email_body, 'text/html')
      .getElementById('coverImage')?.getAttribute('src')
    const imageId = domParser.parseFromString(selectedNewsletter.email_body, 'text/html')
      .getElementById('coverImage')?.getAttribute('data-id')

    if (imageUrl && imageId) {
      newslettersCtx.setter.setEmailImageUrl({
        id: +imageId,
        file: imageUrl,
        campaign: null
      })
    }
  }, [newslettersCtx.setter])

  // ---------------------------- Mailing List Modal ----------------------------

  const showMailingListModalHandler = useCallback((selectedList?:MailingListTypes) => {
    setShowMailingListModal(true)
    if (selectedList) {
      setMailingListName(selectedList.name)
      setMailingListSelectedClients(selectedList.clients)
      setIsEditMailingList(true)
    }
  }, [])

  const closeMailingListModalHandler = useCallback(() => {
    setShowMailingListModal(false)
    setSearchTextClient('')
    setMailingListName(null)
    setMailingListSelectedClients([])
    setIsEditMailingList(false)
  }, [setSearchTextClient])

  // ---------------------------- Specific Clients List Modal ----------------------------

  const showSpecificClientsListModalHandler = useCallback(() => {
    setShowSpecificClientsListModal(true)
  }, [])

  const closeSpecificClientsListModalHandler = useCallback(() => {
    setShowSpecificClientsListModal(false)
    setSearchTextClient('')
  }, [setSearchTextClient])

  // ---------------------------- Mailing List Selected Clients Modal ----------------------------
  const showSelectedClientsModalHandler = useCallback(() => {
    setShowSelectedClientsModal(true)
  }, [])

  const closeSelectedClientsModalHandler = useCallback(() => {
    setShowSelectedClientsModal(false)
  }, [])

  const onSelectClientHandler = useCallback((client:ClientTypes) => {
    setMailingListSelectedClients((prevClients) => {
      const existingClient = prevClients
        .some((prevClient) => prevClient.client_number === client.client_number)
      if (existingClient) {
        return prevClients
      }
      return [...prevClients, client]
    })
  }, [])

  const onRemoveClientHandler = useCallback((client:ClientTypes) => {
    setMailingListSelectedClients((prevClients) => prevClients
      .filter((prevClient) => prevClient.client_number !== client.client_number))
  }, [])

  const onClearMailingListSelectedClientsHandler = useCallback(() => {
    setMailingListSelectedClients([])
  }, [])

  // ---------------------------- Specific Clients ----------------------------

  const showSelectedSpecificClientsModalHandler = useCallback(() => {
    setShowSelectedSpecificClientsModal(true)
  }, [])

  const closeSelectedSpecificClientsModalHandler = useCallback(() => {
    setShowSelectedSpecificClientsModal(false)
  }, [])

  const onSpecificSelectClientHandler = useCallback((client:ClientTypes) => {
    setSpecificMailingListClients((prevClients) => {
      const existingClient = prevClients
        .some((prevClient) => prevClient.client_number === client.client_number)
      if (existingClient) {
        return prevClients
      }
      return [...prevClients, client]
    })
  }, [])

  const onRemoveSpecificClientHandler = useCallback((client:ClientTypes) => {
    setSpecificMailingListClients((prevClients) => prevClients
      .filter((prevClient) => prevClient.client_number !== client.client_number))
  }, [])

  const onClearSpecificClientsHandler = useCallback(() => {
    setSpecificMailingListClients([])
  }, [])

  const onResetMailingListHandler = useCallback(() => {
    setMailingListName(null)
    setMailingListSelectedClients([])
    setSpecificMailingListClients([])
    setIsEditMailingList(false)
    setSelectedMailingListInput(null)
  }, [])

  const ctx = useMemo(() => ({
    mailingList,
    showMailingListModal,
    showSelectedClientsModal,
    showSelectedSpecificClientsModal,
    showSpecificClientsListModal,
    showMailingListModalHandler,
    closeMailingListModalHandler,
    showSpecificClientsListModalHandler,
    showSelectedClientsModalHandler,
    closeSelectedClientsModalHandler,
    closeSpecificClientsListModalHandler,
    onClearMailingListSelectedClientsHandler,
    onClearSpecificClientsHandler,
    onSelectClientHandler,
    onSpecificSelectClientHandler,
    onRemoveClientHandler,
    onRemoveSpecificClientHandler,
    showSelectedSpecificClientsModalHandler,
    closeSelectedSpecificClientsModalHandler,
    onResetMailingListHandler,
    onEditNewsletterHandler,
    getter,
    setter
  }), [
    mailingList,
    showMailingListModal,
    showSelectedClientsModal,
    showSelectedSpecificClientsModal,
    showSpecificClientsListModal,
    showMailingListModalHandler,
    closeMailingListModalHandler,
    showSelectedClientsModalHandler,
    closeSelectedClientsModalHandler,
    onClearMailingListSelectedClientsHandler,
    onSelectClientHandler,
    onSpecificSelectClientHandler,
    onClearSpecificClientsHandler,
    onRemoveClientHandler,
    onRemoveSpecificClientHandler,
    showSpecificClientsListModalHandler,
    closeSpecificClientsListModalHandler,
    showSelectedSpecificClientsModalHandler,
    closeSelectedSpecificClientsModalHandler,
    onResetMailingListHandler,
    onEditNewsletterHandler,
    getter,
    setter
  ]);

  return (
    <mailingListCtx.Provider value={ctx}>
      {children}
    </mailingListCtx.Provider>
  )
}

export default MailingListProvider
