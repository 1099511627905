const translationEng = {
  // Navigation menu links
  home: 'Home',
  tickets: 'Tickets',
  clients: 'Clients',
  products: 'Products',
  analytics: 'Analytics',
  statistics: 'Statistics',
  agents: 'Agents',
  agent: 'Agent',
  orders: 'Orders',
  offers: 'Offers',
  calls: 'Calls',
  'Email Address': 'Email Address',
  Username: 'Username',
  Password: 'Password',
  'Remember me': 'Remember me',
  'Forgot password?': 'Forgot password?',
  Login: 'Login',
  Welcome: 'Welcome',
  Clientele: 'Clientele',
  'Add Client': 'Add Client',
  Products: 'Products',
  'Add Product': 'Add Product',
  'Edit Product': 'Edit Product',
  'New Product': 'New Product',
  Orders: 'Orders',
  'Add Order': 'Add Order',
  'Add Offer': 'Add Offer',
  settings: 'Settings',
  logout: 'Logout',
  'Update Product': 'Update Product',
  'New Employee': 'New Employee',
  'New Client': 'New Client',
  'New Offer': 'New Offer',
  'Update Offer': 'Update Offer',
  'Update Employee': 'Update Employee',
  'Update Client': 'Update Client',
  'Employee Name': 'Employee Name',
  'Employee Surname': 'Employee Surname',
  Email: 'Email',
  Phone: 'Phone',
  Role: 'Role',
  Agent: 'Agent',
  Manager: 'Manager',
  Managers: 'Managers',
  Director: 'Director',
  'Add Employee': 'Add Employee',
  Cancel: 'Cancel',
  'Username required.': 'Username required.',
  'Password required.': 'Password required.',
  'Employee name required.': 'Employee name required.',
  'Employee surname required.': 'Employee surname required.',
  'Invalid email.': 'Invalid email.',
  'Email required.': 'Email required.',
  'Invalid phone number.': 'Invalid phone number.',
  'Phone number required.': 'Phone number required.',
  'Role required.': 'Role required.',
  'Full name required.': 'Full name required.',
  'Address required.': 'Address required.',
  'County required.': 'County required.',
  'Area required.': 'Area required.',
  'Post code required.': 'Post code required.',
  'Company name required.': 'Company name required.',
  'Category required.': 'Category required.',
  'Offer name required.': 'Offer name required.',
  'Manager required.': 'Manager required.',
  'Start date required.': 'Start date required.',
  'End date required.': 'End date required.',
  'Discount percentage required.': 'Discount percentage required.',
  'Status required.': 'Status required.',
  'Usage required.': 'Usage required.',
  'Sales agent required.': 'Sales agent required.',
  'Vat number required.': 'Vat number required.',
  'Irs branch required.': 'Irs branch required.',
  'Last contact required.': 'Last contact required.',
  'Add Complaint': 'Add Complaint',
  'New Complaint': 'New Complaint',
  'Zip code required.': 'Zip code required.',
  'Tax exemption required.': 'Tax exemption required.',
  addAgent: 'Add Agent',
  agentName: 'Agent Name',
  agentSurname: 'Agent Surname',
  role: 'Role',
  telephone: 'Telephone',
  address: 'Address',
  postCode: 'Post Code',
  of: 'of',
  page: 'page',
  pages: 'pages',
  thePageYouAreOn: 'The page you are on',
  AGENT: 'Agent',
  MANAGER: 'Manager',
  DIRECTOR: 'Director',
  search: 'Search',
  yesIamSure: 'Yes, I am sure',
  noCancel: 'No, Cancel',
  deleteUserMessage: 'Are you sure you want to delete the user?',
  deleteOfferMessage: 'Are you sure you want to delete the offer?',
  deleteClientMessage: 'Are you sure you want to delete the client?',
  'Confirm password': 'Confirm password',
  passwordMustBe:
    'Password must be at least 8 characters and contain at least one uppercase letter and one number.',
  passwordsMustMatch: 'Passwords must match.',
  requiredConfirmation: 'Confirmation is required.',
  clientNumber: 'Client Number',
  fullName: 'Full Name',
  category: 'Category',
  company: 'Company',
  location: 'Location',
  lastContact: 'Last Contact',
  offerName: 'Offer Name',
  startDate: 'Start Date',
  endDate: 'End Date',
  state: 'State',
  usage: 'Usage',
  discountPercentage: 'Discount Percentage',
  chooseManager: 'Choose Manager',
  Status: 'Status',
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  chooseCategory: 'Choose Category',
  saleAgent: 'Sale Agent',
  saleAgents: 'Sale Agents',
  chooseSaleAgent: 'Choose Sale Agent',
  complaints: 'Complaints',
  clientName: 'Client Name',
  priority: 'Priority',
  answer: 'Answer',
  callAttempts: 'Call Attempts',
  orderNumber: 'Order Number',
  shortDescription: 'Short Description',
  notes: 'Notes',
  ticketStatus: 'Complaint Status',
  submissionMedium: 'Submission Medium',
  complaintNumber: 'Complaint Number',
  description: 'Description',
  date: 'Date',
  dateCreated: 'Date Created',
  client: 'Client',
  editClient: 'Edit Client',
  action: 'Action',
  complaintPriority: 'Complaint Priority',
  skuProduct: 'SKU',
  image: 'Image',
  name: 'Name',
  type: 'Type',
  price: 'Price/Unit',
  stock: 'Stock',
  orderNum: 'Order Number',
  orderDate: 'Order Date',
  payment: 'Payment',
  orderState: 'Order State',
  sendState: 'Shipping Status',
  fulfillment: 'Fulfillment',
  paid: 'Paid',
  notPaid: 'Not Paid',
  pending: 'Pending',
  sent: 'Sent',
  delivered: 'Delivered',
  onGoing: 'On Going',
  cancelled: 'Cancelled',
  completed: 'Completed',
  waiting: 'Waiting',
  partial: 'Partial',
  complete: 'Complete',
  pending2: 'Pending',
  clientAddress: 'Client Address',
  choosePaymentMethod: 'Choose Payment Method',
  paymentMethod: 'Payment Method',
  cheque: 'Cheque',
  cashOnDelivery: 'Cash on Delivery',
  bankPrepayment: 'Bank Prepayment',
  bankPayment: 'Bank Payment',
  'Submit Orders': 'Submit Orders',
  customerInfo: 'Customer Info',
  deliveryInfo: 'Delivery Info',
  'Client selection required.': 'Client selection required.',
  'Shipping address required.': 'Shipping address required.',
  'Payment method required.': 'Payment method required.',
  chooseClient: 'Choose Client',
  loginToContinue: 'Login to continue',
  login: 'Login',
  vatNumber: 'VAT Number',
  irsBranch: 'IRS Branch',
  county: 'County',
  area: 'Area',
  basicDetails: 'Basic Details',
  editAgent: 'Edit Agent',
  back: 'Back',
  zipCode: 'Zip Code',
  taxStatus: 'Tax Status',
  selectTaxStatus: 'Select Tax Status',
  NONE: 'None',
  FULL: 'Full',
  PARTIAL: 'Partial',
  sold: 'Sold',
  product: 'Product',
  quantity: 'Quantity',
  tax: 'VAT',
  noProductsFound: 'No products found',
  controlPanelAgent: 'Sales Agent Control Panel',
  callProgressToday: 'Call Progress Today',
  completedCourses: 'Completed Calls',
  unansweredCalls: 'Unanswered Calls',
  doNotDisturb: 'Do not disturb',
  numberOfOrdersToday: 'Number of Orders Today',
  subject: 'Subject',
  chooseDescription: 'Choose Description',
  WRONG_PRODUCT: 'Wrong product',
  DEFECTIVE_PRODUCT: 'Defective product',
  WRONG_PRICE: 'Wrong price',
  chooseStatus: 'Choose status',
  ACTIVE: 'Active',
  COMPLETED: 'Completed',
  choosePriority: 'Choose priority',
  LOW: 'Low',
  NORMAL: 'Normal',
  HIGH: 'High',
  chooseAction: 'Choose action',
  PRODUCT_REPLACEMENT: 'Product Replacement',
  OTHER: 'Other',
  ISSUE_OF_CREDIT: 'Issue of Credit',
  COMMUNICATION_FROM_THE_SELLER: 'Communication from the Seller',
  COMMUNICATION_FROM_THE_SALES_MANAGER: 'Communication from the Sales Manager',
  COMMUNICATION_FROM_THE_ACCOUNTING_DEPARTMENT:
    'Communication from the Accounting Department',
  COMMUNICATION_FROM_COMMERCIAL_MANAGER:
    'Communication from the Commercial Manager',
  COMMUNICATION_FROM_CEO: 'Communication from the CEO',
  attachments: 'Attachments',
  complaint: 'Complaint',
  'Notes required.': 'Notes required.',
  'Action required.': 'Action required.',
  'Client required.': 'Client required.',
  'Priority required.': 'Priority required.',
  'Description required.': 'Description required.',
  'Order number required.': 'Order number required.',
  'Documentary required.': 'Documentary required.',
  'ShipmentMethod required.': 'Shipment method required.',
  'lastName required.': 'Last name required.',
  'firstName required.': 'First name required.',
  'Department required.': 'Department required.',
  'Country required.': 'Country required.',
  'City required.': 'City required.',
  complaintDescription: 'Complaint Description',
  editComplaint: 'Edit Complaint',
  deleteComplaintMessage: 'Are you sure you want to delete the complaint?',
  productId: 'Product Id',
  productDescription: 'Description',
  noEntriesFound: 'No entries found',
  tasks: 'Tasks',
  directorControlPanel: 'Director Control Panel',
  newTask: '+ New Task',
  allTasks: 'All Tasks',
  CALL: 'Call',
  MEETING: 'Meeting',
  EMAIL: 'Email',
  addTask: 'Add Task',
  taskName: 'Task Name',
  taskKind: 'Task Kind',
  newTaskModal: 'New Task',
  title: 'Title',
  addNewTask: 'Add New Task',
  chooseTaskKind: 'Choose task kind',
  editTask: 'Edit Task',
  deleteTaskMessage: 'Are you sure you want to delete the task?',
  managerControlPanel: 'Manager Control Panel',
  noTasksFound: 'No tasks found',
  activeComplaints: 'Active Complaints',
  ordersToday: 'Orders Today',
  managers: 'Managers',
  numberOfOrders: 'Number of Orders',
  shipment: 'Shipment',
  order: 'Order',
  fullOrderDate: 'Full Order Date',
  department: 'Department',
  clientDetails: 'Client Details',
  shipmentDetails: 'Shipment Details',
  complaintOrderNumber: 'Complaint Order Number (Optional)',
  all: 'All',
  valueOfOrdersPerKind: 'Values of orders per kind',
  numberOfOrdersPerKind: 'Number of orders per kind',
  statisticDetails: 'Statistic Details',
  frequencyOfOrders: 'Frequency of Orders',
  orderKinds: 'Order Kinds',
  documentary: 'Documentary',
  chooseDocumentary: 'Choose Documentary',
  receipt: 'Receipt',
  invoice: 'Invoice - DA',
  chooseShipmentMethod: 'Choose Shipment Method',
  shipmentMethod: 'Shipment Method',
  pickUpFromSupplier: 'Pick up from Supplier',
  postOffice: 'Post Office',
  courier: 'Courier',
  transportationCompany: 'Transportation Company',
  documentaryNumber: 'Documentary Number',
  firstName: 'First Name',
  lastName: 'Last Name',
  saveChanges: 'Save Changes',
  filters: 'Filters',
  actions: 'Actions',
  numberOfOrderByProductType: 'Number of Orders by Product Type',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm New Password',
  chooseAgent: 'Choose Agent',
  lastContacts: 'Last Contacts',
  answered: 'Answered',
  notAnswered: 'Not Answered',
  newOrder: 'New Order',
  allOrders: 'All Orders',
  latestOrders: 'Latest Orders',
  callsHistory: 'Calls History',
  totalOrdersAmount: 'Total Orders Amount',
  totalOrders: 'Total Orders',
  city: 'City',
  comments: 'Comments',
  startCall: 'Start Call',
  callOutcome: 'Call Outcome',
  save: 'Save',
  country: 'Country',
  chooseCountry: 'Choose Country',
  tradeAgreement: 'Trade Agreement',
  deleteTradeAgreementText:
    'Are you sure you want to delete the trade agreement?',
  noTradeAgreementHasBeenSubmitted: 'No trade agreement has been submitted.',
  thisCalendarMonth: 'This Month',
  thisCalendarYear: 'This Year',
  lastCalendarMonth: 'Last Month',
  lastCalendarYear: 'Last Year',
  selectDateRange: 'Select Date Range',
  show: 'Show',
  clear: 'Clear',
  clientNameOrVat: 'Client Name or VAT',
  totalOrdersSum: 'Total Orders Sum',
  clearFilters: 'Clear Filters',
  selectAll: 'Select All',
  removeAll: 'Remove All',
  newsletters: 'Newsletters',
  newsletterCampaigns: 'Newsletter Campaigns',
  newNewsletter: 'New Newsletter',
  campaignName: 'Campaign Name',
  emailSubject: 'Email Subject',
  newCampaign: 'New Campaign',
  createNewsletter: 'Create Newsletter',
  campaignDetails: 'Campaign Details',
  emailDesign: 'Email Design',
  preview: 'Preview',
  campaignPreview: 'Campaign Preview',
  nextStep: 'Next Step',
  send: 'Send',
  chooseList: 'Choose List',
  chooseEmailList: 'Choose Email List',
  chooseSomeReceivers: 'Choose some receivers',
  createNewList: 'Create new list',
  or: 'or',
  emailSample: 'Email Sample',
  designYourEmail: 'Design your email',
  emailPreview: 'Email Preview',
  insertProducts: 'Insert Products',
  attachFiles: 'Attach Files',
  passwordChangeWarning:
    'Please enter a new password only if you wish to change your password',
  'Campaign name required.': 'Campaign name is required.',
  'Email subject required.': 'Email subject is required.',
  'Mailing list required.': 'Mailing list is required.',
  'Mailing list name required.': 'Mailing list name is required.',
  emailList: 'Email List',
  emailGreeting: 'Email Greeting',
  productName: 'Product Name',
  resetPassword: 'Reset Password',
  pleaseTypeEmailtoResetPassword:
    'Please type your email to reset your password',
  clientCallInfo: 'Client Call Information',
  orderInfo: 'Order Information',
  emailListName: 'Email List Name',
  insertList: 'Insert List',
  supportedMailingListFiles: '(Supported file type: .xlsx)',
  number: 'Number',
  selectedClients: 'Selected Clients',
  close: 'Close',
  noClientsSelected: 'You have not selected any clients.',
  fileAttachments: 'File Attachments',
  DRAFT: 'Draft',
  SENT: 'Sent',
  uploadingFile: 'Uploading file...',
  deleteNewsletterMessage: 'Are you sure you want to delete the newsletter?',
  deleteMailingListMessage: 'Are you sure you want to delete the mailing list?',
  Postponed: 'Postponed',
  Completed: 'Completed',
  'No answer': 'No answer',
  Busy: 'Busy',
  'Wrong number': 'Wrong number',
  'New order': 'New order',
  'Do not disturb': 'Do not disturb',
  newDate: 'New Date',
  backToControlPanel: 'Back to Control Panel',
  creator: 'Creator',
  noLatestOrdersFound: 'No latest orders found.',
  totalOrderValues: 'Total Order Values',
  worthOfOrders: 'Worth of Orders',
  callStatistics: 'Call Statistics',
  fromF: 'from',
  callsLeadToOrders: 'calls lead to orders',
  callsWerePostponed: 'calls were postponed',
  noCallsForToday: 'There are no more calls for today.',
  noHistoryCallFound: 'No history calls found.',
  noLatestCallsFound: 'No latest calls found.',
  editList: 'Edit List',
  averageOrdersPerMonth: 'Average Orders per Month',
  averageOrdersPerYear: 'Average Orders per Year',
  value: 'Value',
  today: 'Today',
  last30Days: 'Last 30 days',
  last365Days: 'Last 365 days',
  Jan: 'Jan',
  Feb: 'Feb',
  Mar: 'Mar',
  Apr: 'Apr',
  May: 'May',
  Jun: 'Jun',
  Jul: 'Jul',
  Aug: 'Aug',
  Sep: 'Sep',
  Oct: 'Oct',
  Nov: 'Nov',
  Dec: 'Dec',
  total: 'Total',
  completedOrders: 'Completed Orders',
  completedCalls: 'Completed Calls',
  newOrders: 'New Orders',
  busyCalls: 'Busy Calls',
  noAnswerCalls: 'No Answer Calls',
  postponedCalls: 'Postponed Calls',
  year: 'Year',
  month: 'Month',
  amount: 'Amount',
  fullClientName: 'Full Client Name',
  phoneNumber: 'Phone Number',
  callDuration: 'Call Duration',
  amountOrDurationOfCallsToday: 'Amount/Duration of Calls Today',
  valueAndNumberOfOrders: 'Value and Number of Orders',
  callAttempt: 'Call Attempt',
  'New number': 'New number',
  results: 'Results',
  transportFromEmoria: 'Transport from Emoria',
  addOptionalIcon: 'Add optional icon',
  pressHereToAddIcon: 'Press here to add an image to the newsletter.',
  acceptedImage:
    'Accepted file types: jpg, jpeg, png. Max file size: 5MB. Dimensions: 1200 X 400 px (or larger).',
  myClients: 'My clients',
  advancedSettings: 'Advanced Settings',
  advancedSettingsNote:
    'Please use the following options only in exceptional cases. The system may not be functional until the tasks are completed.',
  estimatedTime: 'Estimated time',
  sendEmailForGeocode: 'Send Email for Geocode',
  sendEmailForLastDayOrders: 'Send Email for Last Day Orders',
  sendEmailForOutOfStockProducts: 'Send Email for Out of Stock Products',
  clientSync: 'Client & Product Sync',
  orderSync: 'Order Sync',
  sync: 'Sync',
  instant: 'Instant',
  about3minutes: 'about 3 minutes',
  about30minutes: 'about 30 minutes',
  duration: 'Duration',
  syncWithErp: 'Sync with ERP',
  sendEmail: 'Send Email',
  cancel: 'Cancel',
  systemAlert: 'System Alert!',
  systemAlertNote1:
    'Are you sure you want to proceed with the selected sync? Estimated duration is ',
  systemAlertNote2:
    ' and the CRM application will be unavailable until the synchronization is completed.',
  syncInProgressPleaseDontClosePage:
    'Sync is in progress. Please do not close the page.',
  taskInProgressPleaseWait:
    'Task is in progress. Please wait for it to complete.',
  productGroup: 'Product Group',
  soldItems: 'Sold Items',
  openOffers: 'Open Offers',
  productValue: 'Value',
  'clientCode required.': 'Client Code required.',
  clientCode: 'Client Code',
  invoiceCode: 'Invoice Number',
  invoiceDate: 'Invoice Date',
  deliveryDate: 'Delivery Date',
  paymentStatus: 'Payment Status',
  activateAccount: 'Activate Account',
  enterPasswordToActivateAccount: 'Enter a password to activate your account.',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  yourAccountIsActivated: 'Your account is activated.',
  enterNewsPassword: 'Enter new password.',
  endCall: 'End Call',
  viewCall: 'View Call',
  clientsAgentMap: 'Clients Agent Map',
  choosePeriod: 'Choose Period',
  captchaRequired: 'Captcha Required',
  map: 'Map',
  noCategory: 'No Category',
  productTypes: 'Product Types',
  delete: 'Delete',
  resend: 'Resend',
  edit: 'Edit',
  noAttachmentsFound: 'No attachments found',
  files: 'Files',
  content: 'Content',
  sendDates: 'Send Dates',
  creationDate: 'Creation Date',
  totalWorthOfOrders: 'Total Worth of Orders',
  toughClient: 'Difficult Client',
  onlyToughClients: 'Only Difficult Clients',
  yes: 'Yes',
  no: 'No',
  call: 'Call',
  submit: 'Submit',
  commentForClient: 'Leave a comment for the client (maximum 500 characters)',
  'Max 500 characters.': 'Max 500 characters.',
  'Comment required.': 'Comment required.',
  'No comments found.': 'No comments found.',

  // TOAST MESSAGES
  productHasBeenCreated: 'Product has been created successfully',
  productHasBeenUpdated: 'Product has been updated successfully',
  userHasBeenDeleted: 'The user has been successfully deleted.',
  userHasBeenCreated: 'User successfully created.',
  userHasBeenUpdated: 'User successfully updated.',
  yourDetailsHaveBeenUpdated: 'Your details have been successfully updated',
  offerHasBeenCreated: 'The offer was successfully created.',
  offerHasBeenUpdated: 'The offer was successfully updated.',
  offerHasBeenDeleted: 'The offer was successfully deleted.',
  clientHasBeenCreated: 'Client successfully created.',
  clientHasBeenUpdated: 'Client successfully updated.',
  clientHasBeenDeleted: 'Client successfully deleted.',
  complaintHasBeenCreated: 'Complaint successfully registered.',
  complaintHasBeenUpdated: 'The complaint was successfully updated.',
  complaintHasBeenDeleted: 'The complaint was successfully deleted.',
  taskHasBeenCreated: 'The task was successfully created.',
  taskHasBeenUpdated: 'The task was successfully updated.',
  taskHasBeenDeleted: 'The task was successfully deleted.',
  noChangesMade: 'No changes were made.',
  orderHasBeenCreated: 'The order was successfully created.',
  selectProductsInTheOrder: 'Select products for the order.',
  someOfTheSelectedProductsAreOutOfStock:
    'SomeOfTheSelectedProductsAreOutOfStock:',
  tradeAgreementFileHasBeenRemoved:
    'The trade agreement file has been successfully deleted.',
  newsletterHasBeenSend: 'The newsletter was successfully sent.',
  newsletterHasBeenSaved: 'The newsletter was successfully saved.',
  newsletterHasBeenCreated: 'The newsletter was successfully created.',
  newsletterHasBeenUpdated: 'The newsletter was successfully updated.',
  newsletterHasBeenDeleted: 'The newsletter was successfully deleted.',
  mailingListHasBeenCreated: 'The email list was successfully created.',
  mailingListHasBeenUploaded: 'The email list was successfully uploaded.',
  mailingListHasBeenUpdated: 'The email list has been successfully updated.',
  mailingHasBeenDeleted: 'The email list has been successfully deleted.',
  selectListToDelete: 'Select list to delete',
  selectListToEdit: 'Select list to edit',
  emailHasBeenSent: 'The email was sent successfully.',
  callUpdated: 'The call was successfully updated.',
  callNewDate: 'The call has been set for a new date.',
  selectCallOutcome: 'Select the outcome of the call.',
  brokenGeocodeEmailsHasBeenSent:
    'Emails with broken addresses have been sent successfully.',
  lastDayOrdersEmailHasBeenSent:
    "The email with the previous day's orders was sent successfully.",
  outOfStockEmailProductsHasBeenSent:
    'The email with the out of stock products was sent successfully',
  emailImageSize5MB: 'The image size should not exceed 5MB.',
  savePreviousCall:
    'Please end and save the previous call before starting a new one.',
  emailWillBeSent:
    'If you maintain an active account in our system, you will soon receive a new email retrieving your password.',
  sessionExpired: 'Your connection has expired. Please log in again.',
  taskCompleted: 'The task has been successfully completed!',
  syncActionFailed: 'Sync did not complete successfully.',
  accountActivated: 'Your account was successfully activated.',
  passwordHasBeenReset: 'Your password has been successfully reset.',
  commentHasBeenCreated: 'The comment was successfully created.',
  commentHasBeenDeleted: 'The comment was successfully deleted.',
  'detail: ActivatePasswordRequest with this code does not exist':
    'The activation code does not exist.',
  '0: If you maintain an active account in our system, you will soon receive a new password recovery email.':
    'If you maintain an active account in our system, you will soon receive a new password recovery email.',
  '0: No account with this email registered!':
    'No account registered with this email!',
  'detail: No active account found with the given credentials':
    'No active account found with these credentials!',
  "0: {'username': ['User with this Username already exists.'], 'email': ['User with this Email already exists.']}":
    'An account with this username or email already exists!',
  "0: {'email': ['User with this Email already exists.']}":
    'An account with this email already exists!',
  "0: {'username': ['User with this Username already exists.']}":
    'An account with this username already exists!',
  'name: mailing list with this name already exists.':
    'Email list with this name already exists.',
  'name: campaign with this name already exists.':
    'campaign with this name already exists.',
  'error_messages: planned_date is required': 'The planning date is required.',
  'detail: Not found.': 'Not found.'
}

export default translationEng
