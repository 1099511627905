import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Table from '../../../../../components/Table/Table'
import { ProductTypes } from '../../../../../interfaces/products'
import StockIcon from '../../../../../components/UI/StockIcon'

interface Props {
  products : ProductTypes[]
}

function ProductsTable({ products = [] }:Props) {
  const { t } = useTranslation()

  useEffect(() => {
    // localStorage.setItem('productsTable', '[{"product_photo_link":"product_photo_link"}]')
    // console.log(localStorage.getItem('productsTable'))
  }, [])

  const columns = useMemo(() => [
    {
      Header: t('name'),
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: any) => (
        <p title={row.original.product_name} className="overflow-y-hidden text-ellipsis flex items-center gap-8">
          <img src={row.original.product_photo} alt="product-logo" width="44" height="37" className="w-[44px] h-[37px] object-contain" />
          {' '}
          {row.original.product_name}
        </p>
      )
    },
    {
      Header: t('description'),
      accessor: 'description',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p title={value}>{value || '-'}</p>
    },
    {
      Header: t('soldItems'),
      accessor: 'sold_items',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('openOffers'),
      accessor: 'open_offers',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => (
        <p>
          {value}
          €
        </p>
      )
    }
  ], [t]);

  return (
    <Table
      columns={columns}
      data={products}
      storageKey="productsTable"
      rowsPerPage={10}
    />
  )
}

export default ProductsTable
