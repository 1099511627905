import { useTranslation } from 'react-i18next'
import RenderIf from '../../../components/UI/RenderIf'
import ClientsNumberSkeleton from '../../../components/Skeletons/ClientsNumberSkeleton'
import { AgentAnalyticsTypes } from '../../../interfaces/statistics'

interface Props {
  agentAnalytics: AgentAnalyticsTypes
  isLoading: boolean
}

function ClientsNumberContainer({ agentAnalytics, isLoading }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <RenderIf isTrue={isLoading}>
        <ClientsNumberSkeleton />
      </RenderIf>
      <RenderIf isTrue={!isLoading}>
        <div className="text-primary-text-dark">
          <p className="text-[22px]">{t('myClients')}</p>
          <p className="text-[25px]  font-semibold sm:text-right">{agentAnalytics?.clients ?? 0}</p>
        </div>
      </RenderIf>
    </>
  )
}

export default ClientsNumberContainer
