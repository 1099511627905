import { FaImages } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import RenderIf from '../../../../../../components/UI/RenderIf'
import { NewslettersCtxTypes } from '../../../../../../context/NewsletterCtx/newslettersCtx.interface'
import apiService from '../../../../../../services/api/apiService'
import Spinner from '../../../../../../components/UI/Spinner'

interface Props {
  isLoading: boolean
  isEmailDesign: boolean
  newslettersCtx: NewslettersCtxTypes
}

function EmailPreviewImage({ isLoading, isEmailDesign, newslettersCtx }:Props) {
  const { t } = useTranslation()

  return (
    <>
      {/* This is not part of the email template body */}
      <RenderIf isTrue={(
        isEmailDesign
        && !newslettersCtx.emailImage
        && !newslettersCtx.emailImageUrl)
        || isLoading}
      >
        <div className="rounded-t-[16px] bg-grey-statistics h-[250px] w-full max-w-[600px] hover:opacity-90 transition-opacity duration-200">
          <label htmlFor="emailImage" className="cursor-pointer w-full h-full  flex items-center justify-center gap-3 p-1 flex flex-col text-center">
            {isLoading ? <Spinner /> : (
              <>
                <span className="font-bold">{t('addOptionalIcon')}</span>
                <FaImages size={50} color="#bdbdbd" />
                <span className="text-sm">{t('pressHereToAddIcon')}</span>

                <small className="text-grey-pale max-w-[360px]">
                  {t('acceptedImage')}
                </small>
              </>
            )}
            <input
              id="emailImage"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              className="hidden"
              onChange={(e) => newslettersCtx.setter.setEmailImage(e.target.files?.[0])}
            />
          </label>
        </div>
      </RenderIf>
      {/* This is part of the email template body */}
      <RenderIf isTrue={!!newslettersCtx.emailImageUrl}>
        <div
          style={{
            width: '100%',
            height: '250px',
            borderRadius: '16px 16px 0 0',
            position: 'relative'
          }}
        >
          <img
            alt=""
            src={newslettersCtx.emailImageUrl?.file?.includes('https')
              ? newslettersCtx.emailImageUrl?.file
              : newslettersCtx.emailImageUrl?.file?.replace('http', 'https') ?? ''}
            id="coverImage"
            data-id={newslettersCtx.emailImageUrl?.id}
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
              borderRadius: '16px 16px 0 0'
            }}
          />
          {/* This is not part of the email template body */}
          <RenderIf isTrue={isEmailDesign}>
            <MdDelete
              onClick={
              () => apiService.deleteFile({ id: newslettersCtx.emailImageUrl!.id })
                .then(() => {
                  newslettersCtx.setter.setEmailImageUrl(null)
                  newslettersCtx.setter.setEmailImage(undefined)
                })
                .catch((error) => {
                  newslettersCtx.setter.setEmailImageUrl(null)
                  toast.error<string>(t(error.message))
                })
            }
              size={30}
              className="absolute top-2 right-2 cursor-pointer z-20 text-error"
            />
          </RenderIf>
        </div>
      </RenderIf>
    </>
  )
}

export default EmailPreviewImage
