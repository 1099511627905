import { useTranslation } from 'react-i18next'
import { BsSearch } from 'react-icons/bs'
import { ChangeEvent } from 'react'
import { DebounceInput } from 'react-debounce-input'
import Spinner from '../UI/Spinner'

interface Props {
  searchText: string
  onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void
  isLoading: boolean
}

function SearchFromApi({ searchText, onChangeHandler, isLoading }: Props) {
  const { t } = useTranslation()

  return (
    <div className="bg-white min-w-[200px] sm:max-w-[50%] w-full flex items-center justify-between border border-solid border-[#BEBEBE] rounded-md px-4 gap-1">
      <DebounceInput
        autoComplete="off"
        type="text"
        minLength={3}
        placeholder={t('search')}
        debounceTimeout={1500}
        value={searchText}
        onChange={onChangeHandler}
        className="py-[5px] outline-none w-full"
      />
      {
        isLoading
          ? (
            <div className="ml-left">
              <Spinner width="w-5" height="h-5" />
            </div>
          )

          : <BsSearch />
      }
    </div>
  )
}

export default SearchFromApi
