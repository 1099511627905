import { UserRoleTypes } from '../../interfaces/user'
import RenderIf from '../UI/RenderIf'
import SalesAgentNav from './UserRoleNav/SalesAgentNav'
import DirectorNav from './UserRoleNav/DirectorNav'

interface Props {
  userRole : UserRoleTypes['userRole']
  isOpen: boolean
}

function HamburgerNav({ userRole, isOpen }:Props) {
  const isOpenClass = isOpen ? 'translate-x-0' : 'translate-x-full'
  return (
    <div
      className={`${isOpenClass} fixed z-[300] right-0 left-0 bottom-0 top-[96px] bg-[#223367] 3xl:hidden transition-transform flex flex-col items-center justify-center`}
    >
      <nav className="flex flex-col gap-12 items-center overflow-y-auto w-full">
        <RenderIf isTrue={userRole === 'AGENT'}>
          <SalesAgentNav />
        </RenderIf>
        <RenderIf isTrue={userRole === 'DIRECTOR'}>
          <DirectorNav />
        </RenderIf>
      </nav>
    </div>
  )
}

export default HamburgerNav
