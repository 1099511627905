import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IoMdClose } from 'react-icons/io'

import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useModalContext } from '../../../../../context/ModalsCtx/ModalsProvider'
import FormButtons from '../../../../../components/Form/FormButtons'
import { TaskCreationTypes } from '../../../../../interfaces/tasks'
import UserMethods from '../../../../../utils/userMethods'
import useMutateData from '../../../../../hooks/useMutateData'
import apiService from '../../../../../services/api/apiService'
import validationSchema from '../../../../../utils/validationSchema'

function TaskModal() {
  const authUser = UserMethods.getUser()
  const { selectedTask } = useModalContext()
  const { t } = useTranslation()
  const { showTaskModal, closeTaskModalHandler } = useModalContext()

  const { mutate, isLoading } = useMutateData({
    key: 'tasks',
    mutationFn: selectedTask ? apiService.editTask : apiService.createTask,
    successMessage: selectedTask ? 'taskHasBeenUpdated' : 'taskHasBeenCreated'
  })

  const initialValues: TaskCreationTypes = {
    id: '',
    user: authUser?.user.id ?? '',
    title: '',
    description: '',
    type: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema.taskCreationForm,
    onSubmit: (values: TaskCreationTypes) => {
      mutate(values, {
        onSuccess: () => {
          closeTaskModalHandler()
          formik.resetForm()
        }
      })
    }
  })

  const { setValues } = formik
  useEffect(() => {
    if (selectedTask) {
      setValues({
        id: selectedTask.id ?? '',
        user: authUser?.user.id ?? '',
        title: selectedTask?.title ?? '',
        description: selectedTask?.description ?? '',
        type: selectedTask?.type ?? ''
      })
    } else {
      setValues({
        id: '',
        user: authUser?.user.id ?? '',
        title: '',
        description: '',
        type: ''
      })
    }
  }, [selectedTask, authUser?.user.id, setValues])

  const backdropClasses = showTaskModal ? 'opacity-100 visible' : 'opacity-0 invisible'
  const modalClasses = showTaskModal ? 'opacity-100 scale-100' : 'opacity-0 scale-0'

  const overlays = document.getElementById('overlays') as HTMLElement

  const inputClasses = 'border border-solid rounded-md px-3 py-2 outline-none'
  const wrapperClasses = 'flex flex-col gap-[10px]'
  return ReactDOM.createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */}
      <div className={`fixed inset-0 z-40 bg-[rgba(0,0,0,0.20)] transition-opacity duration-300 ${backdropClasses}`} onClick={closeTaskModalHandler} />
      <div className={`bg-white absolute xs:fixed top-8 md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 z-50  max-w-[90%] md:max-w-[648px] w-full origin-center transition-all duration-300 ${modalClasses} rounded-2xl`}>
        <div className="flex items-center justify-between bg-btn-sub text-white rounded-t-2xl px-[35px] py-[25px]">
          <p className="font-semibold text-4xl">{t(selectedTask ? 'editTask' : 'newTaskModal')}</p>
          <IoMdClose size={33} onClick={closeTaskModalHandler} className="cursor-pointer" />
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 px-[35px] py-[25px] text-lg text-grey-pale">
          <div className={`${wrapperClasses}`}>
            <label htmlFor="title">{t('taskName')}</label>
            <input id="title" {...formik.getFieldProps('title')} className={`${inputClasses}  ${formik.errors.title && formik.touched.title ? 'border-error' : 'border-[#BEBEBE]'}`} />
          </div>
          <div className={`${wrapperClasses}`}>
            <label htmlFor="description">{t('description')}</label>
            <textarea rows={5} id="description" {...formik.getFieldProps('description')} className={`${inputClasses} ${formik.errors.description && formik.touched.description ? 'border-error' : 'border-[#BEBEBE]'}`} />
          </div>
          <div className={`${wrapperClasses}`}>
            <label htmlFor="type" className="flex flex-col gap-[10px]">{t('taskKind')}</label>
            <select id="type" {...formik.getFieldProps('type')} className={`${inputClasses} w-1/2  ${formik.errors.type && formik.touched.type ? 'border-error' : 'border-[#BEBEBE]'}`}>
              <option value="">{t('chooseTaskKind')}</option>
              <option value="CALL">{t('CALL')}</option>
              <option value="MEETING">{t('MEETING')}</option>
              <option value="EMAIL">{t('EMAIL')}</option>
            </select>
          </div>
          <div className="w-full h-[1px] bg-[#BEBEBE] mt-6" />
          <FormButtons isLoading={isLoading} btnText={selectedTask ? 'save' : 'addNewTask'} onCancelHandler={closeTaskModalHandler} />
        </form>
      </div>
    </>,
    overlays
  )
}

export default TaskModal
