import { ChildrenTypes } from '../../interfaces/children'

interface Props extends ChildrenTypes {
  isOpen: boolean
  align?: 'right-0 origin-top-right' | 'left-0 origin-top-left'
  triangleAlignment?: 'right-[12px]' | 'left-[12px]'
}

function Dropdown({
  children, isOpen, align = 'right-0 origin-top-right', triangleAlignment = 'right-[12px]'
}:Props) {
  const isOpenClasses = isOpen ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
  return (
    <div className={`${isOpenClasses} ${align} absolute bg-white rounded-md top-[69px] z-[500] transition-all duration-300  shadow-faint`}>
      <div className={`${triangleAlignment} triangle bg-white absolute z-[500] w-4 h-4 -top-3 shadow-x`} />
      {children}
    </div>

  )
}

export default Dropdown
