import NavLink from '../NavLink'
import routes from '../../../constants/routes'

function DirectorNav() {
  return (
    <>
      <NavLink text="home" path={routes.director.home} />
      <NavLink text="agents" path={routes.director.agents} />
      <NavLink text="statistics" path={routes.director.statistics} />
      <NavLink text="products" path={routes.director.products} />
      <NavLink text="complaints" path={routes.director.complaints} />
      <NavLink text="newsletters" path={routes.director.newsletters} />
    </>
  )
}

export default DirectorNav
