import axios, { AxiosError, AxiosResponse } from 'axios'
import { ComplaintsServiceInterface } from './complaintsService.interface'
import axiosInstance from '../axiosInstance'
import exception from '../../utils/exception'
import { ServerError } from '../../interfaces/api'
import { ComplaintCreationTypes, ComplaintTypes } from '../../interfaces/complaints'

class ComplaintsService implements ComplaintsServiceInterface {
  public async getComplaints(): Promise<ComplaintTypes[] | null> {
    try {
      const response = await axiosInstance.get('/complaints/complaint')

      return response.data.data as ComplaintTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async createComplaint(values: ComplaintCreationTypes): Promise<AxiosResponse | null> {
    try {
      const formData = new FormData()
      formData.append('notes', values.notes)
      formData.append('action', values.action)
      formData.append('client', values.client.toString())
      formData.append('priority', values.priority)
      formData.append('description', values.description)
      formData.append('status', values.status)
      formData.append('order_number', values.orderNumber)
      formData.append('subject', '')

      if (values.files.length !== 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const file of values.files) formData.append('attached_files', file)
      }

      const response = await axiosInstance.post('/complaints/complaint/', formData)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateComplaint(values: ComplaintCreationTypes): Promise<AxiosResponse | null> {
    const formData = new FormData()
    formData.append('notes', values.notes)
    formData.append('action', values.action)
    formData.append('description', values.description)
    formData.append('status', values.status)
    formData.append('priority', values.priority)
    formData.append('order_number', values.orderNumber)
    formData.append('subject', '')

    if (values.files.length !== 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of values.files) formData.append('attached_files', file)
    }

    try {
      const response = await axiosInstance.patch(`/complaints/complaint/${values.id}/`, formData)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async deleteComplaint({ id }: { id:number }): Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.delete(`/complaints/complaint/${id}/`)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }

    return null
  }
}

export default ComplaintsService;
