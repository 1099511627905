import { createContext } from 'react'
import { DataProviderCtxTypes } from './dataProviderCtx.interface'

const dataProviderCtx = createContext<DataProviderCtxTypes>({
  clients: [],
  showToughClients: false,
  clientPaginate: {
    fetchNextPage: async () => {},
    hasNextPage: false,
    isFetching: false,
    isFetchingNextPage: false
  },
  agents: [],
  products: [],
  tasks: [],
  searchTextClient: '',
  isClientsLoading: false,
  isProductsLoading: false,
  isTasksLoading: false,
  setIsEnabled: () => {},
  setSearchTextClient: () => {},
  setShowToughClients: () => {},
  onSearchClientHandler: () => {}
})

export default dataProviderCtx
