import { TokenMethodsTypes, TokenServiceTypes } from '../interfaces/token'

const tokenMethods: TokenMethodsTypes = {
  saveToken: ({ token }: TokenServiceTypes) => {
    localStorage.setItem('accessToken', token.access)
    localStorage.setItem('refreshToken', token.refresh)
  },

  refreshAccessToken: ({ token }: TokenServiceTypes) => {
    localStorage.setItem('accessToken', token.access)
  },

  removeToken: () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  },

  getAccessToken: () => localStorage.getItem('accessToken'),

  getRefreshToken: () => localStorage.getItem('refreshToken')
}

export default tokenMethods
