import ReactDOM from 'react-dom'
import { useEffect } from 'react'
import { RiErrorWarningLine } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { MutationFunction } from 'react-query'
import { AxiosResponse } from 'axios'
import { useModalContext } from '../../context/ModalsCtx/ModalsProvider'
import useMutateData from '../../hooks/useMutateData'

interface Props {
  cacheKey: string | string[]
  toastSuccessMessage: string
  mutationFn:MutationFunction<AxiosResponse | null, any >,
}

function ConfirmationModal(
  {
    cacheKey,
    toastSuccessMessage,
    mutationFn
  }:Props
) {
  const { t } = useTranslation()
  const {
    modalText, resourceId, closeConfirmationModalHandler, showConfirmationModal
  } = useModalContext()
  const { mutate } = useMutateData({
    key: cacheKey,
    mutationFn,
    successMessage: toastSuccessMessage
  })

  const mutateParams = toastSuccessMessage === 'userHasBeenDeleted' ? { id: resourceId?.id!, userRole: resourceId?.userRole! } : { id: resourceId?.id }

  const backdropClasses = showConfirmationModal ? 'opacity-100 visible' : 'opacity-0 invisible'
  const modalClasses = showConfirmationModal ? 'opacity-100 scale-100' : 'opacity-0 scale-0'

  const overlays = document.getElementById('overlays') as HTMLElement

  useEffect(() => () => {
    closeConfirmationModalHandler()
  }, [closeConfirmationModalHandler])

  return ReactDOM.createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */}
      <div className={`fixed inset-0 z-40 bg-[rgba(0,0,0,0.20)] transition-opacity duration-300 ${backdropClasses}`} onClick={closeConfirmationModalHandler} />
      <div
        className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 max-w-[400px] w-full origin-center transition-all duration-300 ${modalClasses} `}
      >
        <div className="relative p-4">
          <div className="relative bg-white rounded-lg shadow">
            <button
              onClick={closeConfirmationModalHandler}
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto hover:bg-gray-800 hover:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                />
              </svg>
            </button>
            <div className="p-6 text-center">
              <div className="flex justify-center">
                <RiErrorWarningLine className="text-red-600" size={60} />
              </div>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                {t(modalText ?? '')}
              </h3>
              <button
                onClick={() => mutate(mutateParams, {
                  onSuccess: closeConfirmationModalHandler
                })}
                type="button"
                className="text-white bg-red-600 hover:bg-red-800  focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2"
              >
                {t('yesIamSure')}
              </button>
              <button
                onClick={closeConfirmationModalHandler}
                type="button"
                className="focus:outline-none rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 bg-gray-700 text-gray-300  hover:text-white hover:bg-gray-600"
              >
                {t('noCancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    overlays
  )
}

export default ConfirmationModal
