import { IoSettingsOutline } from 'react-icons/io5'
import { MdLogout } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import UserIcon from '../UI/UserIcon'
import Dropdown from './Dropdown'
import DropdownItem from './DropdownItem'
import UserMethods from '../../utils/userMethods'
import useToggleMenu from '../../hooks/useToggleMenu'
import useLogout from '../../hooks/useLogout'
import routes from '../../constants/routes'

function OptionsDropdown() {
  const navigate = useNavigate()
  const { ref, isOpen, setIsOpen } = useToggleMenu()
  const { logout } = useLogout()
  const authUser = UserMethods.getUser()
  const handleDropdownMenu = () => setIsOpen((prevState) => !prevState)

  // eslint-disable-next-line no-nested-ternary
  const settingsRoute = authUser?.user.user_type === 'DIRECTOR'
    ? routes.director.settings
    : authUser?.user.user_type === 'AGENT'
      ? routes.agent.settings
      : ''

  return (
    <div ref={ref} className="relative h-full flex items-center">
      <button type="button" onClick={handleDropdownMenu}>
        <UserIcon />
      </button>
      <Dropdown isOpen={isOpen}>
        <p className="px-4 py-3 capitalize font-semibold text-lg text-[#B1B1B1]">
          {authUser?.user.first_name}
          {' '}
          {authUser?.user.last_name}
        </p>
        <DropdownItem icon={<IoSettingsOutline size={24} />} onClickHandler={() => navigate(settingsRoute)} text="settings" />
        <DropdownItem icon={<MdLogout size={24} />} onClickHandler={logout} text="logout" />
      </Dropdown>
    </div>
  )
}

export default OptionsDropdown
