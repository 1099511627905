import moment from 'moment'
import { TaskTypes } from '../../../../../interfaces/tasks'
import TaskIcon from '../../../../../components/UI/TaskIcon'
import { useModalContext } from '../../../../../context/ModalsCtx/ModalsProvider'

interface Props {
  task: TaskTypes
}

function ControlPanelTask({ task }:Props) {
  const { openTaskModalHandler } = useModalContext()

  return (
    <button type="button" onClick={() => openTaskModalHandler(task)} className="border border-solid rounded-[10px] border-[#E6E6E6] py-[16px] px-8 cursor-pointer hover:bg-[rgba(0,0,0,0.05)] transition-colors duration-200">
      <div className="flex items-center justify-between text-grey-pale flex-wrap gap-2 w-full">
        <div className="flex items-center gap-2 flex-wrap max-w-full">
          <h3 title={task.title} className="font-bold truncate max-w-[400px]">{task.title}</h3>
          <TaskIcon taskType={task.type} />
        </div>

        <p className="text-sm">{moment(task.creation_date).format('DD/MM/YYYY')}</p>
      </div>
      <p title={task.description} className="text-left mt-[15px] text-[#BEBEBE] truncate max-w-[600px]">{task.description}</p>
    </button>

  )
}

export default ControlPanelTask
