import {
  useMemo, useContext, useState, ChangeEvent, useCallback
} from 'react'
import {
  useInfiniteQuery
} from 'react-query'
import { ChildrenTypes } from '../../interfaces/children'
import useGetData from '../../hooks/useGetData'
import apiService from '../../services/api/apiService'
import { ProductTypes } from '../../interfaces/products'
import { TaskTypes } from '../../interfaces/tasks'
import tokenMethods from '../../utils/tokenMethods'
import { UserTypes } from '../../interfaces/user'
import userMethods from '../../utils/userMethods'
import { DataProviderCtxTypes } from './dataProviderCtx.interface'
import dataProviderCtx from './dataProviderCtx'

export const useDataProvider = ():DataProviderCtxTypes => useContext(dataProviderCtx)

function DataProvider({ children }: ChildrenTypes) {
  const token = tokenMethods.getAccessToken()
  const authUser = userMethods.getUser()
  const [searchTextClient, setSearchTextClient] = useState<string>('')
  const [isEnabled, setIsEnabled] = useState<boolean>(!!token)
  const [showToughClients, setShowToughClients] = useState<boolean>(false)

  const {
    data,
    isLoading: isClientsLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  } = useInfiniteQuery(
    ['clients', searchTextClient, showToughClients],
    ({ pageParam }) => apiService.getClients({
      pageParam,
      searchText: searchTextClient,
      isTough: showToughClients
    }),
    {
      getNextPageParam: (lastPage) => lastPage?.next?.split('=').at(-1),
      enabled: isEnabled,
      refetchOnWindowFocus: false
    }
  )

  const clientPaginate = useMemo(() => ({
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  }), [
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  ])

  const clients = useMemo(() => data?.pages.map((group) => group?.results ?? [])
    .flat(1) ?? [], [data])

  const onSearchClientHandler = useCallback((event:ChangeEvent<HTMLInputElement>) => {
    setSearchTextClient(event.target.value.trim())
  }, [])

  // Get agents data
  const { data: agents } = useGetData<UserTypes[]>({
    queryKey: 'agents',
    queryFn: apiService.getAgents,
    disabled: authUser?.user.user_type === 'AGENT'
  })

  // Get products
  const { data: products, isLoading: isProductsLoading } = useGetData<ProductTypes[]>({
    queryKey: 'products',
    queryFn: apiService.getProducts,
    disabled: !isEnabled,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: 120000,
    cacheTime: 120000,
    refetchIntervalInBackground: true
  })

  // Get tasks
  const { data: tasks, isLoading: isTasksLoading } = useGetData<TaskTypes[]>({
    queryKey: 'tasks',
    queryFn: apiService.getTasks,
    disabled: !isEnabled
  })

  const ctx = useMemo(
    () => ({
      agents,
      clients,
      showToughClients,
      products,
      tasks,
      searchTextClient,
      isClientsLoading,
      isProductsLoading,
      isTasksLoading,
      clientPaginate,
      setIsEnabled,
      setSearchTextClient,
      setShowToughClients,
      onSearchClientHandler
    }),
    [
      agents,
      clients,
      showToughClients,
      products,
      tasks,
      searchTextClient,
      isTasksLoading,
      isClientsLoading,
      isProductsLoading,
      clientPaginate,
      setIsEnabled,
      setSearchTextClient,
      setShowToughClients,
      onSearchClientHandler
    ]
  )

  return (
    <dataProviderCtx.Provider value={ctx}>
      {children}
    </dataProviderCtx.Provider>
  )
}

export default DataProvider
