import { BsSearch } from 'react-icons/bs';
import { useAsyncDebounce } from 'react-table'
import { useTranslation } from 'react-i18next'

interface Props {
  setFilter: (value: string) => void
}

function Search({ setFilter }: Props) {
  const { t } = useTranslation()

  const handleChange = useAsyncDebounce((val) => setFilter(val || undefined), 500)

  return (
    <div className="bg-white min-w-[200px] sm:max-w-[40%] w-full flex items-center border border-solid border-[#BEBEBE] rounded-md px-4">
      <input
        type="text"
        placeholder={t('search')}
        onChange={(event) => {
          handleChange(event.target.value)
        }}
        className="py-[5px] outline-none w-full"
      />
      <BsSearch />
    </div>
  )
}

export default Search
