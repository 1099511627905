import { createContext } from 'react'
import { MailingListCtxTypes } from './mailingListCtx.interface'

const mailingListCtx = createContext<MailingListCtxTypes>({
  mailingList: [],
  getter: {
    mailingListName: null,
    selectedMailingListInput: null,
    isEditMailingList: false,
    mailingListSelectedClients: [],
    specificMailingListClients: []
  },
  showMailingListModal: false,
  showSpecificClientsListModal: false,
  showSelectedClientsModal: false,
  showSelectedSpecificClientsModal: false,
  showMailingListModalHandler: () => {},
  closeMailingListModalHandler: () => {},
  showSelectedClientsModalHandler: () => {},
  showSpecificClientsListModalHandler: () => {},
  closeSelectedClientsModalHandler: () => {},
  closeSpecificClientsListModalHandler: () => {},
  onSelectClientHandler: () => {},
  onSpecificSelectClientHandler: () => {},
  onRemoveClientHandler: () => {},
  onRemoveSpecificClientHandler: () => {},
  onClearMailingListSelectedClientsHandler: () => {},
  onClearSpecificClientsHandler: () => {},
  showSelectedSpecificClientsModalHandler: () => {},
  closeSelectedSpecificClientsModalHandler: () => {},
  onResetMailingListHandler: () => {},
  onEditNewsletterHandler: () => {},
  setter: {
    setMailingListName: () => {},
    setSelectedMailingListInput: () => {}
  }
})

export default mailingListCtx
