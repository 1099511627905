import { useTranslation } from 'react-i18next'
import { AiFillCheckCircle } from 'react-icons/ai'
import RenderIf from '../../../../../../../components/UI/RenderIf'

interface Props {
  status: 'inActive' | 'inProgress' | 'completed'
  text: string
  stepNumber: number
}

function CampaignHeaderStep({ text, stepNumber, status }:Props) {
  const { t } = useTranslation()

  const containerClasses = 'flex items-center gap-[6px]'
  const circleClasses = 'flex items-center justify-center rounded-full h-[25px] w-[25px] text-xs border border-solid'

  return (
    <>
      <RenderIf isTrue={status === 'inProgress'}>
        <div className={`${containerClasses} font-semibold`}>
          <div className={`${circleClasses} bg-btn-sub text-xs text-white border-btn-sub`}>{stepNumber}</div>
          <p className="text-black">{t(text)}</p>
        </div>
      </RenderIf>

      <RenderIf isTrue={status === 'inActive'}>
        <div className={containerClasses}>
          <div className={`${circleClasses} border-[#BEBEBE] bg-transparent text-xs text-[#BEBEBE]`}>{stepNumber}</div>
          <p className="text-[#BEBEBE]">{t(text)}</p>
        </div>
      </RenderIf>

      <RenderIf isTrue={status === 'completed'}>
        <div className={containerClasses}>
          <AiFillCheckCircle size={25} color="#c1c1c1" />
          <p className="text-[#BEBEBE]">{t(text)}</p>
        </div>
      </RenderIf>
    </>

  )
}

export default CampaignHeaderStep
