import { Dispatch, SetStateAction } from 'react'

interface Props {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

function HamburgerMenu({ isOpen, setIsOpen }:Props) {
  const hamburgerLine = 'h-1 w-6 my-1 rounded-full bg-btn-sub transition ease transform duration-300'
  return (
    <button
      type="button"
      className="flex 3xl:hidden flex-col h-12 w-12 justify-center items-center group"
      onClick={() => setIsOpen((prevState) => !prevState)}
    >
      <div className={`${hamburgerLine} ${isOpen && 'rotate-45 translate-y-3'}`} />
      <div className={`${hamburgerLine} ${isOpen && 'opacity-0'}`} />
      <div className={`${hamburgerLine} ${isOpen && '-rotate-45 -translate-y-3'}`} />
    </button>
  )
}

export default HamburgerMenu
