import { useEffect } from 'react'
import { useOutletContext, useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ProductTypes } from '../../../../../interfaces/products'
import PageSection from '../../../../../components/UI/PageSection'
import PageTitle from '../../../../../components/UI/PageTitle'
import ProductDetails from '../components/ProductDetails'
import DetailsInfoBtns from '../../../../../components/UI/DetailsInfoBtns'
import PrintButton from '../../../../../components/UI/PrintButton'
import routes from '../../../../../constants/routes'
import UserMethods from '../../../../../utils/userMethods'

function ProductInfo() {
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()
  const products = useOutletContext<ProductTypes[]>()
  const product = products?.find((prd) => prd.id.toString() === params.id)
  const authUser = UserMethods.getUser()
  const { t } = useTranslation()

  useEffect(() => {
    document.title = `Emoria CRM - ${t('product')}`
  }, [t])

  return (
    <PageSection>
      <div className="flex justify-between">
        <PageTitle text="product" />
        <PrintButton usedInTitle />
      </div>
      <ProductDetails product={product} />
      <DetailsInfoBtns onClickHandler={() => { navigate(`/${authUser?.user.user_type.toLowerCase()}/products/${routes.shared.updateProduct}/${product?.id}`) }} btnText="Edit Product" />
    </PageSection>
  )
}

export default ProductInfo
