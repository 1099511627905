import { UserRoleTypes } from '../interfaces/user'
import routes from '../constants/routes'

interface Props {
  userRole?: UserRoleTypes['userRole']
}

export default function userPath({ userRole }: Props) {
  switch (userRole) {
    case 'DIRECTOR':
      return routes.director.tasks
    case 'AGENT':
      return routes.agent.tasks
    default:
      return ''
  }
}
