import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import validationSchema from '../../../utils/validationSchema'
import InputErrorMessage from '../../../components/UI/InputErrorMsg'
import Spinner from '../../../components/UI/Spinner'
import RenderIf from '../../../components/UI/RenderIf'
import { ResetPasswordTypes } from '../../../interfaces/resetPassword'

const initialValues = {
  email: ''
}
const inputDivClasses = 'relative flex flex-col w-3/4 mx-auto mt-8 text-grey-pale'

interface Props {
  onSubmit: (values: ResetPasswordTypes) => void,
  isLoading: boolean
}

function ForgotPasswordForm({ onSubmit, isLoading }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema.forgotPasswordForm,
    onSubmit
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={`${inputDivClasses} mb-4`}>
        <label htmlFor="email" className="mb-2">
          {t('E-mail')}
        </label>
        <input
          className={`border py-1 px-2 rounded-[5px] h-12 outline-none ${formik.errors.email && formik.touched.email ? 'border-red-700' : 'border-[#BEBEBE]'
            // eslint-disable-next-line @typescript-eslint/indent
            }`}
          id="email"
          type="email"
          {...formik.getFieldProps('email')}
        />
        <RenderIf isTrue={!!(formik.errors.email && formik.touched.email)}>
          <div className="ml-0.5 mt-1">
            <InputErrorMessage text={formik.errors.email!} />
          </div>
        </RenderIf>
      </div>
      <div className={inputDivClasses}>
        <button
          className="text-white text-[18px] bg-btn-sub w-full text-center mx-auto px-2 py-4 rounded-[5px]"
          type="submit"
        >
          {isLoading ? <Spinner /> : t('resetPassword')}
        </button>
        <button
          className="text-lg leading-6 rounded-[5px] mt-4 xs:mt-6 mb-20 px-[30px] py-4 font-semibold hover:opacity-90 transition-opacity duration-200 bg-[#e8e8e8]"
          type="button"
          onClick={() => navigate(-1)}
        >
          {t('back')}
        </button>
      </div>
    </form>
  )
}
export default ForgotPasswordForm
