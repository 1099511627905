import { useTranslation } from 'react-i18next'

interface Props {
  text: string
  icon: JSX.Element
  onClickHandler: () => void
}

function DropdownItem({ icon, text, onClickHandler }:Props) {
  const { t } = useTranslation()
  return (
    <button
      type="button"
      onClick={onClickHandler}
      className="w-full flex items-center gap-3 py-2 border-t px-4 py-3 hover:bg-[#F8F8F8] transition-all duration-200 last:rounded-b-md"
    >
      {icon}
      <span className="text-[#959292] text-lg">{t(text)}</span>
    </button>
  )
}

export default DropdownItem
