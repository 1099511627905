import axios, { AxiosError } from 'axios'
import { StatisticsServiceInterface } from './statisticsService.interface'
import {
  TotalOrdersStatisticsTypes,
  StatisticsFromToTypes,
  TotalProductTypeOrdersStatisticsTypes,
  AnalyticsTypes,
  TotalWorthProductTypeOrdersStatisticsTypes,
  TotalOrderValuesStatisticsTypes,
  TotalCallStatisticsTypes,
  AverageOrdersPerMonthTypes,
  AverageOrdersPerYearTypes,
  ClientWorthOfOrdersTypes,
  FrequencyOfOrdersByYearTypes,
  FrequencyOfOrdersByMonthTypes,
  ClientTypeOfOrdersTypes,
  AgentAnalyticsTypes
} from '../../interfaces/statistics'
import exception from '../../utils/exception'
import { ServerError } from '../../interfaces/api'
import axiosInstance from '../axiosInstance'

class StatisticsService implements StatisticsServiceInterface {
  public async getAnalytics(): Promise<AnalyticsTypes | null> {
    try {
      const response = await axiosInstance.get('/orders/analytics/')
      return response.data.data as AnalyticsTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getAgentAnalytics(agent:number): Promise<AgentAnalyticsTypes | null> {
    try {
      const response = await axiosInstance.get(`/auth/agent/${agent}/todays-status/`)
      return response.data.data.data as AgentAnalyticsTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getTotalOrdersStatistics(
    {
      from,
      to
    }: StatisticsFromToTypes
  ): Promise<TotalOrdersStatisticsTypes | null> {
    try {
      const response = await axiosInstance.post('/orders/order/statistics/orders/', {
        from,
        to
      })
      return response.data.data as TotalOrdersStatisticsTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getTotalProductTypeOrdersStatistics(
    {
      from,
      to,
      agent
    }: StatisticsFromToTypes
  ): Promise<TotalProductTypeOrdersStatisticsTypes | null> {
    try {
      const data = {
        from,
        to,
        agent
      }

      if (agent === 'all') {
        delete data.agent
      }

      const response = await axiosInstance.post('/orders/order-product/statistics/orders/', data)
      return response.data.data as TotalProductTypeOrdersStatisticsTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getTotalWorthProductTypeOrdersStatistics({
    from,
    to,
    agent
  }: StatisticsFromToTypes): Promise<TotalWorthProductTypeOrdersStatisticsTypes[] | null> {
    const data = {
      from,
      to,
      agent
    }
    if (agent === 'all') {
      delete data.agent
    }
    try {
      const response = await axiosInstance.post('/orders/order-product/statistics/orders-worth/', data)
      return response.data.data.total as TotalWorthProductTypeOrdersStatisticsTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getTotalOrderValuesStatistics({
    from,
    to,
    agent
  }: StatisticsFromToTypes): Promise<TotalOrderValuesStatisticsTypes | null> {
    try {
      const data = {
        from,
        to,
        agent
      }

      if (agent === 'all') {
        delete data.agent
      }

      const response = await axiosInstance.post('/orders/order/statistics/order-values/', data)
      return response.data.data.data as TotalOrderValuesStatisticsTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getTotalCallStatistics({
    from,
    to,
    agent
  }: StatisticsFromToTypes): Promise<TotalCallStatisticsTypes | null> {
    const data = {
      from,
      to,
      agent
    }

    if (agent === 'all') {
      delete data.agent
    }

    try {
      const response = await axiosInstance.post('/orders/order/statistics/calls-leading-to-orders/', data)
      return response.data.data.data as TotalCallStatisticsTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getAverageOrdersPerMonth(clientNumber:string):
  Promise<AverageOrdersPerMonthTypes | null> {
    try {
      const response = await axiosInstance.get(`/clients/client/${clientNumber}/average-orders-per-month/`)
      return response.data.data.data as AverageOrdersPerMonthTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getAverageOrdersPerYear(clientNumber:string):
  Promise<AverageOrdersPerYearTypes | null> {
    try {
      const response = await axiosInstance.get(`/clients/client/${clientNumber}/average-orders-per-year/`)
      return response.data.data.data as AverageOrdersPerYearTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getClientWorthOfOrders(clientNumber:string):
  Promise<ClientWorthOfOrdersTypes | null> {
    try {
      const response = await axiosInstance.get(`/clients/client/${clientNumber}/worth-of-orders/`)
      return response.data.data.data as ClientWorthOfOrdersTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getFrequencyOfOrdersByYear(clientNumber:string):
  Promise<FrequencyOfOrdersByYearTypes[] | null> {
    try {
      const response = await axiosInstance.get(`/clients/client/${clientNumber}/order-frequency-by-year/`)
      return response.data.data.data as FrequencyOfOrdersByYearTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getFrequencyOfOrdersByMonth(clientNumber:string):
  Promise<FrequencyOfOrdersByMonthTypes[] | null> {
    try {
      const response = await axiosInstance.get(`/clients/client/${clientNumber}/order-frequency-by-last-30/`)
      return response.data.data.data as FrequencyOfOrdersByMonthTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getClientTypeOfOrders({ clientNumber, from, to }:StatisticsFromToTypes):
  Promise<ClientTypeOfOrdersTypes[] | null> {
    try {
      const response = await axiosInstance.post(`/clients/client/${clientNumber}/types-of-orders/`, {
        from,
        to
      })
      return response.data.data.data as ClientTypeOfOrdersTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}

export default StatisticsService
