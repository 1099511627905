import { GrAttachment } from 'react-icons/gr'
import { useTranslation } from 'react-i18next'
import FormTitle from '../../../../Director/components/UI/FormTitle'
import { NewslettersCtxTypes } from '../../../../../context/NewsletterCtx/newslettersCtx.interface'
import RenderIf from '../../../../../components/UI/RenderIf'

interface Props {
  newslettersCtx: NewslettersCtxTypes
}

function EmailAttachmentsPreview({ newslettersCtx }:Props) {
  const { t } = useTranslation()
  return (
    <div>
      <FormTitle text="fileAttachments" />
      <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-2">

        <RenderIf isTrue={!!newslettersCtx.getter.newsletterFiles
          && newslettersCtx.getter.newsletterFiles.length > 0}
        >
          {Array.from(newslettersCtx.getter.newsletterFiles ?? [], (file, idx) => (
            <div key={idx} className="flex items-center mb-2 gap-2 flex-wrap">
              <GrAttachment />
              <p className="text-[#2571C6] whitespace-pre-line">{file.name}</p>
            </div>
          ))}
        </RenderIf>

        <RenderIf isTrue={newslettersCtx.existingFiles.length > 0}>
          {newslettersCtx.existingFiles.map((file) => {
            const fileName = file.file?.split('/').at(-1)
            return (
              <div key={file.id + file.file} className="flex items-center mb-2 gap-3">
                <GrAttachment />
                <p className="text-[#2571C6] whitespace-pre-line">{fileName}</p>
              </div>
            )
          })}
        </RenderIf>

        <RenderIf isTrue={
          (!newslettersCtx.getter.newsletterFiles
           || (!!newslettersCtx.getter.newsletterFiles
              && newslettersCtx.getter.newsletterFiles.length === 0)
          )
          && newslettersCtx.existingFiles.length === 0
          }
        >
          <p className="text-grey-pale">{t('noAttachmentsFound')}</p>
        </RenderIf>

      </div>
    </div>
  )
}

export default EmailAttachmentsPreview
