import { useTranslation } from 'react-i18next'
import FormTitle from '../../../../../../Director/components/UI/FormTitle'
import FormInput from '../../../../../../../components/Form/FormInput'
import MailingListFormInput from '../../MailingList/MailingListFormInput'
import CreateNewListBtn from '../../MailingList/CreateNewListBtn'
import { useMailingList } from '../../../../../../../context/MailingListCtx/MailingListProvider'
import ViewSelectedClientsBtn from '../../MailingList/ViewSelectedClientsBtn'
import RenderIf from '../../../../../../../components/UI/RenderIf'

interface Props {
  formik: any
}

function CampaignDetailsStep({ formik }:Props) {
  const {
    mailingList,
    showSpecificClientsListModalHandler,
    getter,
    showSelectedSpecificClientsModalHandler
  } = useMailingList()

  const { t } = useTranslation()
  return (
    <form onSubmit={formik.handleSubmit} className="w-full ">
      <FormTitle text="campaignDetails" />
      <FormInput
        inputId="campaignName"
        name="campaignName"
        width="md:w-full"
        marginX="mx-0"
        getFieldProps={formik.getFieldProps('campaignName')}
        errors={!!(formik.touched.campaignName && formik.errors.campaignName)}
        errorName={formik.errors.campaignName}
      />
      <FormInput
        inputId="emailSubject"
        name="emailSubject"
        width="md:w-full"
        marginX="mx-0"
        getFieldProps={formik.getFieldProps('emailSubject')}
        errors={!!(formik.touched.emailSubject && formik.errors.emailSubject)}
        errorName={formik.errors.emailSubject}
      />
      <MailingListFormInput
        setValue={formik.setFieldValue}
        getFieldProps={formik.getFieldProps('mailingList')}
        errors={!!(formik.touched.mailingList && formik.errors.mailingList)}
        errorName={formik.errors.mailingList}
        mailingList={mailingList}
      />
      <p className="text-grey-pale mb-5">{t('or')}</p>
      <CreateNewListBtn text="chooseSomeReceivers" onClickHandler={showSpecificClientsListModalHandler} />
      <RenderIf isTrue={getter.specificMailingListClients.length > 0}>
        <div className="mt-4">
          <ViewSelectedClientsBtn
            selectedClients={getter.specificMailingListClients}
            onClickHandler={showSelectedSpecificClientsModalHandler}
          />
        </div>
      </RenderIf>
    </form>
  )
}

export default CampaignDetailsStep
