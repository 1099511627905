import { useTranslation } from 'react-i18next'
import { ProductTypes } from '../../../../../interfaces/products'
import FormLayout from '../../../../../components/Form/FormLayout'
import Detail from '../../../../../components/UI/Detail'

interface Props {
  product?: ProductTypes
}

function ProductDetails({ product }: Props) {
  const { t } = useTranslation()
  const print = 'print:grid-cols-3'
  return (
    <FormLayout>
      <div className={`grid grid-cols-2 md:grid-cols-4 gap-5 w-full ${print}`}>
        <Detail label={t('name')} text={product?.product_name || ''}>
          <img src={product?.product_photo} alt="product-logo" width="44" height="37" className="w-[44px] h-[37px] object-contain" />
        </Detail>
        <Detail label={t('productDescription')} text={product?.description || ''} />
        <Detail label={t('soldItems')} text={product?.sold_items.toString() || ''} />
        <Detail label={t('openOffers')} text={product?.open_offers.toString() || ''} />
      </div>
    </FormLayout>
  )
}

export default ProductDetails
