import { toast } from 'react-toastify'
import { MutationFunction, useMutation, useQueryClient } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { ServerError } from '../interfaces/api'

interface Props<T> {
  key: string | string[]
  mutationFn: MutationFunction<AxiosResponse | null, T>
  successMessage?: string
}

const useMutateData = <T extends unknown>({
  key,
  mutationFn,
  successMessage
}: Props<T>) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const {
    isLoading,
    isError,
    error,
    mutate
  } = useMutation(
    (params: T) => mutationFn(params),
    {
      onSuccess: async () => {
        if (Array.isArray(key)) {
          await Promise.all(key.map((k) => queryClient.invalidateQueries(k)))
        } else {
          await queryClient.invalidateQueries(key)
        }

        if (successMessage) {
          toast.success<string>(t(successMessage))
        }
      },
      onError: (apiError: AxiosError<ServerError>) => {
        toast.error<string>(t(apiError.message))
      }
    }
  )

  return {
    isLoading,
    isError,
    error,
    mutate
  }
}

export default useMutateData
