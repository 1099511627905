import { useTranslation } from 'react-i18next'
import emailPreviewImage from '../../../../assets/images/email-template-img.png'

function EmailTemplatePreviewImage() {
  const { t } = useTranslation()
  return (
    <div className="h-[calc(100%-40px)] w-full">
      <p className="text-primary-text-dark text-lg font-semibold mb-3">{t('emailSample')}</p>
      <img alt="" src={emailPreviewImage} className="w-full h-full" />
    </div>
  )
}

export default EmailTemplatePreviewImage
