import axios, { AxiosError, AxiosResponse } from 'axios'
import { NewslettersServiceInterface } from './newslettersService.interface'
import {
  FileTypes,
  MailingListApiDataTypes,
  MailingListTypes,
  NewsletterCreationDataTypes,
  NewsletterTypes
} from '../../interfaces/newsletters'
import exception from '../../utils/exception'
import { ServerError } from '../../interfaces/api'
import axiosInstance from '../axiosInstance'
import { newsletterFormData } from '../../helpers/newsletter-formdata'

class NewslettersService implements NewslettersServiceInterface {
  public async getNewsletters(): Promise<NewsletterTypes[] | null> {
    try {
      const response = await axiosInstance.get('/newsletter/campaign/')
      return response.data.data as NewsletterTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getMailingList(): Promise<MailingListTypes[] | null> {
    try {
      const response = await axiosInstance.get('/newsletter/mailing-list/')
      return response.data.data as MailingListTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async createMailingList({ name, clients }: MailingListApiDataTypes)
    : Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.post('/newsletter/mailing-list/', {
        name: name.trim(),
        clients: clients.map((client) => client.client_number)
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async uploadMailingListFile(file: File): Promise<AxiosResponse | null> {
    const formData = new FormData()
    formData.append('file', file)
    try {
      return await axiosInstance.post('/newsletter/mailing-list/upload/', formData)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async createNewsletter({ newsletter, mailingList }: NewsletterCreationDataTypes)
    : Promise<AxiosResponse | null> {
    const formData = newsletterFormData({ newsletter, mailingList })

    try {
      return await axiosInstance.post('/newsletter/campaign/', formData)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateNewsletter({
    newsletter,
    mailingList,
    isEditNewsletter
  }: NewsletterCreationDataTypes)
    : Promise<AxiosResponse | null> {
    const formData = newsletterFormData({ newsletter, mailingList, isEditNewsletter })

    try {
      return await axiosInstance.put(`/newsletter/campaign/${newsletter.selectedNewsletterId}/`, formData)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async sendNewsletter(id: number): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.get(`/newsletter/campaign/${id}/send/`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async deleteNewsletter({ id }: { id: number }): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.delete(`/newsletter/campaign/${id}/`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async deleteMailingList({ id }: { id: number }): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.delete(`/newsletter/mailing-list/${id}/`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateMailingList({ id, name, clients }: MailingListApiDataTypes)
    : Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.patch(`/newsletter/mailing-list/${id}/`, {
        name,
        clients: clients.map((client) => client.client_number)
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async createFile(file: File, campaignId?: number): Promise<FileTypes | null> {
    try {
      const formData = new FormData()
      formData.append('file', file)

      if (campaignId) {
        formData.append('campaign', campaignId.toString())
      }

      const response = await axiosInstance.post('/newsletter/file-field/', formData)
      return response.data.data as FileTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async deleteFile({ id }: { id: number }): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.delete(`/newsletter/file-field/${id}/`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}

export default NewslettersService
