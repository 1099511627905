import { useTranslation } from 'react-i18next'

interface Props {
  text: string
  margin?: 'mb-5' | 'mb-0'
}

function PageTitle({ text, margin = 'mb-5' }:Props) {
  const { t } = useTranslation()

  return (
    <h2 className={`text-white font-semibold text-3xl md:text-4xl leading-[52px] ${margin}`}>{t(text)}</h2>
  )
}

export default PageTitle
