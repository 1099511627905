import { useTranslation } from 'react-i18next'
import { IoIosMail } from 'react-icons/io'
import { BsFillTelephoneInboundFill, BsPeopleFill } from 'react-icons/bs'
import { TaskTypes } from '../../interfaces/tasks'

interface Props {
  taskType: TaskTypes['type']
}

function TaskIcon({ taskType }: Props) {
  const { t } = useTranslation()
  let icon: JSX.Element | null = null

  switch (taskType) {
    case 'EMAIL':
      icon = <IoIosMail color="#EEC72D" />
      break
    case 'CALL':
      icon = <BsFillTelephoneInboundFill color="#61A279" />
      break
    case 'MEETING':
      icon = <BsPeopleFill color="#2571C6" />
      break
    default:
      icon = null
  }

  return (
    <div className="flex items-center gap-3 text-sm">
      {icon}
      {t(taskType)}
    </div>
  )
}

export default TaskIcon
