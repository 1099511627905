import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import PageTitle from '../../../../../components/UI/PageTitle';
import ProductCreationForm from '../components/ProductCreationForm';
import useMutateData from '../../../../../hooks/useMutateData';
import apiService from '../../../../../services/api/apiService';
import PageSection from '../../../../../components/UI/PageSection'

function CreateProduct() {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { mutate: createProduct, isLoading } = useMutateData({
    key: 'products',
    mutationFn: apiService.createProduct,
    successMessage: 'productHasBeenCreated'
  })

  const onSubmit = (values: any) => {
    createProduct(values, {
      onSuccess: () => navigate(-1)
    })
  }

  useEffect(() => {
    document.title = `KT CRM - ${t('New Product')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text={t('New Product')} />
      <ProductCreationForm onSubmit={onSubmit} isLoading={isLoading} />
    </PageSection>
  )
}

export default CreateProduct
