import { Navigate, Outlet } from 'react-router-dom'
import tokenMethods from '../../utils/tokenMethods'
import userMethods from '../../utils/userMethods'
import navigateTo from '../../utils/navigateTo'

function PublicRoutes() {
  const token = tokenMethods.getAccessToken()
  const user = userMethods.getUser()
  return (
    <>
      {!token && <Outlet />}
      {token && user && <Navigate to={navigateTo(user?.user.user_type)} />}
    </>
  )
}

export default PublicRoutes
