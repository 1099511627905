import { useTranslation } from 'react-i18next'

interface Props {
  title: string;
  text: string;
}

function CampaignHeaderPreviewValue({ title, text }:Props) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-3 text-lg">
      <p className="text-grey-pale">{t(title)}</p>
      <p className="text-black font-semibold">{text}</p>
    </div>
  )
}

export default CampaignHeaderPreviewValue
