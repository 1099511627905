import { ChildrenTypes } from '../../interfaces/children'

interface Props extends ChildrenTypes {
  width?: 'max-w-[1236px] mx-auto' | 'w-full'
}

function PageSection({ children, width = 'max-w-[1236px] mx-auto' }: Props) {
  return (
    <section className={`w-full flex flex-col mt-8 ${width} `}>
      {children}
    </section>
  )
}

export default PageSection
