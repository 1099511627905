import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PageSection from '../../../../components/UI/PageSection'
import PageTitle from '../../../../components/UI/PageTitle'
import UpdateUserDetailsForm from './components/UpdateUserDetailsForm'
import UserMethods from '../../../../utils/userMethods'
import RenderIf from '../../../../components/UI/RenderIf'

function Settings() {
  const { t } = useTranslation()
  const authUser = UserMethods.getUser()
  useEffect(() => {
    document.title = `Emoria CRM - ${t('settings')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text="settings" />
      <UpdateUserDetailsForm />
    </PageSection>
  )
}

export default Settings
