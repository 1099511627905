import axios, { AxiosError, AxiosResponse } from 'axios'
import jwt_decode from 'jwt-decode'
import { ServerError } from '../../interfaces/api'
import { LoginTypes } from '../../interfaces/login'
import axiosInstance from '../axiosInstance'
import { AuthServiceInterface } from './authService.interface'
import exception from '../../utils/exception'
import tokenMethods from '../../utils/tokenMethods'
import { DecodedTokenTypes } from '../../interfaces/token'
import userMethods from '../../utils/userMethods'
import { UserRoleTypes } from '../../interfaces/user'
import { ResetPasswordTypes } from '../../interfaces/resetPassword'
import { ActivatePasswordTypes } from '../../interfaces/passwrod-form'

class AuthService implements AuthServiceInterface {
  public async login(
    {
      username,
      password
    }: LoginTypes
  ):
    Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.post('/login/', {
        username,
        password
      })
      tokenMethods.saveToken({ token: response?.data.data })
      const decodedToken: DecodedTokenTypes = jwt_decode(tokenMethods.getAccessToken()!)
      const userRoleId = decodedToken.agent_id
        ?? decodedToken.manager_id
        ?? decodedToken.director_id

      return await this.getAuthUser(userRoleId!, decodedToken.user_type)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getAuthUser(userRoleId: number, userRole: UserRoleTypes['userRole']): Promise<AxiosResponse | null> {
    try {
      const userEndpoint = userRole.toLowerCase()
      const response = await axiosInstance.get(`/auth/${userEndpoint}/${userRoleId}`)
      userMethods.saveUser(response?.data.data)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async forgotPassword(values: ResetPasswordTypes): Promise<AxiosResponse | null> {
    try {
      const formData = new FormData()
      formData.append('email', values.email.trim())
      return await axiosInstance.post('/auth/forgot-password/forgot-password-request/', formData)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async activateAccount(
    { token, password }: ActivatePasswordTypes
  ): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.post('/auth/activate-password/activate-agent-password/', {
        code: token,
        password
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }

    return null
  }

  public async resetPassword(
    { token, password }: ActivatePasswordTypes
  ): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.post('/auth/forgot-password/activate-new-password/', {
        code: token,
        password
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }

    return null
  }

  public async sendCaptchaToken(captchaToken: string): Promise<AxiosResponse | null> {
    try {
      return await axiosInstance.post('/auth/captcha/hcaptcha/', {
        captcha_challenge: captchaToken
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}

export default AuthService
