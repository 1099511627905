import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { TaskTypes } from '../../../../../interfaces/tasks'
import Table from '../../../../../components/Table/Table'
import TableActionBtns from '../../../../../components/Table/TableActionBtns'
import TaskIcon from '../../../../../components/UI/TaskIcon'
import { useModalContext } from '../../../../../context/ModalsCtx/ModalsProvider'

interface Props {
  tasks: TaskTypes[]
}

interface OriginalTypes {
  cell: { row: { original: TaskTypes } }
}

function TasksTable({ tasks = [] }: Props) {
  const { openTaskModalHandler, openConfirmationModalHandler } = useModalContext()
  const { t } = useTranslation()

  const transformedTasks = useMemo(() => tasks.map((task) => ({
    ...task,
    tableType: t(task.type)
  })).sort((a, b) => b.creation_date.localeCompare(a.creation_date)), [tasks, t])

  const columns = useMemo(() => [
    {
      Header: t('taskName'),
      accessor: 'title',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: string }) => <p title={value} className="truncate max-w-[250px]">{value}</p>
    },
    {
      Header: t('description'),
      accessor: 'description',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: string }) => <p title={value} className="truncate max-w-[400px]">{value}</p>
    },
    {
      Header: t('taskKind'),
      accessor: 'tableType',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        cell: {
          row: { original }
        }
      }:OriginalTypes) => <TaskIcon taskType={original.type} />
    },
    {
      Header: t('date'),
      accessor: 'creation_date',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: string }) => <span>{moment(value).format('DD/MM/YYYY')}</span>
    },
    {
      Header: ' ',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        cell: {
          row: { original }
        }
      }:OriginalTypes) => (
        <TableActionBtns
          onClickHandlerEdit={() => openTaskModalHandler(original)}
          onClickHandlerDelete={() => openConfirmationModalHandler('deleteTaskMessage', {
            id: original.id,
            userRole: null
          })}
        />
      )
    }
  ], [t, openTaskModalHandler, openConfirmationModalHandler])

  return (
    <Table
      columns={columns}
      data={transformedTasks}
      storageKey="tasksTable"
    />
  )
}

export default TasksTable
