import { Navigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import tokenMethods from '../../utils/tokenMethods'
import userMethods from '../../utils/userMethods'
import routes from '../../constants/routes'
import { UserRoleTypes } from '../../interfaces/user'
import { responseInterceptor } from '../../services/axiosInstance'
import useLogout from '../../hooks/useLogout'

interface Props {
  userRole: UserRoleTypes['userRole']
}

function ProtectedRoutes({ userRole }:Props) {
  const { t } = useTranslation()
  const { logout } = useLogout()
  const token = tokenMethods.getAccessToken()
  const user = userMethods.getUser()
  responseInterceptor({ logout, toastMsg: t('sessionExpired') })

  return (
    token && user?.user.user_type === userRole ? <Outlet /> : <Navigate to={routes.login} />
  )
}

export default ProtectedRoutes
