import axios, { AxiosError, AxiosResponse } from 'axios'
import { AiOutlineConsoleSql } from 'react-icons/ai'
import { ProductsServiceInterface } from './productsService.interface'
import { ProductTypes, Product } from '../../interfaces/products'
import exception from '../../utils/exception'
import { ServerError } from '../../interfaces/api'
import axiosInstance from '../axiosInstance'

class ProductsService implements ProductsServiceInterface {
  public async getProducts(): Promise<ProductTypes[] | null> {
    try {
      const response = await axiosInstance.get('/products/product/')
      return response.data.data as ProductTypes[]
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getProduct(productId: any): Promise<Product | null> {
    try {
      const response = await axiosInstance.get(`/products/product/${productId}`)
      return response.data.data as Product
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async createProduct(body: any): Promise<AxiosResponse | null> {
    try {
      const formData = new FormData()
      Object.entries(body).forEach((entry: any) => {
        const [key, value] = entry;
        if (key === 'product_photo') {
          formData.append(key, value[0] as any)
        } else {
          formData.append(key, value as any)
        }
      });

      const response = await axiosInstance.post('/products/product/', formData)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateProduct(values: any): Promise<AxiosResponse | null> {
    try {
      const formData = new FormData()
      Object.entries(values).forEach((entry: any) => {
        const [key, value] = entry;
        if (key === 'product_photo') {
          if (!value[0]?.source && value.length !== 0) {
            formData.append(key, value[0] as any)
          } else if ((!value[0]?.source && value.length === 0)) {
            console.log('!!!')
            formData.append(key, null as any)
          }
        } else {
          formData.append(key, value as any)
        }
      });

      const response = await axiosInstance.patch(`/products/product/${values.id}/`, formData)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}

export default ProductsService
