function ClientsNumberSkeleton() {
  return (
    <div className="flex flex-col gap-[10px]">
      <div className="bg-gray-200 rounded-[10px] animate-pulse w-[150px] h-[23px]" />
      <div className="bg-gray-200 rounded-[10px] animate-pulse w-[80px] h-[25px] sm:self-end" />
    </div>
  )
}

export default ClientsNumberSkeleton
