import { Dispatch, SetStateAction } from 'react'
import Logo from '../UI/Logo'
import Navigation from '../Navigation/Navigation'
import { UserRoleTypes } from '../../interfaces/user'
import OptionsDropdown from '../Dropdown/OptionsDropdown'
import HamburgerMenu from '../UI/HamburgerMenu'
import HamburgerNav from '../Navigation/HamburgerNav'

interface Props {
  userRole : UserRoleTypes['userRole']
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

function Header({ userRole, isOpen, setIsOpen }:Props) {
  const disablePrint = 'print:hidden';

  return (
    <header className={`${disablePrint} ${isOpen ? 'touch-none' : 'touch-auto'} flex items-center justify-between min-h-[96px] border-b-[1px] border-b-header-border border-solid`}>
      <Logo />
      <Navigation userRole={userRole} />
      <HamburgerNav userRole={userRole} isOpen={isOpen} />
      <div className="flex items-center gap-3">
        <OptionsDropdown />
        <HamburgerMenu isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </header>
  )
}

export default Header
