import { FieldInputProps } from 'formik'
import { useTranslation } from 'react-i18next'
import RenderIf from '../UI/RenderIf'
import InputErrorMessage from '../UI/InputErrorMsg'
import { ChildrenTypes } from '../../interfaces/children'

interface Props extends ChildrenTypes {
  labelText: string
  getFieldProps: FieldInputProps<any>
  errors: boolean
  errorName: string | undefined
  width?: 'lg:w-1/3' | 'lg:w-1/2' | 'lg:w-full' | 'md:w-full'
  disabled?: boolean
}

function FormSelect({
  labelText,
  getFieldProps,
  errors,
  errorName,
  children,
  width = 'lg:w-1/3',
  disabled = false
}:Props) {
  const { t } = useTranslation()
  return (
    <div className={`relative text-base md:text-lg w-full sm:w-full ${width} mt-4 mb-4`}>
      <label htmlFor={labelText} className="leading-6 text-grey-pale flex flex-col">
        <span className="mb-2 mx-4">{t(labelText)}</span>
        <select disabled={disabled} id={labelText} {...getFieldProps} className={`outline-none mx-4 bg-white border py-1 px-2 rounded-[5px] h-12 ${errors ? 'border-red-700' : 'border-gray-400'}`}>
          {children}
        </select>
      </label>
      <RenderIf isTrue={!!errors}>
        <div className="mx-4 absolute top-1 w-full
        "
        >
          <InputErrorMessage text={errorName!} />
        </div>
      </RenderIf>
    </div>
  )
}

export default FormSelect
