import RenderIf from '../../../../../components/UI/RenderIf'
import TasksSkeleton from '../../../../../components/Skeletons/TasksSkeleton'
import ControlPanelTasks from './ControlPanelTasks'
import { useDataProvider } from '../../../../../context/DataProviderCtx/DataProvider'

function ControlPanelTasksContainer() {
  const { tasks, isTasksLoading } = useDataProvider()

  return (
    <>
      <RenderIf isTrue={isTasksLoading}>
        <TasksSkeleton />
      </RenderIf>
      <RenderIf isTrue={!isTasksLoading}>
        <ControlPanelTasks tasks={tasks} />
      </RenderIf>
    </>
  )
}

export default ControlPanelTasksContainer
