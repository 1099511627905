import {
  Dispatch, SetStateAction, useEffect, useState
} from 'react'
import moment from 'moment/moment'
import { PredefinedRangeTypes } from '../interfaces/statistics'

export interface PredefinedDateRangeTypes {
  startDate: Date,
  endDate: Date,
  predefinedDateRange: PredefinedRangeTypes,
  shouldFetchValue: PredefinedRangeTypes
  setDateRange: Dispatch<SetStateAction<Date[]>>,
  setPredefinedDateRange: Dispatch<SetStateAction<PredefinedRangeTypes>>
}

const usePredefinedDateRange = () => {
  const [dateRange, setDateRange] = useState<Date[]>([])
  const [startDate, endDate] = dateRange
  const [predefinedDateRange, setPredefinedDateRange] = useState<PredefinedRangeTypes>('thisCalendarMonth');
  const [shouldFetchValue, setShouldFetchValue] = useState<PredefinedRangeTypes>('thisCalendarMonth')

  useEffect(() => {
    if (predefinedDateRange === 'thisCalendarMonth') {
      setDateRange([moment().startOf('month').toDate(), moment().endOf('month').toDate()])
      setShouldFetchValue('thisCalendarMonth')
    } else if (predefinedDateRange === 'lastCalendarMonth') {
      setDateRange([moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()])
      setShouldFetchValue('lastCalendarMonth')
    } else if (predefinedDateRange === 'thisCalendarYear') {
      setDateRange([moment().startOf('year').toDate(), moment().endOf('year').toDate()])
      setShouldFetchValue('thisCalendarYear')
    } else if (predefinedDateRange === 'lastCalendarYear') {
      setDateRange([moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('year').toDate()])
      setShouldFetchValue('lastCalendarYear')
    } else {
      setDateRange([])
      setShouldFetchValue('selectDateRange')
    }
  }, [predefinedDateRange])

  return {
    startDate,
    endDate,
    predefinedDateRange,
    shouldFetchValue,
    setDateRange,
    setPredefinedDateRange
  }
}

export default usePredefinedDateRange
