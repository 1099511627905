import React from 'react'

function EmailPreviewFooter() {
  return (
    <div style={{
      boxSizing: 'border-box',
      margin: '30px auto 20px auto',
      maxWidth: '600px',
      width: '100%',
      borderRadius: '10px',
      height: 'auto',
      backgroundColor: '#F9F9F9',
      textAlign: 'center'
    }}
    >
      <div style={{ textAlign: 'center', paddingTop: '10px', marginBottom: '10px' }}>
        <a href="https://www.google.com">emoria.eu</a>
      </div>
      <div style={{ textAlign: 'center', display: 'inline-block' }}>
        <a href="src/pages/Shared/pages/Newsletters/components/EmailPreview/EmailPreview#" title="facebook" style={{ display: 'inline-block', marginLeft: '10px', marginRight: '10px' }}>
          <img src="https://crm.emoria.eu:8443/static/images/facebook.png" alt="Facebook" />
        </a>
      </div>
      <div style={{ textAlign: 'center', display: 'inline-block' }}>
        <a href="src/pages/Shared/pages/Newsletters/components/EmailPreview/EmailPreview#" title="instagram" style={{ display: 'inline-block', marginRight: '10px' }}>
          <img src="https://crm.emoria.eu:8443/static/images/instagram.png" alt="Instagram" />
        </a>
      </div>
      <div style={{ textAlign: 'center', display: 'inline-block' }}>
        <a href="src/pages/Shared/pages/Newsletters/components/EmailPreview/EmailPreview#" title="twitter" style={{ display: 'inline-block', marginRight: '10px' }}>
          <img src="https://crm.emoria.eu:8443/static/images/twitter.png" alt="Twitter" />
        </a>
      </div>
      <div style={{
        textAlign: 'center', margin: '10px 10px 0', wordBreak: 'break-word', paddingBottom: '10px'
      }}
      >
        <small id="clientEmail" style={{ color: 'grey', fontSize: '75%' }}>Το παρόν email εστάλη στο name@email.com στα πλαίσια της επιχειρηματικής μας συνεργασίας.</small>
      </div>
    </div>
  )
}

export default EmailPreviewFooter
