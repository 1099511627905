import { UserRoleTypes } from '../../interfaces/user'
import RenderIf from '../UI/RenderIf'
import DirectorNav from './UserRoleNav/DirectorNav'
import SalesAgentNav from './UserRoleNav/SalesAgentNav'

export default function Navigation({ userRole }: UserRoleTypes) {
  return (
    <nav className="hidden 3xl:flex h-full gap-[4.5rem] ">
      <RenderIf isTrue={userRole === 'AGENT'}>
        <SalesAgentNav />
      </RenderIf>
      <RenderIf isTrue={userRole === 'DIRECTOR'}>
        <DirectorNav />
      </RenderIf>
    </nav>
  )
}
