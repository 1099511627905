import CampaignHeaderPreviewValue from './CampaignHeaderPreviewValue'
import RenderIf from '../../../../../../../components/UI/RenderIf'

interface Props {
  campaignName: string | null;
  emailSubject: string | null;
}

function CampaignHeaderPreviewValues({ campaignName, emailSubject }:Props) {
  return (
    <div className="flex gap-4 flex-col sm:flex-row sm:flex-wrap sm:items-center pt-2 justify-between w-full">
      <RenderIf isTrue={!!campaignName}>
        <CampaignHeaderPreviewValue title="campaignName" text={campaignName ?? ''} />
      </RenderIf>
      <RenderIf isTrue={!!emailSubject}>
        <CampaignHeaderPreviewValue title="emailSubject" text={emailSubject ?? ''} />
      </RenderIf>
      <CampaignHeaderPreviewValue title="emailList" text="test list" />
    </div>
  )
}

export default CampaignHeaderPreviewValues
