import {
  useEffect, useState
} from 'react'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'

import { InfiniteActionPaginateTypes } from '../../interfaces/client'

interface PaginationProps {
  pageSize: number,
  pageIndex: number,
  pageLength: number,
  previousPage: () => void,
  canPreviousPage: boolean,
  nextPage: () => void,
  canNextPage: boolean,
  pageOptions: number[]
  gotoPage: (updater: (number | ((pageIndex: number) => number))) => void
  apiPagination?: InfiniteActionPaginateTypes
}

function Pagination({
  pageSize,
  pageIndex,
  pageLength,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  pageOptions,
  gotoPage,
  apiPagination
}: PaginationProps) {
  const [pageNum, setPageNum] = useState<number>(pageIndex + 1)
  const { t } = useTranslation()
  const pageNumName = pageOptions.length > 1 ? t('pages') : t('page')
  const borderClasses = 'border bg-white border-solid border-[#BEBEBE] rounded-md w-[30px] h-[30px] flex items-center justify-center hover:opacity-70 transition-opacity duration-200'
  const borderDisableClasses = 'disabled:border-[#E2E2E2]'

  // Keep the page number in sync after state changes
  useEffect(() => {
    gotoPage(pageNum - 1)
  }, [gotoPage, apiPagination, pageNum])

  return (
    <div className="bg-[#F8F8F8] flex justify-between items-center p-5 text-[#B1B1B1] rounded-md flex-wrap">
      <p>
        {`${pageSize * pageIndex + 1} - ${pageSize * pageIndex + pageLength} ${t('of')} ${pageOptions.length} ${pageNumName}`}
      </p>
      <div className="flex items-center gap-[18px] flex-wrap">
        <div className="flex items-center gap-3 flex-wrap">
          <p>{t('thePageYouAreOn')}</p>
          <input
            className={`${borderClasses} py-1 px-3 max-w-[70px] `}
            type="number"
            value={pageNum.toString()}
            min={1}
            max={pageOptions.length}
            step={1}
            onChange={(event) => {
              const page = event.target.value ? +event.target.value - 1 : 0
              setPageNum(+event.target.value)
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </div>
        <div className="h-[30px] w-[2px] bg-[#E6E6E6]" />
        <div className="flex gap-2">
          <button
            className={`${borderClasses} ${borderDisableClasses}`}
            type="button"
            onClick={() => {
              setPageNum(pageIndex)
              previousPage()
            }}
            disabled={!canPreviousPage}
          >
            <BsChevronLeft className={`${!canPreviousPage ? 'text-[#E2E2E2]' : 'text-primary-text-dark'}`} />
          </button>
          <button
            className={`${borderClasses} ${apiPagination?.hasNextPage ? '' : borderDisableClasses}`}
            type="button"
            onClick={async () => {
              if (apiPagination && pageIndex === pageOptions.at(-1)) {
                await apiPagination.fetchNextPage()
              }
              setPageNum(pageIndex + 2)
              nextPage()
            }}
            disabled={apiPagination?.hasNextPage ? false : !canNextPage}
          >
            <BsChevronRight className={`${!canNextPage && !apiPagination?.hasNextPage ? 'text-[#E2E2E2]' : 'text-primary-text-dark'}`} />
          </button>
        </div>
      </div>

    </div>
  )
}

export default Pagination
