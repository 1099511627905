import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import RenderIf from '../../../../components/UI/RenderIf'
import Title from '../../../../components/UI/Title'
import routes from '../../../../constants/routes'
import TableSkeleton from '../../../../components/Skeletons/TableSkeleton'
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal'
import apiService from '../../../../services/api/apiService'
import NewslettersTable from './components/NewslettersTable'
import useGetData from '../../../../hooks/useGetData'
import { NewsletterTypes } from '../../../../interfaces/newsletters'
import NewslettersProvider from '../../../../context/NewsletterCtx/NewslettersProvider'
import MailingListProvider from '../../../../context/MailingListCtx/MailingListProvider'

function Newsletters() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isExactPath = pathname === routes.director.newsletters
    || pathname === routes.agent.newsletters
  const { data: newsletters, isLoading } = useGetData<NewsletterTypes[]>({
    queryKey: 'newsletters',
    queryFn: apiService.getNewsletters
  })

  const { t } = useTranslation()

  useEffect(() => {
    document.title = `Emoria CRM - ${t('newsletters')}`
  }, [t])

  return (
    <NewslettersProvider>
      <MailingListProvider>
        <RenderIf isTrue={isExactPath}>
          <Title results={newsletters} title="newsletterCampaigns" add="newNewsletter" onClickHandler={() => navigate(routes.shared.createNewsletter)} />
          <RenderIf isTrue={isLoading}>
            <TableSkeleton />
          </RenderIf>
          <RenderIf isTrue={!isLoading}>
            <NewslettersTable newsletters={newsletters} />
            <ConfirmationModal cacheKey="newsletters" toastSuccessMessage="newsletterHasBeenDeleted" mutationFn={apiService.deleteNewsletter} />
          </RenderIf>
        </RenderIf>
        <RenderIf isTrue={!isExactPath}>
          <Outlet />
        </RenderIf>
      </MailingListProvider>
    </NewslettersProvider>
  )
}

export default Newsletters
