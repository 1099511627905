import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import RenderIf from '../../../../../../components/UI/RenderIf'
import MailingListTableSkeleton from '../../../../../../components/Skeletons/MailingListTableSkeleton'
import MailingListTable from './MailingListTable'
import { useDataProvider } from '../../../../../../context/DataProviderCtx/DataProvider'
import { useMailingList } from '../../../../../../context/MailingListCtx/MailingListProvider'

const SpecificClientsListModal = () => {
  const { t } = useTranslation()
  const dataProvider = useDataProvider()
  const mailingListCtx = useMailingList()

  const onCloseModalHandler = () => {
    mailingListCtx.closeSpecificClientsListModalHandler()
    mailingListCtx.onClearSpecificClientsHandler()
  }

  const backdropClasses = mailingListCtx.showSpecificClientsListModal ? 'opacity-100 visible' : 'opacity-0 invisible'
  const modalClasses = mailingListCtx.showSpecificClientsListModal ? 'opacity-100 scale-100' : 'opacity-0 scale-0'
  const overlays = document.getElementById('overlays') as HTMLElement

  return ReactDOM.createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */}
      <div className={`fixed inset-0 z-40 bg-[rgba(0,0,0,0.20)] transition-opacity duration-300 ${backdropClasses}`} onClick={onCloseModalHandler} />
      <div className={`bg-white absolute xl:fixed top-8 md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 z-50  max-w-[90%] xl:max-w-[1235px] w-full origin-center transition-all duration-300 ${modalClasses} rounded-2xl`}>
        <div className="flex items-center justify-between bg-btn-sub text-white rounded-t-2xl px-[35px] py-[25px]">
          <h2 className="font-semibold text-4xl">{t('chooseSomeReceivers')}</h2>
        </div>
        <div className="flex flex-col gap-2 px-[35px] py-[25px] text-lg text-grey-pale">
          <RenderIf isTrue={
            dataProvider.isClientsLoading
            || dataProvider.clientPaginate.isFetchingNextPage
}
          >
            <MailingListTableSkeleton />
          </RenderIf>

          <RenderIf isTrue={!dataProvider.isClientsLoading
            && !dataProvider.clientPaginate.isFetchingNextPage}
          >
            <MailingListTable
              clients={dataProvider.clients}
              selectedClients={mailingListCtx.getter.specificMailingListClients}
              onSelectHandler={mailingListCtx.onSpecificSelectClientHandler}
              onRemoveHandler={mailingListCtx.onRemoveSpecificClientHandler}
              onShowSelectedClientsHandler={mailingListCtx.showSelectedSpecificClientsModalHandler}
            />
          </RenderIf>
          <div className="w-full h-[1px] bg-[#BEBEBE] mt-4" />
          <div>
            <div className="mt-4 flex flex-col lg:flex-row justify-end gap-5">
              <button
                type="button"
                onClick={onCloseModalHandler}
                className="text-lg leading-6 rounded-[5px] px-[30px] py-4 font-semibold hover:opacity-90 transition-opacity duration-200 bg-[#e8e8e8] flex items-center justify-center gap-[2px]"
              >
                {t('Cancel')}
              </button>
              <button
                type="button"
                className="w-full lg:w-auto text-lg text-white leading-6 rounded-[5px] px-[30px] py-4 font-semibold hover:opacity-90 transition-opacity duration-200text-white bg-btn-sub"
                onClick={mailingListCtx.closeSpecificClientsListModalHandler}
              >
                {t('save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    overlays
  )
}

export default SpecificClientsListModal
