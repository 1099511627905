import { useTranslation } from 'react-i18next'
import { UserTypes } from '../../../../../interfaces/user'
import FormLayout from '../../../../../components/Form/FormLayout'
import Detail from '../../../../../components/UI/Detail'

interface Props {
  user?: UserTypes
}

function UserDetails({ user }:Props) {
  const { t } = useTranslation()

  return (
    <FormLayout>
      <div className="w-full">
        <h2 className="font-semibold text-xl text-primary-text-dark">{t('basicDetails')}</h2>

        <div className="grid grid-cols-2 md:grid-cols-4 flex-wrap gap-10 w-full mt-8">
          <Detail label={t('agentName')} text={`${user?.user.first_name ?? ''} `} />
          <Detail label={t('agentSurname')} text={user?.user.first_name ?? ''} />
          <Detail label="Email" text={user?.user.email ?? ''} />
          <Detail label={t('role')} text={t(user?.user.user_type ?? '')} />
        </div>
      </div>
    </FormLayout>
  )
}

export default UserDetails
