import { FiEdit } from 'react-icons/fi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdSend } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import RenderIf from '../UI/RenderIf'

interface Props {
  onClickHandlerEdit?: () => void
  onClickHandlerDelete?: () => void
  onClickHandlerOrder?: () => void
  onClickHandlerSend?: () => void
}

function TableActionBtns({
  onClickHandlerEdit, onClickHandlerDelete, onClickHandlerOrder, onClickHandlerSend
}:Props) {
  const { t } = useTranslation()
  const iconClasses = 'transition-colors duration-200 cursor-pointer'
  return (
    <div className="flex items-center justify-end gap-[15px]">
      <RenderIf isTrue={!!onClickHandlerSend}>
        <MdSend
          title={t('resend')}
          onClick={onClickHandlerSend}
          size={21}
          className={`${iconClasses} hover:text-[#4B8FDC]`}
        />
      </RenderIf>
      <RenderIf isTrue={!!onClickHandlerEdit}>
        <FiEdit
          title={t('edit')}
          onClick={onClickHandlerEdit}
          size={21}
          className={`${iconClasses} hover:text-[#4B8FDC]`}
        />
      </RenderIf>
      <RenderIf isTrue={!!onClickHandlerDelete}>
        <RiDeleteBin6Line
          title={t('delete')}
          onClick={onClickHandlerDelete}
          size={21}
          className={`${iconClasses} hover:text-red-500`}
        />
      </RenderIf>
      <RenderIf isTrue={!!onClickHandlerOrder}>
        <AiOutlineShoppingCart
          title={t('order')}
          onClick={onClickHandlerOrder}
          size={21}
          cursor="pointer"
          className={`${iconClasses} hover:text-[#4B8FDC]`}
        />
      </RenderIf>

    </div>
  )
}

export default TableActionBtns
