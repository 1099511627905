import { NewsletterCreationDataTypes } from '../interfaces/newsletters'

// eslint-disable-next-line import/prefer-default-export
export const newsletterFormData = (
  { newsletter, mailingList, isEditNewsletter }: NewsletterCreationDataTypes
):FormData => {
  const formData = new FormData()
  formData.append('name', newsletter.campaignName!)
  formData.append('email_subject', newsletter.emailSubject!)
  formData.append('email_body', newsletter.emailTemplate!)
  if (mailingList.selectedMailingListInput) {
    formData.append('mailing_lists', mailingList.selectedMailingListInput)
  }

  if (mailingList.specificMailingListClients.length > 0) {
    mailingList.specificMailingListClients.forEach((client) => {
      formData.append('specific_clients', client.client_number)
    })
  }

  if (newsletter.selectedNewsletterProducts.length > 0) {
    newsletter.selectedNewsletterProducts.forEach((product) => {
      formData.append('products_on_offer', product.id.toString())
    })
  }
  if (!!newsletter.newsletterFiles && newsletter.newsletterFiles.length > 0 && !isEditNewsletter) {
    Array.from(newsletter.newsletterFiles).forEach((file) => {
      formData.append('file', file)
    })
  }
  return formData
}
