function LatestCallSkeleton() {
  return (
    <div className="bg-gray-200 animate-pulse rounded-[10px] py-[16px] px-8">
      <div className="flex items-center justify-between text-grey-pale flex-wrap gap-2 w-full">
        <div className="flex items-center gap-2 flex-wrap max-w-full">
          <div className="h-[24px] w-[180px] rounded-[10px] bg-gray-100 animate-pulse " />
          <div className="h-[24px] w-[100px] rounded-[10px] bg-gray-100 animate-pulse" />
        </div>
        <div className="h-[24px] w-[100px] rounded-[10px] bg-gray-100 animate-pulse" />
      </div>
      <div className="mt-[15px] h-[24px] max-w-[600px] rounded-[10px] bg-gray-100 animate-pulse" />
    </div>
  )
}

function LatestCallsSkeleton() {
  return (
    <div className="rounded-[10px] h-[calc(100vh-435px)] overflow-y-hidden bg-white">
      <div className="flex items-center h-[36px] gap-2 px-8 rounded-[10px] flex-wrap justify-between bg-gray-200 animate-pulse">
        <div className="rounded-[10px] bg-gray-100 animate-pulse w-[120px] h-[23px]" />
      </div>
      <div className="mt-5 flex flex-col gap-3 overflow-y-auto h-[calc(100%-60px)]">
        <LatestCallSkeleton />
        <LatestCallSkeleton />
        <LatestCallSkeleton />
        <div className="ml-auto rounded-[10px] bg-gray-100 animate-pulse w-[120px] h-[23px]" />
      </div>
    </div>
  )
}

export default LatestCallsSkeleton
