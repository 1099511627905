function CardSkeleton() {
  return (
    <div className="rounded-[16px] shadow-md p-6  h-[163px] bg-gray-100 animate-pulse">
      <div className="rounded-[10px] max-w-[220px] h-[28px] bg-gray-200 animate-pulse" />
      <div className="rounded-[10px] mt-5 w-[100px] h-[50px] bg-gray-200 animate-pulse" />
    </div>
  )
}

function DirectorCardsSkeleton() {
  return (
    <div className="grid grid-cols-2 2xl:grid-cols-5 my-6 gap-[22px] w-full ">
      <CardSkeleton />
      <CardSkeleton />
      <div className="col-span-full 2xl:col-auto">
        <CardSkeleton />
      </div>
      <CardSkeleton />
      <CardSkeleton />
    </div>
  )
}

export default DirectorCardsSkeleton
