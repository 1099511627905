import { useTranslation } from 'react-i18next'
import useGetData from '../../../../../hooks/useGetData'
import apiService from '../../../../../services/api/apiService'
import { ClientLatestCallTypes } from '../../../../../interfaces/client'
import { durationToTime } from '../../../../../helpers/duration-to-time'
import WidgetTitle from '../../../../../components/UI/WidgetTitle'

interface Props {
  agent: number
}

export default function LastCallsTable({ agent }: Props) {
  const [t] = useTranslation()

  const { data: lastCalls } = useGetData<ClientLatestCallTypes[]>({
    queryKey: `lastCalls ${agent}`,
    queryFn: () => apiService.getClientsLatestCalls(agent),
    disabled: !agent
  })

  return (
    <div className="bg-[#FBFBFB] rounded-[10px] p-[10px] md:py-5 md:px-8">
      <WidgetTitle text="amountOrDurationOfCallsToday" fontWeight="font-semibold" />
      <table className="w-full border-collapse">
        <thead className="border-b-2 border-black">
          <tr className="text-left font-semibold text-grey-bold">
            <th>{t('fullClientName')}</th>
            <th>{t('phoneNumber')}</th>
            <th className="text-right">{t('duration')}</th>
          </tr>
        </thead>
        <tbody>
          {lastCalls?.map((client) => (
            <tr className="text-left border-b border-[#BEBEBE]" key={client.id}>
              <td className="py-2">
                {client.client_name}
              </td>
              <td>{client.client_telephone}</td>
              <td className="text-right">{durationToTime(client.duration)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
