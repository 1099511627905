import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IoMdClose } from 'react-icons/io'
import { RiDeleteBin6Line } from 'react-icons/ri'
import RenderIf from '../../../../../../components/UI/RenderIf'
import NoDataMessage from '../../../../../../components/UI/NoDataMessage'
import { ClientTypes } from '../../../../../../interfaces/client'

interface Props {
  selectedClients: ClientTypes[]
  showModal: boolean
  closeModal: () => void
  onRemoveClient: (client: ClientTypes) => void
  onClearSelectedClients: () => void
}

function SelectedClientsModal({
  selectedClients = [],
  showModal,
  closeModal,
  onRemoveClient,
  onClearSelectedClients
}
:Props) {
  const { t } = useTranslation()
  const backdropClasses = showModal ? 'opacity-100 visible' : 'opacity-0 invisible'
  const modalClasses = showModal ? ' translate-x-[0]' : ' translate-x-[100%]'
  const overlays = document.getElementById('overlays') as HTMLElement

  return ReactDOM.createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */}
      <div className={`fixed inset-0 z-[90] bg-[rgba(0,0,0,0.20)] transition-opacity duration-300 ${backdropClasses}`} onClick={closeModal} />
      <div className={`bg-white flex flex-col fixed bottom-0 top-0 right-0 z-[100] max-w-[600px] w-full origin-center transition-all duration-300 ${modalClasses} sm:rounded-l-2xl`}>
        <div className="flex items-center justify-between bg-btn-sub text-white sm:rounded-tl-2xl px-[35px] py-[25px]">
          <p className="font-semibold text-4xl">{t('selectedClients')}</p>
          <IoMdClose size={33} onClick={closeModal} className="cursor-pointer" />
        </div>
        <div className="flex flex-col h-[calc(100%-130px)] xs:h-[calc(100%-90px)] p-5">
          <RenderIf isTrue={selectedClients.length === 0}>
            <NoDataMessage text="noClientsSelected" />
          </RenderIf>
          <RenderIf isTrue={selectedClients.length > 0}>
            <div className="h-full overflow-y-auto flex flex-col mb-4">
              <button
                onClick={onClearSelectedClients}
                className="text-left text-sm text-grey-pale mb-2 hover:text-btn-sub w-max transition-colors duration-200"
                type="button"
              >
                {t('removeAll')}
              </button>
              {selectedClients.map((client) => (
                <div key={client.client_number} className="border-b py-1 flex items-center justify-between gap-2">
                  <p title={client.full_name} className="text-primary-text-dark line-clamp-1">{client.full_name}</p>
                  <RiDeleteBin6Line
                    onClick={() => onRemoveClient(client)}
                    size={22}
                    className="text-[#BEBEBE] transition-colors duration-200 cursor-pointer hover:text-red-500 pr-1"
                  />
                </div>
              ))}
            </div>
          </RenderIf>
          <div className="w-full h-[1px] bg-[#BEBEBE] my-4 mt-auto" />
          <button
            className="text-lg rounded-[5px] w-full px-[30px] py-4 font-semibold hover:opacity-90 transition-opacity duration-200 bg-[#e8e8e8]  "
            type="button"
            onClick={closeModal}
          >
            {t('close')}
          </button>
        </div>
      </div>
    </>,
    overlays
  )
}

export default SelectedClientsModal
