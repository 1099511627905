import { ChildrenTypes } from '../../interfaces/children'

function TableSkeletonText({ bgColor = 'bg-gray-200', display = 'block' }) {
  return (
    <p className={`h-[15px] animate-pulse max-w-[150px] w-full ${bgColor} ${display} rounded-2xl`} />
  )
}

function TableSkeletonWrapper({ children }:ChildrenTypes) {
  return (
    <div className="flex items-center justify-between h-[25px] gap-2 bg-gray-200 p-5 rounded-md animate-pulse">
      {children}
    </div>
  )
}

function MailingListTableSkeleton() {
  return (
    <div className="h-max bg-white w-full">
      <div className="bg-gray-200 p-5 mb-8 rounded-md animate-pulse">
        <div className="bg-gray-100 min-w-[200px] max-w-[40%] w-full rounded-md h-8 animate-pulse" />
      </div>
      <div className="w-full">
        <div className="flex justify-between h-max gap-2 px-5">
          <TableSkeletonText />
          <TableSkeletonText display="hidden lg:block" />
          <TableSkeletonText display="hidden lg:block" />
          <TableSkeletonText display="hidden lg:block" />

        </div>
        <div className="flex flex-col gap-3 mt-5">
          <TableSkeletonWrapper>
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
          </TableSkeletonWrapper>
          <TableSkeletonWrapper>
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
          </TableSkeletonWrapper>
          <TableSkeletonWrapper>
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
          </TableSkeletonWrapper>
          <TableSkeletonWrapper>
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
          </TableSkeletonWrapper>
          <TableSkeletonWrapper>
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
          </TableSkeletonWrapper>
        </div>
      </div>
      <div className="flex gap-3 bg-gray-200 p-6 mt-8 rounded-md animate-pulse">
        <TableSkeletonText bgColor="bg-gray-100" />
        <div className="flex gap-3 w-full justify-end">
          <TableSkeletonText bgColor="bg-gray-100" />
          <TableSkeletonText bgColor="bg-gray-100" />
        </div>
      </div>
    </div>
  )
}

export default MailingListTableSkeleton
