import { MdDelete } from 'react-icons/md'
import { useNewsletters } from '../../../../../context/NewsletterCtx/NewslettersProvider'
import useMutateData from '../../../../../hooks/useMutateData'
import apiService from '../../../../../services/api/apiService'

function ExistingFiles() {
  const { existingFiles, setter } = useNewsletters()
  const { mutate: deleteFile } = useMutateData({
    key: 'newsletters',
    mutationFn: apiService.deleteFile
  })
  return (
    <div className="fex flex-col">
      {existingFiles.map((file) => {
        const fileName = file.file?.split('/').at(-1)
        return (
          <div key={file.id + file.file} className="flex items-center mb-2 gap-3">
            <p>{fileName}</p>
            <MdDelete
              className="text-error cursor-pointer"
              size={20}
              onClick={() => deleteFile(
                { id: file.id },
                {
                  onSuccess: async () => {
                    setter.setExistingFiles((prevFiles) => prevFiles
                      .filter((prevFile) => prevFile.id !== file.id))
                  }
                }
              )}
            />
          </div>
        )
      })}
    </div>
  )
}

export default ExistingFiles
