import { useTranslation } from 'react-i18next'
import { BsFilter } from 'react-icons/bs'

interface Props {
  onClickHandler: () => void
}

function TableFiltersBtn({ onClickHandler }:Props) {
  const { t } = useTranslation()
  return (
    <button onClick={onClickHandler} type="button" className="px-3 py-[5px] text-grey-pale  border border-solid border-[#BEBEBE] rounded-[5px] flex items-center gap-2">
      <BsFilter className="text-grey-pale" size={21} />
      {t('filters')}
    </button>
  )
}

export default TableFiltersBtn
